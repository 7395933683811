.modal-container {
  position: relative;
  background-color: #121212;
}

.modal-video-player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.modal-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.blurred-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: cover;
  filter: blur(30px);
  z-index: 2;
}
.close-modal-icon {
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  color: rgb(0, 162, 255);
  background-color: rgba(175, 173, 173, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
}
.close-modal-icon:hover {
  background-color: rgba(221, 215, 215, 0.5);
}

.modal-icons-container {
  display: flex;
  gap: 10px;
  /* width: 40%; */
  justify-content: flex-start;
}

.desc-modal-icon {
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  color: white;
  background-color: #0b2f72;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desc-modal-details-container {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 1rem;
  flex-wrap: wrap;
}

.desc-modal-analytics {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0.7rem;
  /* height: 100%; */
}
.desc-modal-desc-container {
  width: 50%;
}
.desc-modal-details-container span {
  font-size: 0.9rem;
}
.desc-modal-tags {
  width: 47%;
}

.desc-modal-icon:hover {
  color: white;
  background-color: #3879f1;
}

@media (max-width: 992px) {
  .desc-modal-details-container {
    flex-direction: column;
    gap: 10px;
  }
  .desc-modal-analytics {
    width: 100%;
  }
  .desc-modal-tags {
    width: 100%;
  }
  .desc-modal-analytics h6 {
    font-size: 0.9rem;
  }
  .desc-modal-desc-container {
    width: 100%;
  }
}
