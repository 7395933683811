.checkout-form {
  display: flex;
  flex-direction: column;
  width: 460px;
  margin: 0 auto;
  background: #1f1e1e;
  padding: 2rem;
  border-radius: 10px;
  gap: 1rem;

  .checkout-heading {
    h5 {
      margin-bottom: 0.8rem;
    }
    border-bottom: #fff 1px solid;
  }

  //   .hr-half-width {
  //     border-top: 0.1rem solid rgb(189, 195, 196);
  //     width: 95%;
  //     margin: 0 auto;
  //   }
  #input-container {
    position: relative;
  }

  #input-container > i {
    position: absolute;

    top: 12px;
    right: 15px;
    // border-radius: 10px;
    // border: 1px solid #ccc;
    font-size: 20px;
  }

  #input-container > input {
    // padding-left: 40px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: transparent;
  }
  .form-row {
    display: flex;

    justify-content: center;
    input {
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #ccc;
      width: 98%;
    }
    .full-width {
      padding-right: 10rem;
      background: url("https://cdn-icons-png.flaticon.com/512/60/60378.png")
        no-repeat right;
      background-size: 30px;
    }
  }
  .form-multiple-row {
    display: flex;
    justify-content: space-between;
    gap: 0.6rem;
    #input-container {
      position: relative;
      width: 100%;
    }

    input {
      background: transparent;
      border-radius: 10px;
      border: 1px solid #ccc;
      color: #7a7777;
    }

    #input-container > i {
      position: absolute;

      top: 12px;
      right: 15px;
      // border-radius: 10px;
      // border: 1px solid #ccc;
      font-size: 20px;
    }

    #input-container > input {
      // padding-left: 40px;
      border-radius: 10px;
      border: 1px solid #ccc;
      background: transparent;
    }
  }
  .form-row {
    .payment-button {
      padding: 8px;
      background: linear-gradient(to bottom, #00bfff, #007fff);
      color: #fff;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;
      margin-left: 4px;
    }
    .payment-button:hover {
      background: linear-gradient(to bottom, #007fff, #00bfff);
    }
  }
}
