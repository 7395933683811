/*
Template: Streamit - Responsive Bootstrap 4 Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/

/*================================================
[  Table of contents  ]
================================================

:: Header
:: Navigation
:: Pages Content
:: Back to Top
:: Sign In

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
                    Header
-----------------------------------------------------------------------*/

/* ---Premium Card---- */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&family=Titillium+Web:wght@400;600;700&display=swap');

.font-raleway{
  /* font-family: "Raleway", sans-serif;
  font-family: "Titillium Web", sans-serif; */
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.no-scroll {
  overflow: hidden !important;
}
.pricingTable {
  text-align: center;
  background: #fff;
  margin: 0 -15px;
  box-shadow: 0 0 10px #ababab;
  padding-bottom: 40px;
  border-radius: 10px;
  color: #cad0de;
  transform: scale(1);
  transition: all 0.5s ease 0s;
}

.text-light{
  font-weight: normal !important;
  opacity: .5;
}
.notif{
  cursor: pointer;
}

.notification-bell {
  position: relative;
  display: inline-block;
}

.badge-notif {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50% !important;
  padding: 4px;
  font-size: 8px;
  font-weight: bold;
  min-width: 18px;
  text-align: center;
}

.pricingTable:hover {
  transform: scale(1.05);
  z-index: 1;
}

.pricingTable .pricingTable-header {
  padding: 40px 0;
  background: #f5f6f9;
  border-radius: 10px 10px 50% 50%;
  transition: all 0.5s ease 0s;
}

.pricingTable:hover .pricingTable-header {
  background: #ff9624;
}

.pricingTable .pricingTable-header i {
  font-size: 50px;
  color: #858c9a;
  margin-bottom: 10px;
  transition: all 0.5s ease 0s;
}

.pricingTable .price-value {
  font-size: 35px;
  color: #ff9624;
  transition: all 0.5s ease 0s;
}

.pricingTable .month {
  display: block;
  font-size: 14px;
  color: #cad0de;
}

.pricingTable:hover .month,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
  color: #fff;
}

.pricingTable .heading {
  font-size: 24px;
  color: #ff9624;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.pricingTable .pricing-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.pricingTable .pricing-content ul li {
  line-height: 30px;
  color: #a7a8aa;
}

.pricingTable .pricingTable-signup a {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  padding: 10px 35px;
  border-radius: 20px;
  background: #ffa442;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.pricingTable-signup button {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  padding: 10px 35px;
  border-radius: 20px;
  border: none;
  background: var(--iq-primary);
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.pricingTable-signup button:hover {
  box-shadow: 0 0 10px var(--iq-primary-hover);
}

.pricingTable .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #ffa442;
}

.pricingTable.blue .heading,
.pricingTable.blue .price-value {
  color: var(--iq-primary);
}

.pricingTable.blue .pricingTable-signup a,
.pricingTable.blue:hover .pricingTable-header {
  background: var(--iq-primary);
}

.pricingTable.blue .pricingTable-signup a:hover {
  box-shadow: 0 0 10px var(--iq-primary-hover);
}
.pricingTable.blue:hover .price-value {
  color: #fff;
}

@media screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 20px;
  }
}

/* ----- */

.special_modal .modal-content {
  color: #000;
}
.blurred-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for desired transparency */
  backdrop-filter: blur(5px); /* Optional: Apply additional blur effect */
  z-index: 9999; /* Ensure the overlay is on top of the modal */
}
.modal-content {
  background-color: transparent !important;
}
header#main-header {
  position: fixed;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 99;
  background: rgba(20, 20, 20, 0.5) !important;
}
header .navbar-light .navbar-brand img.logo {
  width: 80px;
  margin: 0px;
}
.iq-search-bar .search-input {
  width: 100%;
  height: 40px;
  padding: 5px 15px 5px 40px;
  border: none;
  border-radius: 0;
  color: var(--iq-white);
  background: var(--iq-bg1);
}
.iq-search-bar .searchbox .search-link {
  position: absolute;
  left: 15px;
  top: 6px;
  font-size: 16px;
  color: white;
}
.iq-search-bar .searchbox .close-link {
  position: absolute;
  right: 14px;
  top: 7px;
  font-size: 18px;
  color: white;
}
.iq-search-bar .searchbox .search-link-button {
  position: absolute;
  left: 14px;
  top: 7px;
  font-size: 18px;
  color: white;
}
.load-more-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
/* Pagination color start */
.custom-pagination .page-link {
  color: #ffffff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.custom-pagination .page-item.active .page-link {
  color: #007bff;
  background-color: transparent !important;
}
/* Pagination color end */

.load-more-button:hover {
  background-color: #23527c;
}
.nav-item span.dots {
  height: 10px;
  width: 10px;
  font-size: 0;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 3px;
  right: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.menu-right .nav-item span.dots {
  right: 0;
}
.navbar-right li .iq-sub-dropdown .iq-sub-card {
  position: relative;
  font-size: inherit;
  padding: 15px 15px;
  line-height: normal;
  color: inherit;
  text-align: left;
  display: inline-block;
  width: 100%;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 1px 0;
  border-image-source: linear-gradient(
    to right,
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0.3),
    rgba(209, 208, 207, 0)
  );
}
.iq-sub-card.setting-dropdown {
  padding: 15px 15px 15px 0 !important;
}
.navbar-right li .iq-sub-dropdown a.iq-sub-card:last-child {
  border-bottom: 0;
}
.notify-bg {
  overflow: hidden;
  z-index: 99;
}
.notify-bg:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 70px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-30deg);
  animation: noti-before-animation 2s infinite 2s;
}
.notify-bg:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-30deg);
  animation: noti-after-animation 2s infinite 2s;
}
.navbar-right .iq-sub-dropdown .iq-card-body div {
  border-radius: 0;
}
.navbar-right .iq-show .iq-sub-dropdown,
.iq-show .search-box {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.navbar-right .iq-sub-dropdown {
  width: 300px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 15px;
  background: var(--iq-bg1);
  transform: translate(0, 70px);
  -webkit-transform: translate(0, 70px);
  transition: all 0.3s;
  padding: 0;
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
}

/* language dropdown------------------------------------------- */
.navbar-right .iq-lang-dropdown {
  width: auto !important;
  right: 0px !important;
}

.navbar-light .navbar-toggler-icon {
  background: url(../../assets/images/menu.png) no-repeat scroll center center;
}
.search-toggle:hover,
header .navbar ul li.menu-item a:hover {
  color: var(--iq-primary) !important;
}
.search-wrapper {
  position: relative;
  display: inline-block;
  height: 36px;
  vertical-align: bottom;
}
.searchbutton {
  position: absolute;
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
  right: 16px;
  color: var(--iq-body-text);
}
.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  color: var(--iq-primary);
}
.search:hover + .searchbutton {
  color: var(--iq-white);
}
.search {
  position: absolute;
  left: 49px;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 0 0 0 16px;
  width: 0;
  height: 100%;
  z-index: 10;
  color: var(--iq-white);
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}
.search:focus,
.search:hover {
  width: 350px;
  padding: 0 0 0 0;
  border-color: var(--iq-body-text);
}
.expandright {
  left: auto;
  right: -6px;
  bottom: -2px;
}
.search::placehoder {
  color: var(--iq-white) !important;
}
.noti-svg {
  -webkit-animation: notify 1.5s ease infinite;
  animation: notify 1.5s ease infinite;
  fill: var(--iq-body-text);
}
.noti-svg:hover {
  fill: var(--iq-primary);
}
.search-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 46px;
  min-width: 25rem;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transform: translate(0, 70px);
  -webkit-transform: translate(0, 70px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.search-toggle {
  padding: 0 !important;
}

header.style-one li .search-box input[type="search"] {
  border: none;
  background: var(--iq-bg1);
}
header.style-one li .search-box .search-submit {
  right: auto;
  left: 0;
  background: transparent;
}
header#main-header {
  position: fixed;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 999;
  background: rgba(20, 20, 20, 0.5) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
/*--------------------------------------------------------------
                       Navigation
--------------------------------------------------------------*/
header#main-header.menu-sticky {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
}
header#main-header.menu-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(20, 20, 20, 0.5) !important;
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}
header .navbar .menu-header-menu-container,
header .navbar .menu-all-pages-container,
header .navbar .menu-testing-menu-container,
header .navbar .menu-short-container,
header .navbar .menu-main-menu-container {
  display: inline-block;
  width: 100%;
}
header .navbar ul.navbar-nav {
  display: block;
  text-align: center;
}
header .navbar ul li {
  list-style: none;
  margin-right: 18px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
}
header .navbar ul li.menu-item a {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding: 0px 0px 0px 10px;
  line-height: 70px;
  position: relative;
  z-index: 9;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}
@media (min-width: 991px) and (max-width: 1132px) {
  header .navbar ul li.menu-item a {
    font-size: 12px;
    font-weight: 300;
  }
  .logo {
    width: 3.2rem !important;
  }
}
@media (max-width: 991px) {
  .logo {
    width: 7rem !important;
  }
  .navbar-light .navbar-toggler-icon {
    width: 1.2rem;
    height: 1.5rem;
    padding: 0px;
  }
  .c-toggler {
    padding: 0px;
  }
}
.nav-open .nav-overlay {
  visibility: visible;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.nav-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 900;
  display: block;
  width: 100%;
  position: fixed;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.more-toggle {
  color: var(--iq-white) !important;
  font-size: 22px;
}
.dropdown-toggle.more-toggle::after {
  content: none;
}
.mobile-more-menu {
  display: none;
}
.more-menu {
  position: absolute;
  top: 50px;
  left: auto;
  right: 0;
  min-width: 23rem;
  padding: 0.35rem 0.5rem;
  margin: 0;
  opacity: 0;
  transform: translate(0, 70px);
  -webkit-transform: translate(0, 70px);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: var(--iq-bg1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  visibility: hidden;
  z-index: 999;
}
.more-menu.show {
  opacity: 1;
  transform: translate(0, 0);
  visibility: visible;
}
header .navbar ul li:last-child {
  margin-right: 0;
}

/*--------------------------------------------------------------
                       Main Slider
--------------------------------------------------------------*/
#home-slider ul.slick-dots {
  bottom: 30px;
}
#home-slider .slick-dots li {
  height: auto;
  width: auto;
}
#home-slider .slick-dots li button {
  height: 2px;
  width: 30px;
  background: rgba(229, 9, 20, 0.4);
  padding: 0;
}
#home-slider .slick-dots li.slick-active button {
  background: var(--iq-primary);
}
#home-slider li {
  position: relative;
}
.slider-description {
  position: absolute;
  top: 15%;
  left: 80px;
}
#home-slider h1.slider-text {
  font-size: 60px;
  margin: 14px 0;
}
h1.slider-text-video {
  font-size: 45px;
  margin: 14px 0;
}
@media (min-width: 550px) and (max-width: 760px) {
  h1.slider-text-video {
    font-size: 20px;
  }
}
@media (max-width: 549px) {
  h1.slider-text-video {
    font-size: 15px;
  }
}
@media (min-width: 766px) and (max-width: 850px) {
  h1.slider-text-video {
    font-size: 25px;
    margin: 0;
  }
}
@media (min-width: 850px) and (max-width: 1000px) {
  h1.slider-text-video {
    font-size: 35px;
    margin: 0;
  }
}
@media (min-width: 1001px) and (max-width: 1400px) {
  h1.slider-text-video {
    font-size: 40px;
    margin-top: 1rem;
  }
}
h1.live-title {
  /* font-size: 30px; */
  margin: 10px 0;
  max-width: 150px;
  min-width: 150px;
}
@media (max-width: 600px) {
  h1.live-title {
    font-size: 20px;
    margin: 5px 0;
  }
}
@media (max-width: 601px) and (max-width: 750px) {
  h1.live-title {
    font-size: 25px;
    margin: 5px 0;
  }
}
@media (max-width: 575px) {
  h1.live-title {
    max-width: 300px !important;
  }
}
@media (max-width: 340px) {
  h1.live-title {
    max-width: 200px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1220px) {
  h1.live-title {
    font-size: 25px;
    margin: 5px 0;
    max-width: 150px !important;
  }
}
@media (min-width: 1221px) and (max-width: 1400px) {
  h1.live-title {
    max-width: 250px !important;
  }
}
@media (min-width: 1401px) and (max-width: 1600px) {
  h1.live-title {
    font-size: 25px;
    margin: 5px 0;
    max-width: 350px !important;
  }
}
#home-slider p {
  margin: 20px 0;
  width: 75%;
}
#home-slider .slick-bg {
  padding: 100px 0 50px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 105vh;
  position: relative;
  z-index: 1;
}

#home-slider .slick-bg.s-bg-1 {
  background-image: url(../../assets/images/slider/slider1.jpg);
}
#home-slider .slick-bg.s-bg-2 {
  background-image: url(../../assets/images/slider/slider2.jpg);
}
#home-slider .slick-bg.s-bg-3 {
  background-image: url(../../assets/images/slider/slider3.jpg);
}
.shows-content {
  position: absolute;
  top: 70px; /* Changes for videos page  */
  left: 25px;
  z-index: 99;
  width: 90%;
}
.dot {
  display: inline-block;
  padding-right: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: green;
}
.view-btn {
  position: absolute;
  right: 45px;
}
.swiper-slide .view-btn .livebtn {
  position: relative;
  bottom: 10px;
}
@media (min-width: 850px) and (max-width: 1000px) {
  .view-btn {
    position: relative;
    top: 20px !important;
  }
}
@media (min-width: 1001px) and (max-width: 1400px) {
  .view-btn {
    position: relative;
    top: 50px !important;
  }
}
@media (min-width: 1401px) and (max-width: 1800px) {
  .view-btn {
    position: relative;
    top: 65px !important;
  }
}
@media (min-width: 1801px) and (max-width: 2200px) {
  .view-btn {
    position: relative;
    top: 100px !important;
  }
}

.live-button-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: green;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
}

.circle-icon {
  margin-right: 8px;
  font-size: 8px;
}

.label {
  text-transform: uppercase;
}

.content-size {
  position: absolute;
  top: 50px;
  left: 15px;
  z-index: 99;
}
.content-size {
  position: absolute;
  left: 15px;
  z-index: 99;
  width: 100%;
}
.content-size-live-badge {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 99;
}
.gallery-container .image-size:hover .content-size-live-badge {
  right: -11px;
}

.content-size-live {
  position: absolute;
  /* top: 13%;
  left: 5%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 90%;
}
@media (max-width: 900px) and (min-width: 768px) {
  .movie-time .live-desc {
    display: none !important;
  }
  .content-size-live {
    top: 62% !important;
  }
}
@media (max-width: 1150px) {
  .content-size-live {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 90%;
    margin-bottom: 1rem;
  }
  .live-channel-container {
    margin-bottom: 1rem;
  }
}

.gallery-container .image-size:hover .content-size-live .live-desc {
  -webkit-line-clamp: 3;
  text-overflow: "ellipsis";
  overflow: "hidden";
  max-width: 200px;
}
/* @media (min-width: 375px) and (max-width: 600px) {
  .content-size-live {
    position: absolute;
    left: 0px;
    z-index: 99;
  }
}
@media (min-width: 651px) and (max-width: 700px) {
  .content-size-live {
    position: absolute;
    top: 50px;
    left: 20px;
    z-index: 99;
  }
}
@media (min-width: 751px) and (max-width: 850px) {
  .content-size-live {
    position: absolute;
    top: 35px;
    left: 0px;
    z-index: 99;
  }
}
@media (min-width: 851px) and (max-width: 900px) {
  .content-size-live {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 99;
  }
}
@media (min-width: 901px) and (max-width: 1099px) {
  .content-size-live {
    position: absolute;
    top: 50px;
    left: 0px !important;
    z-index: 99;
  }
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .content-size-live {
    position: absolute;
    top: 75px;
    left: 0px !important;
    z-index: 99;
  }
}
@media (min-width: 1201px) and (max-width: 1300px) {
  .content-size-live {
    position: absolute;
    top: 90px;
    left: 10px;
    z-index: 99;
  }
}
@media (min-width: 1301px) and (max-width: 1500px) {
  .content-size-live {
    position: absolute;
    top: 105px;
    left: 10px;
    z-index: 99;
  }
}
@media (min-width: 1501px) and (max-width: 1600px) {
  .content-size-live {
    position: absolute;
    top: 100px;
    left: 10px;
    z-index: 99;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .content-size-live {
    position: absolute;
    top: 125px;
    left: 0px !important;
    z-index: 99;
  }
}
@media (min-width: 1801px) and (max-width: 2200px) {
  .content-size-live {
    position: absolute;
    top: 180px;
    left: 0px !important;
    z-index: 99;
  }
} */
@media (min-width: 651px) and (max-width: 700px) {
  .content-size {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
  }
}
@media (min-width: 701px) and (max-width: 1200px) {
  .content-size {
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 99;
  }
}
@media (min-width: 1201px) and (max-width: 1500px) {
  .content-size {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 99;
  }
}

@media (max-width: 650px) {
  .content-size {
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 99;
  }
}

/* Changes Main slider para */
.mainSlider-Movie {
  -webkit-line-clamp: 3;
  text-overflow: "ellipsis";
  overflow: "hidden";
}
.paraOverflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
@media only screen and (min-width: 431px) and (max-width: 500px) {
  .live-desc {
    -webkit-line-clamp: 1 !important;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 200px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 430px) {
  .live-desc {
    display: none !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 680px) {
  .live-desc {
    -webkit-line-clamp: 2;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 200px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 999px) {
  .live-desc {
    -webkit-line-clamp: 2;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 200px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .live-desc {
    -webkit-line-clamp: 3;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 200px;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .live-desc {
    -webkit-line-clamp: 3;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 400px !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .live-desc {
    -webkit-line-clamp: 3;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 480px !important;
  }
}
@media only screen and (min-width: 1801px) and (max-width: 2000px) {
  .live-desc {
    -webkit-line-clamp: 3;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 520px !important;
  }
}
@media only screen and (min-width: 1801px) and (max-width: 2000px) {
  .live-desc {
    -webkit-line-clamp: 3;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 540px !important;
  }
}
@media only screen and (min-width: 2001px) and (max-width: 2200px) {
  .live-desc {
    -webkit-line-clamp: 4;
    text-overflow: "ellipsis";
    overflow: "hidden";
    max-width: 600px !important;
  }
}
.heading-length {
  /* white-space: nowrap; */
  text-overflow: ellipsis !important;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.heading-main-small {
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 500px;
}
.live-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  max-width: 500px;
}
@media only screen and (min-width: 1200px) {
  .mainSlider-Movie {
    -webkit-line-clamp: 3;
    max-width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mainSlider-Movie {
    -webkit-line-clamp: 2;
    min-width: 50%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mainSlider-Movie {
    -webkit-line-clamp: 1;
    max-width: 250px;
  }
}

@media only screen and (max-width: 480px) {
  .mainSlider-Movie {
    -webkit-line-clamp: 1;
    max-width: 200px;
  }
}

/* .trailor-video { position: absolute; bottom: 0; right: 0; z-index: 999;text-align: center; } */
.iq-view-all {
  font-weight: 700;
}
/* .trailor-video {
    margin: 0 auto;
    text-align: center;
} */
.channel-name {
  color: var(--iq-primary);
  font-size: 20px;
  margin-left: 10px;
  letter-spacing: 2.5px;
  font-weight: 500;
}
.c-logo {
  width: 100px;
}
.episode-section-season {
  margin-bottom: 1rem !important;
  background-color: #191919 !important;
}

.episode-section-season h5 {
  background: linear-gradient(
    to bottom,
    rgba(13, 71, 161, 0.3),
    rgba(131, 197, 209, 0)
  ) !important;
  display: inline-block !important;
  padding: 0.7rem !important;
  border-top: 5px solid #0453e5 !important;
  color: #fff !important;
}
.channel-logo {
  border-left: 5px solid var(--iq-primary);
  background: transparent
    /* linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 55, 65, 0.3) 100%); */
    linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(39, 170, 225, 0.3) 100%);
  padding: 10px 10px 10px 15px;
  width: 255px;
  position: relative;
  overflow: hidden;
}

.circle {
  stroke: var(--iq-primary);
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.playbtn {
  display: inline-block;
  -webkit-transition: all 0.5s ease;
}
.playbtn .triangle {
  -webkit-transition: all 0.7s ease-in-out;
  stroke-dasharray: 240;
  stroke-dashoffset: 480;
  stroke: var(--iq-white);
  transform: translateY(0);
}
.playbtn:hover .triangle {
  stroke-dashoffset: 0;
  opacity: 1;
  stroke: var(--iq-primary);
  animation: trailorPlay 0.7s ease-in-out;
}
.playbtn:hover .circle {
  stroke-dashoffset: 0;
  opacity: 1;
}
.season-banner-container {
  padding-top: 12rem;
}
.lock-banner-container {
  padding-top: 6rem;
  padding-bottom: 16rem;
  width: 73%;
}

.season-banner {
  position: relative;
  background-size: "cover";
  background-repeat: "no-repeat";
  background-position: "center";

  /* padding-top: 12rem !important; */
}
.season-banner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  object-fit: cover;
  z-index: -9999;
  opacity: 0.3;
  background-color: black;
}
@media (min-width: 1400px) {
  .season-banner img {
    height: 100vh;
  }
  .season-banner-description span {
    font-size: 1.5rem;
  }
}
@media (max-width: 1400px) {
  .season-banner img {
    height: 96vh;
  }
  .season-banner-description span {
    font-size: 1.2rem;
  }
}
@media (max-width: 1118px) {
  .season-banner img {
    height: 104vh;
  }
}
/* .season-banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  background-color: black;
} */
.w-trailor {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--iq-white);
  margin-left: 10px;
}

.w-trailor-slider {
  font-size: 1.3rem;
  letter-spacing: 3px;
  font-weight: 500;
  color: var(--iq-white);
}

.slider-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.big-title {
  /* background: url("../images/texure.jpg");
  background-repeat: repeat-x;
  background-position: 100% 100%;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  
}
.slick-track {
  margin: unset !important;
}
/*---------------------------------------------------------------------
                      Pages Content
-----------------------------------------------------------------------*/
.main-content {
  padding-top: 40px;
}
.block-space {
  padding: 40px 0;
}
.s-margin {
  margin-top: 40px;
}
.iq-main-header {
  margin-bottom: 20px;
}
.main-title a:hover,
.main-title a:focus {
  color: var(--iq-primary) !important;
}
:focus {
  outline: none !important;
}
/*--------------------------*/

.red-heart {
  background: var(--iq-primary) !important;
  color: var(--iq-white);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding-top: 2px;
}
.red-heart:hover {
  background: var(--iq-white) !important;
  color: var(--iq-primary) !important;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding-top: 2px;
}
.blue-thumb-up {
  background: var(--iq-primary) !important;
  color: var(--iq-white);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding-top: 2px;
}

.favorites-slider .slick-list,
#top-ten-slider-nav .slick-list {
  overflow: visible;
  padding-bottom: 40px !important;
}
.favorites-slider li.slide-item {
  float: left;
  width: 25%;
}
.favorites-slider .slick-list {
  overflow: visible;
}
li.slide-item {
  position: relative;
  padding: 0 15px;
}
li.slide-item .block-images {
  position: relative;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  transition: all 0.45s ease 0s;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
}

li.slide-item:hover .block-images {
  overflow: visible;
}
li.slide-item:hover .block-images .img-box {
  position: relative;
}
li.slide-item:hover .block-images {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9); */
}

.block-social-info {
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 25px;
  z-index: 999;
  display: flex;
  align-items: center;
  opacity: 0;
}
@media (max-width: 500px) {
  .block-social-info {
    right: 40px;
  }
}
.block-social-info-live {
  position: absolute;
  top: 50%;
  /* right: auto; */
  /* bottom: 0; */
  right: 25px;
  z-index: 999;
  display: flex;
  align-items: end;
  opacity: 0;
}
.swiper-slide:hover .block-social-info-live {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}
.block-social-info-search {
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 25px;
  z-index: 999;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 1.5s ease; /* Add transition for smooth effect */
}

.gallery-item:hover .block-social-info-search {
  opacity: 1;
}
@media (max-width: 900px) and (min-width: 768px) {
  .block-social-info-search .music-play-lists li {
    width: 20px;
    height: 20px;
  }
  .block-social-info-search .music-play-lists span {
    width: 15px;
    height: 15px;
  }
  .block-social-info-search .music-play-lists span i {
    font-size: 5px;
  }
}
@media (max-width: 900px) and (min-width: 680px) {
  .block-social-info-search .music-play-lists li {
    width: 15px;
    height: 15px;
  }
  .block-social-info-search .music-play-lists span {
    width: 10px;
    height: 10px;
  }
  .block-social-info-search .music-play-lists span i {
    font-size: 8px;
  }
}

@media (max-width: 660px) {
  .block-social-info-search .music-play-lists li {
    display: none;
  }
}
@media (max-width: 1500px) and (min-width: 980px) {
  .block-social-info-search .music-play-lists li {
    width: 40px;
    height: 40px;
  }
  .block-social-info-search .music-play-lists span {
    width: 30px;
    height: 30px;
  }
  .block-social-info-search .music-play-lists span i {
    font-size: 15px;
  }
}
.music-play-lists li {
  position: relative;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 0 auto 7px;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
}
.music-play-lists span {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 26px;
  font-size: 15px;
  text-align: center;
  /* background: var(--iq-primary); */
  color: var(--iq-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  cursor: pointer;
}

.music-play-lists .count-box {
  height: 15px !important;
  width: 15px !important;
  line-height: 15px i !important;
  font-size: 8px !important;
  /* background: var(--iq-primary) !important; */
  color: var(--iq-white-color) !important;
  position: absolute;
  right: 0;
  top: 0px;
  padding: 0;
  text-align: center !important;
}

.music-play-lists li:hover span {
  background: var(--iq-primary);
  color: var(--iq-white);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.block-description {
  position: absolute;
  left: 25px;
  /* top: 0; */
  /* bottom: 10px; */
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-slider-content {
  display: flex;
  width: 100%;
  align-items: center;
}

.badge-right {
  position: absolute;
  /* top: 0; */
  right: 10px;
  bottom: 10px;
  z-index: 999;
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.swiper-slide:hover .block-description {
  top: 0;
}
.swiper-slide:hover .block-description .badge-right {
  top: 0;
}
.block-description h6.iq-title {
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #eee; /* Set the text color to dark white */
}

.block-description-genre {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  text-transform: capitalize;
  background: linear-gradient(
    20deg,
    rgb(0 0 0 / 60%) 0%,
    rgb(0 0 0 / 80%) 50%,
    rgba(83, 100, 141, 0) 100%
  );
  z-index: 1;
  /* padding-bottom: 30px; */
}
.history-duration {
  position: absolute;
  bottom: 0;
  left: 85%;
  transform: translateX(-50%);
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: 2px 5px;
}
.iq-title {
  font-size: 18px;
  color: var(--iq-white-color);
  text-transform: capitalize;
}
.iq-title-contant {
  font-size: 40px;
  color: var(--iq-white-color);
  text-transform: capitalize;
}
.title-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  margin-bottom: 10px;
}

.live-title-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  margin-bottom: 10px;
}
.block-description-genre:hover {
  background-color: #000000a6;
  color: var(--iq-white-color);
  text-transform: capitalize;
  transition: opacity 0.3s ease;
     z-index: 1;
}
.iq-title-genre {
  /* font-size: 24px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
  padding: 0px 0px 0px 15px;
  width: 100%;
}
.block-description > h6 {
  font-size: 1.5em;
}
.block-description .iq-title {
  font-size: 18px;
  color: var(--iq-white-color);
  text-transform: capitalize;
}
.text-white {
  font-size: 14px;
}

li.slide-item .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.8); */
  width: 100%;
  height: 100%;
  /* opacity: 0; */
}
li.slide-item:hover .block-images::before {
  opacity: 1;
  z-index: 9;
}
li.slide-item:hover .block-description {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}
li.slide-item:hover .block-social-info {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}
.hover-buttons .btn {
  padding: 5px 10px;
  font-size: 12px;
}

/* .hover-buttons-search {
  padding: 0px 0px;
  font-size: 12px;
}
.hover-buttons-search:hover {
  opacity: 1;
} */

.live-channel-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.live-title {
  flex: 1;
}

/* .livebtn-hover {
  margin-left: -60px;
} */
/* @media (min-width: 1200px) and (max-width: 1500px) {
  .image-size:hover .livebtn-hover {
    margin-left: -100px !important;
  }
}

@media (min-width: 1550px) and (max-width: 2200px) {
   .livebtn-hover {
    margin-right: 15px !important;
  }
} */

.livebtn-hover {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.image-size:hover .livebtn-hover {
  opacity: 1 !important;
}

.btnV {
  padding: 5px 10px;
  font-size: 12px;
}
li.slide-item.slick-current:hover .block-images {
  transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
}

/* code */
#trending-slider-nav .slick-current.slick-active {
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  -webkit-transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0)
    perspective(500px);
  -moz-transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  -o-transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
}

/* edited footer start */

li.slide-item .block-images::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(20, 20, 20, 0.4) 50%,
    rgba(83, 100, 141, 0) 100%
  ); */
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
}
.slick-vertical .slick-slide .block-images::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(20, 20, 20, 0.4) 50%,
    rgba(83, 100, 141, 0) 100%
  ); */
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}

small,
.text_small,
span {
  font-size: 14px;
}
a:focus,
a:hover {
  color: var(--iq-primary);
  outline: none;
}

li.slide-item {
  position: relative;
  /* padding: 0px 10px; */
}
li.slide-item .block-images {
  position: relative;
  width: 100%;
  transition: all 0.45s ease 0s;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
}
li.slide-item:hover .block-images {
  overflow: visible;
  border-left: 5px solid var(--iq-primary-hover);
}
li.slide-item:hover .block-images .img-box {
  position: relative;
}
li.slide-item:hover .block-images {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px);
  -webkit-transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0)
    perspective(500px);
  -moz-transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  -o-transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
}

#home-slider .trending-list {
  margin: 0 0 20px 0;
}
.slider-ratting ul li {
  margin-right: 5px;
}

.btn-bordered {
  border: 1px solid #007bff;
  padding: 2px 4px;
  align-items: center;
}

.buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
}
@media (max-width: 500px) {
  .buttons-container {
    justify-content: center;
    gap: 0.7rem;
  }
}
/* @media (max-width: 500px) {
  .donate-icon{
  width: 10px !important;
  height: 10px !important;
                              
  }
  .donate-button-text{
    font-size: 0.7rem !important;
  }
} */

.donate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #69aff5;
  padding: 10px;
  width: auto;
  height: 50px;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.donate-button-text {
  margin-right: 10px;
  font-size: 1.2rem;
  min-width: 50px;
}

.donate-icon {
  width: 20px;
  height: 20px;
}

.iq-button-live.btn {
  padding: 4px 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  font-size: 10px;
  font-weight: 400;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .iq-button-live.btn .livebtn {
    padding: 5px 10px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
@media (min-width: 550px) and (max-width: 1200px) {
  .iq-button-live.btn .livebtn {
    padding: 2px 5px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
/* edited end */

.block-images .hover-buttons {
  margin-top: 0px;
  margin-bottom: 5px;
}
.block-social-info .music-play-lists li {
  width: 40px;
  height: 40px;
}
.block-social-info .music-play-lists span {
  width: 30px;
  height: 30px;
}
.block-social-info .music-play-lists span i {
  font-size: 15px;
}

/*-------------------*/
.movie-content li {
  position: relative;
  padding-right: 20px;
  font-size: 1.2rem;
  color: #21a6e0 !important;
}
.m.movie-content li:last-child {
  padding-right: 0px;
}
.movie-content li:before {
  content: "";
  height: 3px;
  width: 3px;
  background: #dddddd;
  position: absolute;
  top: 10px;
  right: 8px;
  border-radius: 50%;
}
.movie-detail .movie-content li:before {
  width: 5px;
  height: 5px;
}
.movie-content li:last-child:before {
  display: none;
}
li.slide-item .block-description .ratting-start {
  font-size: 12px;
}
li.slide-item .block-description .badge {
  background-color: rgb(72, 72, 72, 0.61);
}
li.slide-item .block-description .parallax-ratting span {
  font-size: 14px;
}
.slick-vertical .slick-slide.slick-current.slick-active .block-description {
  left: 25px;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  opacity: 1;
}
.slick-vertical .slick-slide .block-images {
  overflow: visible;
}
.slick-vertical .slick-slide .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  border-left: 6px solid var(--iq-primary);
  z-index: 9;
}
.slick-vertical .slick-slide.slick-current.slick-active .block-images::before {
  opacity: 1;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.slick-vertical .slick-slide.slick-current.slick-active .block-images {
  width: 100%;
  overflow: visible;
  transform-origin: 100% 100%;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  z-index: 9;
}
.slick-vertical li.slick-slide.slick-current.slick-active {
  transition: all 0.45s ease 0s;
}
.slick-vertical .slick-slide {
  margin-bottom: 18px;
}
.slick-vertical li.slick-slide:last-child {
  margin-bottom: 0 !important;
}

/* ads */

.slick-vertical .block-description .hover-buttons > a {
  opacity: 0;
  display: none;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.slick-vertical
  .slick-slide.slick-current.slick-active
  .block-description
  .hover-buttons
  > a {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  opacity: 1;
  display: block;
}

/*---------------------*/
#trending-slider .tranding-block,
.banner-wrapper {
  background-size: cover;
  background-position: top right;
  position: relative;
  width: 100%; /* width of trending 2nd slider */
}
.trending-pills {
  background: rgb(0, 0, 0, 0.25);
}
.trending-info {
  padding: 60px 0;
}
.tab-title-info {
  z-index: 99;
}
.trending-content .tab-pane.fade {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.trending-content .tab-pane.fade.active.show {
  display: block;
  visibility: visible;
  opacity: 1;
}
.trending-content .tab-pane {
  width: 100%;
  height: 100%;
}
.trending-pills.nav-pills .nav-item a {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
}
.trending-pills.nav-pills .nav-item a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 3px;
  background: var(--iq-primary);
  opacity: 0;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}
.trending-pills.nav-pills .nav-item a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(83, 100, 141, 0) 0%,
    rgb(218, 64, 60, 0.3) 85%
  );
  content: "";
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, 50%, 0);
  -moz-transform: translate3d(0, 50%, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.trending-pills.nav-pills .nav-item a.show:before {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.trending-pills.nav-pills .nav-item a.show:after {
  opacity: 1;
  width: 100%;
}
.trending-pills.nav-pills .nav-item a:hover {
  color: var(--iq-primary);
}
.trending-content {
  padding: 0px 50px;
  display: flex;
}
.p-btns {
  display: flex;
  align-items: center;
}
.trending-info .trending-text {
  font-size: 60px;
  line-height: 100px;
  margin: 15px 0;
}
.trending-info .text-detail {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin-bottom: 30px;
}
.trending-info .text-detail .badge {
  font-size: 20px;
}
.badge.badge-trend {
  background: rgb(0, 0, 0, 0.42);
}
.text-detail .trending-year {
  position: relative;
  padding-left: 30px;
}
.text-detail .trending-year:before {
  content: "";
  height: 6px;
  width: 6px;
  background: var(--iq-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  border-radius: 50%;
}
.overview-tab:before,
.slick-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(20, 20, 20, 1) 0%,
    rgba(36, 36, 36, 1) 35%,
    rgba(83, 100, 141, 0) 100%
  );
  z-index: 1;
}
.slick-bg:before {
  background: linear-gradient(
    90deg,
    #000,
    #141414,
    rgb(0 0 0 / 37%),
    rgb(83 100 141 / 0%),
    rgb(83 100 141 / 0%)
  );
  width: 100%;
  z-index: -1;
}
.overlay-tab:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 36, 36, 0.8);
  z-index: 1;
}
.trending-info {
  position: relative;
  z-index: 99;
}
.trending-info .trending-dec {
  margin-bottom: 30px;
  width: 58%;
}
.trending-info .block-social {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
}
.trending-info .block-social a {
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  background: var(--iq-white);
  color: var(--iq-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.trending-info .block-social.social1 a {
  background: var(--iq-primary);
  color: var(--iq-white);
}
.trending-list .title {
  font-size: 18px;
  font-weight: 500;
}
.trending-list .title span {
  font-size: 16px;
  font-weight: 400;
}
.trending-info .iq-dropdown .form-control {
  background: rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  color: var(--iq-white);
}

/*-----------------------*/
/* #trending-slider-nav .slick-list { padding-bottom: 35px !important; }
#trending-slider-nav .movie-slick { border: 12px solid transparent; transition: all 0.4s ease; }
#trending-slider-nav .slick-current.slick-active .movie-slick { border-color: var(--iq-bg1); transition: all 0.4s ease; }
#trending-slider-nav .movie-slick:before { content: ''; position: absolute; left: 50%; bottom: -31px; transform: translateX(-50%); width: 0; height: 0; border-left: 18px solid transparent; border-right: 18px solid transparent; border-top: 26px solid var(--iq-bg1); opacity: 0; transition: all 0.4s ease; z-index: 999; }
#trending-slider-nav .slick-current.slick-active .movie-slick:before { opacity: 1; }
.e-item:hover .episodes-description { color: var(--iq-white) !important; }
.episodes-description a:hover { color: var(--iq-primary); }
.episode-number { position: absolute; top: 15px; left: 15px; height: 30px; width: 30px; line-height: 30px; text-align: center; color: var(--iq-white); background: rgb(134, 134, 134, 0.6); border-radius: 50%; opacity: 0; filter: alpha(opacity=0); -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
.e-item:hover .episode-number { opacity: 1; filter: alpha(opacity=100); -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
.episode-play-info { position: absolute; left: 0; right: 0; top: 38%; opacity: 0; filter: alpha(opacity=0); -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
.e-item:hover .episode-play-info { opacity: 1; filter: alpha(opacity=100); -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
.episode-play { height: 45px; width: 45px; line-height: 45px; text-align: center; background: rgba(255, 255, 255, 0.15); border-radius: 50%; margin: 0 auto; display: flex; -webkit-display: flex; -moz-display: flex; -o-display: flex; align-items: center; }
.episode-play a { position: relative; display: block; height: 32px; width: 32px; line-height: 26px; font-size: 16px; text-align: center; background: var(--iq-white); color: var(--iq-primary); border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -o-border-radius: 50%; margin: 0 auto; display: flex; -webkit-display: flex; -moz-display: flex; -o-display: flex; align-items: center; justify-content: center; transition: all 0.45s ease 0s; -webkit-transition: all 0.45s ease 0s; -moz-transition: all 0.45s ease 0s; -o-transition: all 0.45s ease 0s; }
.episodes-slider1 .owl-nav { position: absolute; top: 50%; transform: translateY(-50%); left: 0; right: 0; color: #fff; }
.episodes-slider1 .owl-nav button.owl-prev, .episodes-slider1 .owl-nav button.owl-next { float: left; width: 40px; height: 40px; border-radius: 50%; background: rgba(255, 55, 65, 0.8); line-height: 23px; font-size: 27px; text-align: center;display: flex;align-items: center;justify-content: center;}
.episodes-slider1 .owl-nav button.owl-next { float: right !important; } */

.movies-tab-desc p {
  margin-bottom: 0;
  display: inherit !important;
}
#iq-trending {
  overflow: hidden;
}
#trending-slider-nav .slick-list {
  padding-bottom: 40px !important;
  overflow: visible;
}
#trending-slider-nav .movie-slick {
  border: 12px solid transparent;
  transition: all 0.4s ease;
  z-index: -1;
}
#trending-slider-nav .slick-current.slick-active .movie-slick {
  transition: all 0.4s ease;
  padding: 10px;
  background: rgba(20, 20, 20, 0.5) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(1px);
  border: 1px solid #5d5757;
}
#trending-slider-nav .slick-current.slick-active {
  transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px);
  -webkit-transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0)
    perspective(500px);
  -moz-transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px);
  -o-transform: scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
}
.trending-contens .episodes-contens {
  margin-top: 30px;
}
.e-item .episodes-description {
  color: var(--iq-body-text) !important;
  background: var(--iq-bg1);
  padding: 10px;
}

.episodes-description-badge {
  background: #1786b6 !important;
  padding: 2px 5px 2px 5px !important;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 6px;
}
.e-item:hover .episodes-description {
  color: var(--iq-white-color) !important;
}
.episodes-description a:hover {
  color: var(--iq-primary);
}
.episode-number {
  color: var(--iq-white-color);
}
.episode-duration {
  position: absolute;
  top: 15px;
  left: auto;
  right: 15px;
  font-weight: 700;
  background: var(--iq-primary);
  color: var(--iq-white-color);
  padding: 0 5px;
}
.e-item .episodes-description p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.e-item:hover .episode-number {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.episode-play-info {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.e-item:hover .episode-play-info {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.episode-play {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background: rgba(53, 176, 207, 0.336);
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
}

.episode-play:hover {
  background: rgba(115, 215, 238, 0.822) !important;
}
.episode-play a {
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  background: var(--iq-white-color);
  color: var(--iq-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.episodes-slider1 .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  color: #fff;
}
.episodes-slider1 .owl-nav button.owl-prev,
.episodes-slider1 .owl-nav button.owl-next {
  float: left;
  width: 35px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  line-height: 23px;
  font-size: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.episodes-slider1 .owl-nav button.owl-next {
  float: right !important;
}

/* parallax */
.parallax-img img {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6);
}
.parallax-window {
  height: 100%;
  padding: 100px 0;
  position: relative;
  /* background: url(../../assets/images/parallax/p1.jpg) center center; */
  background-size: cover;
  background-attachment: fixed;
}
.parallax-window::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}
.parallaxt-details {
  z-index: 9;
  position: relative;
}

/*-----------------------*/
/* .topten-contens { position: relative; overflow: hidden; }
.topten-title { position: absolute; left: 70px; top: 50px; z-index: 99; }
.topten-title-sm { display: none; }
.vertical_s { position: absolute; top: 95px; right: 0; bottom: 0; left: 70px; overflow: hidden; }
#top-ten-slider-nav { width: 300px; height: 100%; position: relative; }
#top-ten-slider-nav .slick-list.draggable { height: 100% !important }
#top-ten-slider-nav .slick-prev, #top-ten-slider-nav .slick-next { left: 0; right: 0; margin: 0 auto; }
#top-ten-slider-nav .slick-prev { top: 0; bottom: auto; }
#top-ten-slider-nav .NextArrow, #top-ten-slider-nav .PreArrow { position: absolute; left: 50%; transform: translateX(-50%); z-index: 999; background: transparent; border: none; color: #fff; font-size: 60px; width: 100%; z-index: 1; }
#top-ten-slider-nav .NextArrow { bottom: -20px; }
#top-ten-slider-nav .PreArrow { top: -25px; }
#top-ten-slider-nav .NextArrow:before, #top-ten-slider-nav .PreArrow:before { content: ''; position: absolute; left: 0; right: 0; width: 100%; height: 50%; z-index: -1; }
#top-ten-slider-nav .NextArrow:before { background: linear-gradient(360deg, rgba(20, 20, 20, 0.9) 0%, rgba(36, 36, 36, 0.7) 35%, rgba(83, 100, 141, 0) 100%); bottom: 17px; }
#top-ten-slider-nav .slick-next:before, #top-ten-slider-nav .slick-prev:before { display: none; }
.slick-arrow{cursor:pointer;} */

/*---------------------------------------------------------------------
                     topten-contens  
-----------------------------------------------------------------------*/
.topten-contens {
  position: relative;
  overflow: hidden;
}
#iq-topten .iq-title {
  position: absolute;
  left: 35px;
  top: 35px;
  z-index: 99;
}
.topten-title-sm {
  display: none;
}
.vertical_s {
  position: absolute;
  top: 95px;
  right: 0;
  bottom: 0;
  left: 35px;
  overflow: hidden;
}
#top-ten-slider-nav {
  width: 300px;
  height: 100%;
  position: relative;
}
#top-ten-slider-nav .slick-list.draggable {
  height: 100% !important;
}
#top-ten-slider-nav .slick-prev,
#top-ten-slider-nav .slick-next {
  left: 0;
  right: 0;
  margin: 0 auto;
}
#top-ten-slider-nav .slick-prev {
  top: 0;
  bottom: auto;
}
#top-ten-slider-nav .NextArrow,
#top-ten-slider-nav .PreArrow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: var(--iq-white-color);
  font-size: 30px;
  width: 60px;
  height: 35px;
}
#top-ten-slider .NextArrow,
#top-ten-slider .PreArrow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: var(--iq-white-color);
  font-size: 30px;
  width: 35px;
  height: 60px;
}
#top-ten-slider-nav .NextArrow {
  bottom: 0;
}
#top-ten-slider-nav .PreArrow {
  top: 0;
}
#top-ten-slider .NextArrow {
  right: 0;
  left: auto;
}
#top-ten-slider .PreArrow {
  left: 0;
  right: auto;
}
#top-ten-slider-nav .slick-next:before,
#top-ten-slider-nav .slick-prev:before,
#top-ten-slider .slick-next:before,
#top-ten-slider .slick-prev:before {
  display: none;
}
.slick-arrow {
  cursor: pointer;
}
ul#top-ten-slider .slick-bg:before {
  z-index: 0;
}

#top-ten-slider-nav .NextArrow:hover,
#top-ten-slider-nav .NextArrow:focus {
  outline: none;
  border: none;
}
#top-ten-slider-nav .PreArrow:hover,
#top-ten-slider-nav .PreArrow:focus {
  outline: none;
  border: none;
}

/*-----------------------*/
footer {
  background: var(--iq-bg1);
}
ul.f-link li {
  margin-bottom: 4px;
}
ul.f-link li a {
  margin-bottom: 4px;
  color: var(--iq-body-text);
}
ul.f-link li a:hover {
  color: var(--iq-primary);
}
.s-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 12px;
  color: var(--iq-white);
  font-size: 17px;
  background: rgb(41, 41, 41, 0.76);
  transition: all 0.4s ease;
}
.s-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--iq-primary);
  transition: all 0.4s ease;
  transform: scale(0.9);
  z-index: -1;
}
.s-icon:hover:before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--iq-primary);
}
.s-icon:hover {
  box-shadow: 0 0 10px var(--iq-primary);
  text-shadow: 0 0 2px var(--iq-primary);
  color: var(--iq-primary);
  transition: all 0.4s ease;
}
.copyright {
  background: var(--iq-body-bg);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
}

/*-----------------------*/
.banner-wrapper {
  padding: 60px 0;
  z-index: 1;
  width: 100%;
  height: 550px;
  background-image: url(../../assets/images/shows-banner/single-show.jpg);
  background-position: top left;
}
.overlay-wrapper:before,
.shows-img:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.8) 70%,
    var(--iq-black) 100%
  );
}
.active-class {
  padding-top: 20px;
  background: rgba(50, 50, 50, 0.7) !important;
  transition: all 0.6s ease 0s;
  transform: scale(1);
  border: 2px solid red;
}
.banner-caption,
.overlay-s-name {
  position: absolute;
  bottom: 30px;
  left: 80px;
}
.movie-detail .trending-info {
  padding: 0 0 20px 0;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 1px 0;
}
.movie-detail .trending-info.g-border {
  border-image-source: linear-gradient(
    to left,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}
.share-icons.music-play-lists li {
  display: inline-flex;
  margin-right: 7px;
  width: 45px;
  height: 45px;
}
.share-icons.music-play-lists li span {
  width: 32px;
  height: 32px;
}
.share-icons.music-play-lists li span i {
  font-size: 15px;
}
.movie-detail .trending-info .trending-text {
  font-size: 50px;
  line-height: 1.5;
  margin: 0;
}
.main-content.movi {
  padding-top: 0px;
  position: relative;
}

/*  */

/* subscription page */
.checkout_wrapper_main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 350px;
}

.checkout_wrapper {
  width: 600px;
  height: 120%;
  display: flex;
}

.checkout_wrapper .product_info {
  width: 45%;
  background: #bddbe9;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
}

.checkout_wrapper .product_info img {
  width: 240px;
  margin-top: 30px;
  margin-left: 5px;
}

.checkout_wrapper .checkout_form {
  width: 55%;
  background: #fff;
  padding: 50px 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.checkout_wrapper .product_info .content {
  text-align: center;
  margin-top: 25px;

  text-transform: uppercase;
}

.checkout_wrapper .product_info .content h3 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 3px;
}

.checkout_wrapper .product_info .content p {
  margin-top: 10px;
  font-size: 14px;
}

.checkout_form p {
  margin-bottom: 25px;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #2e82a3 !important;
  font-weight: 800 !important;
}

.checkout_form .details .section {
  margin-bottom: 15px;
}

.checkout_form .details .section input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #dddddd !important;
  color: transparent !important;
  background: #fff !important;
}

.checkout_form .details .section input[type="text"]:focus {
  border: 2px solid #1572dd !important;
  color: transparent !important;
}

.checkout_form .details .section.last_section {
  display: flex;
  justify-content: space-between;
}

.checkout_form .details .section.last_section .item {
  width: 48%;
}

.checkout_form .details .btn {
  background: #1572dd;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
  margin-top: 25px;
}

.checkout_form .details .btn a {
  color: #fff;
  letter-spacing: 5px;
  display: block;
}

/* Modal  */

.special_modal .modal-content {
  background-color: #010203;
}
/* ======================================================== */
.season-trailer-container {
  position: relative;
  width: 300px;
  height: 180px;
  text-align: center;
  cursor: pointer;
}

.trailer-container {
  position: relative;
  width: 360px;
  height: 200px;
  text-align: center;
  cursor: pointer;
}

.trailer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px !important;
}

.trailer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px !important;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.season-trailer-container:hover .trailer-overlay {
  opacity: 1;
}
.season-trailer-container:hover .season-trailer-text {
  transform: translate(-50%, -50%) scale(1.1);
}
.trailer-container:hover .trailer-overlay {
  opacity: 1;
}

.trailer-container:hover .trailer-text {
  transform: translate(-50%, -50%) scale(1.1);
}

.trailer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  transition: transform 0.3s ease;
}

.season-trailer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  transition: transform 0.3s ease;
}
a.play-new-btn {
  width: 50px;
  height: 50px;
  background-color: #2388da !important;
  border-radius: 100%;
  border: #fff solid 2px !important;
  position: relative;
  animation: shadowPulse 1s infinite linear;
}

a.play-new-btn::before {
  position: absolute;
  content: "";
  border-top: transparent 10px solid;
  border-bottom: transparent 10px solid;
  border-left: #fdfcfc57 16px solid !important; /* Changed arrow color to black */
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}

a.play-new-btn::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #5bd8e9 solid 2px !important;
  border-radius: 100%;
  animation: ringPulse 1s infinite linear;
}

@keyframes ringPulse {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  10% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 4px 3px transparent, 0 0 0 0 transparent,
      0 0 0 0 transparent;
  }

  10% {
    box-shadow: 0 0 4px 4px #2168da7e, 0 0 6px 5px transparent,
      0 0 12px 5px #3e76c996;
  }

  80%,
  100% {
    box-shadow: 0 0 4px 4px transparent, 0 0 0 20px transparent,
      0 0 0 20px transparent; /* Decreased shadow size */
  }
}

div.play-new-btn-mbl {
  width: 40px;
  height: 40px;
  background-color: #2388da !important;
  border-radius: 100%;
  border: #fff solid 2px !important;
  position: relative;
  animation: shadowPulse 1s infinite linear;
}

div.play-new-btn-mbl::before {
  position: absolute;
  content: "";
  border-top: transparent 10px solid;
  border-bottom: transparent 10px solid;
  border-left: #fdfcfc57 16px solid !important; /* Changed arrow color to black */
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}

div.play-new-btn-mbl::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #fff solid 2px !important;
  border-radius: 100%;
  animation: ringPulse 1s infinite linear;
}

/*  */
.share {
  position: relative;
}

.share-box {
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  display: none;
  position: absolute;
  max-width: 125px;
  bottom: 10px;
  right: auto;
  left: -70px;
  background-color: var(--iq-bg1);
  padding: 0px 10px;
  border-radius: 0px;
  text-align: center;
  z-index: 2;
  animation: side-in 0.5s forwards;
  margin-bottom: 10px;
  transition: all 0.45s ease 0s;
  word-break: break-all;
}

.share:hover .share-box {
  display: inline-block;
}
.share-box a {
  background: transparent !important;
  color: var(--iq-body) !important;
  margin-right: 15px;
}
.share-box i {
  font-size: px !important;
}
.share-box a:hover {
  color: var(--iq-primary) !important;
}
.favorite-box {
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  display: none;
  position: absolute;
  width: 150px;
  bottom: 10px;
  right: auto;
  left: 40px;
  background-color: var(--iq-bg1);
  padding: 0px 10px;
  border-radius: 0px;
  text-align: center;
  z-index: 2;
  animation: side-in 0.5s forwards;
  margin-bottom: 10px;
  transition: all 0.45s ease 0s;
}

.share:hover .favorite-box {
  display: inline-block;
}
.favorite-box a {
  background: transparent !important;
  color: var(--iq-body) !important;
  margin-right: 15px;
}
.favorite-box i {
  font-size: px !important;
}
.favorite-box a:hover {
  color: var(--iq-primary) !important;
}
.season-banner-dec {
  width: 50% !important;
}
.play-ico {
  font-size: 22px;
}
.play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  display: block;
  padding-left: 5px;
  text-align: center;
}
.play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: #ba1f24;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}
.play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: var(--iq-primary);
  border-radius: 50%;
  transition: all 200ms;
}
.play-button:hover:after {
  background-color: darken(#fa183d, 10%);
}
.play-button i {
  display: block;
  position: relative;
  z-index: 3;
  font-size: 20px;
  color: var(--iq-white);
}
.w-name {
  margin-left: 70px;
}
video {
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 1170px) {
  .container-video {
    height: 82vh !important;
  }
}
@media (max-width: 1160px) {
  .container-video {
    height: 80vh !important;
  }
}
@media (max-width: 1115px) {
  .container-video {
    height: 78vh !important;
  }
}
@media (max-width: 1080px) {
  .container-video {
    height: 76vh !important;
  }
  .season-trailer-container {
    width: 240px;
    height: 130px;
  }
  .season-trailer-text {
    font-size: 1rem;
  }
}
@media (max-width: 1050px) {
  .container-video {
    height: 74vh !important;
  }
}
@media (max-width: 1020px) {
  .container-video {
    height: 72vh !important;
  }
}
@media (max-width: 990px) {
  .container-video {
    height: 66vh !important;
  }
}
@media (max-width: 882px) {
  .container-video {
    height: 64vh !important;
  }
}

@media (max-width: 884px) {
  .container-video {
    width: 82% !important;
    margin-left: 12vh !important;
  }
}
@media (max-width: 880px) {
  .container-video {
    width: 100% !important;
    margin-left: 0vh !important;
  }
}
@media (max-width: 735px) {
  .season-banner-dec {
    width: 90% !important;
  }
}

.banner-img-container-mobile {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.banner-img-container-mobile img {
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}

.container-video {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 26vh; */
  height: 100vh;
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 0px;
  margin-bottom: 2rem;
}
.pagination-container {
  display: flex;
  justify-content: center;
}
.responsive-iframe {
  position: absolute;
  top: 6rem;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  border: none;
  background-color: #040107;
}
@media (max-width: 450px) {
  .container-video {
    width: 80%;
    height: 80%;
  }
  .responsive-iframe {
    height: 80%;
  }
}

@media (max-width: 1199px) {
  .container-video {
    margin-left: 26vh;
  }
}
@media (max-width: 1100px) {
  .container-video {
    margin-left: 25vh;
  }
}
@media (max-width: 1000px) {
  .container-video {
    margin-left: 24vh;
  }
}
@media (max-width: 950px) {
  .container-video {
    margin-left: 21vh;
  }
}
@media (max-width: 850px) {
  .container-video {
    margin-left: 19vh;
  }
}
@media (max-width: 770px) {
  .container-video {
    margin-left: 17vh;
  }
}
@media (max-width: 670px) {
  .container-video {
    margin-left: 15vh;
  }
}
@media (max-width: 600px) {
  .container-video {
    margin-left: 13vh;
  }
}
@media (max-width: 510px) {
  .container-video {
    margin-left: 11vh;
  }
}
@media (max-width: 430px) {
  .container-video {
    margin-left: 8vh;
  }
}
.video-container {
  width: 100%;
  height: 650px;
  overflow: hidden;
  position: relative;
}

/*  */
.movie-desc-navigator {
  background: linear-gradient(to right, #141414 30%, #0e0d0d 50%, #141414 70%);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.movie-desc-navigator-btn {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  margin: 0 10px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.5s ease, border-bottom 0.5s ease;
}

.movie-desc-navigator button:hover {
  transform: scale(1.2);
  border-bottom: 2px solid #21a6e0;
}

.sources {
  height: 100%;
}
.movie-desc-navigator__active-btn {
  border-bottom: 2px solid #21a6e0;
}

.movie-desc-navigator__table {
  padding: 2rem;
}

.movie-tags {
  gap: 0.4rem;
  color: #21a6e0;
  font-size: 1.2rem;
}

.movie-tags span {
  color: #e6e6e6;
  font-size: 1.2rem;
}

.movie-tags-tag {
  gap: 0.4rem;
}

/* table {
  border-collapse: separate;
  border-spacing: 2em;
}
table td,
table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

thead th,
tbody th {
  margin-bottom: 10rem;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
}
.card-body {
  padding: 0;
}
tbody button {
  font-size: 1.2rem;
  background-color: #55abe1;
  padding: 0.7rem 2rem 0.7rem 2rem;
  color: white;
  cursor: pointer;
}

tbody td {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.2rem;
  text-align: center;
}

.card {
  background-color: #0a0a0a;
  width: full;
} */
.movie-desc-navigator__desc-text {
  font-weight: 200;
  color: #ebe5e5;
  font-size: 1.3rem;
  /* margin-top: 10px; */
}

.movie-desc-crew-title {
  display: flex;
  justify-content: space-between;
}

.movie-desc-crew-title span:first-child {
}

.movie-desc-crew-title span {
  font-size: 1rem;
}

.movie-desc-crew-title :nth-child(1) {
  color: white;
  font-weight: 600;
}
.movie-desc-crew-title :nth-child(2) {
  color: white;
  font-weight: 600;
  text-align: right;
}

.comments-section-row {
}

.comments-section {
  /* padding: 1rem; */
}
.comments-section-main {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.comments-image {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-section-title {
  display: flex;
  gap: 5vh;
  align-items: center;
}
.comments-section-comment {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-right: 1.2rem;
}
.comment-section-actions i {
  font-size: 1.2rem;
  color: #21a6e0;
  cursor: pointer;
}
.comment-section-actions i:hover {
  color: #abdaf0;
}
.comment-section-actions {
  display: flex;
  gap: 0.6rem;
}
.comment-section-title p {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
}

.comment-section-title span {
  font-size: 1rem;
}

.add-comment-container {
  width: 100%;
}

.add-comment-container h4 {
  color: #21a6e0;
}

.comments-heading {
  color: #21a6e0;
}
.video-container.overlay-wrapper:before,
.overlay-s-name {
  z-index: 1;
}

.loader {
  z-index: 999;
}

/* Dropdown container */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

/* custom-Dropdown select element */
.custom-dropdown-select {
  font-size: 16px;
  font-weight: bold;
  color: #ebe6e6;
  padding: 8px 20px 8px 20px;
  border: 2px solid #aaa;
  border-radius: 5px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

/* custom-Dropdown select arrow */
.custom-dropdown-select:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* custom-Dropdown options */
.custom-dropdown-select option {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  background-color: #fff;
}

/* custom-Dropdown options hover state */
.custom-dropdown-select option:hover {
  background-color: #f2f2f2;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/*-----------------------*/
.seasons .iq-dropdown .form-control {
  background: var(--iq-secondary);
  color: var(--iq-white);
}
.seasons .trending-pills {
  background: var(--iq-bg1);
}
.seasons .trending-pills.nav-pills .nav-item a {
  margin-left: 0;
}
.epi-box .episode-number,
.epi-box .episode-play-info {
  opacity: 1;
}
.epi-box .episode-number {
  background: rgb(0, 0, 0, 0.7);
}
.epi-desc {
  background: var(--iq-bg1);
}
.epi-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 1rem;
}
.epi-box {
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
}
.epi-img {
  overflow: hidden;
}

.epi-img img {
  width: 100%;
  /* height: 27vh; */
}
.img-zoom {
  -webkit-transition: transform 2s ease-in-out;
  -o-transition: transform 2s ease-in-out;
  transition: transform 2s ease-in-out;
  transform-origin: center center;
}
.episode-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0vh 1vh;
  background-color: #1075bd;
  font-size: 1.3rem;
  color: #fff;
}

.episode-release-date {
  background: transparent
    /* linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 55, 65, 0.3) 100%); */
    linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(39, 170, 225, 0.3) 100%); /* Replace these colors with the gradient you want */
  border-left: 5px solid var(--iq-primary);
  padding: 5px 40px 5px 5px; /* Add some padding to make the text more readable */
  color: white;
  font-style: italic;
  font-size: 1rem;
}

.genre-title {
  background: transparent
    /* linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 55, 65, 0.3) 100%); */
    linear-gradient(
      270deg,
      rgba(11, 1, 2, 0.3) 0%,
      rgba(39, 170, 225, 0.6) 100%
    ); /* Replace these colors with the gradient you want */
  border-left: 5px solid var(--iq-primary);
  padding: 5px 40px 5px 5px; /* Add some padding to make the text more readable */
  color: white;
  font-style: italic;
  font-size: 1rem;
  width: 100%;
}

.episode-tag-duration {
  font-size: 1rem;
}

.epi-box:hover .img-zoom {
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.episode-name span {
  font-size: 28px;
}
.episode-name .trending-year:before {
  top: 22px !important;
  left: 12px !important;
}
.season-info .big-title {
  font-size: 22px !important;
}
.live-title .big-title {
  font-size: 20px !important;
}
@media (min-width: 375px) and (max-width: 768px) {
  .live-title .big-title {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .live-title .big-title {
    font-size: 18px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1600px) {
  .live-title .big-title {
    font-size: 25px !important;
  }
}
@media (min-width: 1601px) and (max-width: 2200px) {
  .live-title .big-title {
    font-size: 30px !important;
  }
}
/*-----------------------*/
#tvshows-slider li {
  float: left;
}
.shows-img {
  position: relative;
}
.image-size {
  max-width: 100%;
  max-height: 100%;
}
.image-size:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.123),
    rgba(0, 0, 0, 0.123),
    hsl(0deg 0% 0% / 5%)
  );
}
.image-size:before {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.089),
    rgba(0, 0, 0, 0.192),
    hsl(0deg 0% 0% / 5%)
  );
  z-index: 1;
}
.image-size:hover {
  transform: scale(1.04);
  transition: all 3s;
}
.cross-icon {
  color: #27aae1 !important;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}
.shows-content {
  position: absolute;
  bottom: 10px;
  left: 25px;
  z-index: 99;
}
@media (min-width: 600px) and (max-width: 699px) {
  .shows-content .btnMain {
    padding: 0.3rem 0.3rem;
    font-size: 0.7rem;
  }
  .shows-content h1 {
    -webkit-line-clamp: 1 !important;
  }
}
@media (max-width: 599px) {
  .shows-content .btnMain {
    padding: 0.3rem 0.3rem;
    font-size: 0.7rem;
  }
  .shows-content h1 {
    -webkit-line-clamp: 1 !important;
  }
}
@media (min-width: 550px) {
  .shows-content {
    top: 30px !important;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .shows-content .btnMain {
    position: absolute;
    bottom: 20px !important;
    z-index: 99;

    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.4rem 0.7rem;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .shows-content h1 {
    position: absolute;
    top: 0px !important;
    z-index: 99;
    font-size: 25px;
    width: 60% !important;
  }
  .shows-content .para-play {
    position: absolute;
    bottom: 40px !important;
    z-index: 99;
    width: 100% !important;
  }
  .shows-content .para-play .w-trailor-slider {
    position: absolute;
    bottom: 20px !important;
    z-index: 99;
    font-size: 15px;
  }
  .shows-content .video-open {
    bottom: 70px !important;
    z-index: 99;
    font-size: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .shows-content {
    position: absolute;
    bottom: 0px;
    left: 25px;
    z-index: 99;
  }
  .shows-content h1 {
    position: absolute;
    bottom: 155px !important;
    z-index: 99;
    font-size: 35px;
    width: 60% !important;
  }
  .shows-content .para-play {
    position: absolute;
    bottom: 70px !important;
    z-index: 99;
    width: 100% !important;
  }
  .shows-content .btnMain {
    position: absolute;
    bottom: 20px !important;
    z-index: 99;
  }
}
@media (min-width: 1500px) and (max-width: 2200px) {
  .shows-content {
    position: absolute;
    bottom: 0px;
    left: 25px;
    z-index: 99;
  }
  .shows-content h1 {
    position: absolute;
    bottom: 200px !important;
    z-index: 99;
    font-size: 50px;
    width: 62% !important;
  }
  .shows-content .para-play {
    position: absolute;
    bottom: 100px !important;
    z-index: 99;
    width: 100% !important;
  }
  .shows-content .btnMain {
    position: absolute;
    bottom: 30px !important;
    z-index: 99;
  }
}
.shows-img:before {
  background-image: linear-gradient(
    220deg,
    rgba(51, 51, 51, 0),
    rgba(0, 0, 0, 0.6) 75%,
    var(--iq-black) 90%
  );
  z-index: 1;
}
#tvshows-slider .slick-slide {
  opacity: 0.4;
  filter: blur(3px);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
#tvshows-slider .slick-slide.slick-current.slick-center {
  opacity: 1;
  filter: blur(0);
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
}
#tvshows-slider .NextArrow,
#tvshows-slider .PreArrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 999;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 65px;
  z-index: 1;
}
#top-ten-slider .NextArrow,
#top-ten-slider .PreArrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 999;
  width: 40px;
  height: 40px;
  background: rgba(255, 55, 65, 0.8);
  border-radius: 50%;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 27px;
  display: inline-block;
  z-index: 1;
  line-height: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#tvshows-slider .NextArrow,
#top-ten-slider .NextArrow {
  right: 10px;
}
#tvshows-slider .PreArrow,
#top-ten-slider .PreArrow {
  left: 10px;
}
.iq-main-slider {
  position: relative;
}
.genres-box {
  position: absolute;
  top: 100px;
  left: 15%;
}
.genres-box .btn-secondary {
  background-color: rgba(108, 117, 125, 0.8);
  border-color: transparent;
  position: relative;
}
.genres-box .btn-secondary:focus {
  box-shadow: none !important;
}
.genres-box .dropdown-toggle::after {
  content: "\ea41";
  position: absolute;
  right: 37px;
  top: 10px;
  font-family: "remixicon";
  border: none !important;
  transition: all 0.4s ease;
  vertical-align: middle !important;
}
.genres-box .btn {
  padding: 9px 42px 9px 25px;
}
.genres-box .dropdown-menu {
  background-color: rgb(36, 36, 36, 0.9);
  border: transparent;
  color: var(--iq-white) !important;
  border-radius: 0;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.genres-box .dropdown-menu.show {
  transform: translate(0, 70px);
}
.genres-box .dropdown-item {
  color: var(--iq-white);
}
.genres-box .dropdown-item:focus,
.genres-box .dropdown-item:hover {
  background-color: transparent;
  color: var(--iq-primary);
}
.genres-box .btn-secondary:not(:disabled):not(.disabled).active,
.genres-box .btn-secondary:not(:disabled):not(.disabled):active,
.genres-box.show > .btn-secondary.dropdown-toggle {
  background-color: rgba(108, 117, 125, 0.8) !important;
  border-color: transparent !important;
}

/*---------------------------*/
.m-profile {
  padding-top: 120px;
  padding-bottom: 60px;
}
.manage-p {
  height: 100vh;
}
.m-profile .sign-user_card {
  background: var(--iq-bg1);
}
.m-profile .iq-custom-select .select2-container--bootstrap4 .select2-selection,
.m-profile .select2-dropdown {
  border: 1px solid var(--iq-body-text);
}
.m-profile .form-group {
  margin-bottom: 1.2rem;
}
.m-profile .sign-user_card {
  padding: 30px;
}
.m-profile .select2-dropdown {
  top: 0;
}
.m-profile .select2-search--dropdown .select2-search__field {
  display: none !important;
}
.lang-dropdown {
  margin-left: 30px;
}
.m-profile
  .select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-search__field {
  background: transparent;
}
.m-profile
  .select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__choice {
  border: none;
  background: var(--iq-bg1);
  border-radius: 0;
  color: #f2f2f2;
}
.manage-gen
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: calc(1.5em + 1.3rem);
  padding-left: 10px;
  font-size: 14px;
}
.manage-dd .select2-container {
  width: 100% !important;
}
.manage-dd .select2-search--dropdown .select2-search__field {
  width: 98%;
}
.lang-dropdown
  .select2-container
  .select2-search--inline
  .select2-search__field {
  margin-top: 0px;
  font-size: 14px;
}
.a-border {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 1px 0;
  border-image-source: linear-gradient(
    to right,
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0.3),
    rgba(209, 208, 207, 0)
  );
}

/*-----------------------*/
.setting a:hover {
  color: var(--iq-primary) !important;
}
.edit-icon {
  position: absolute;
  right: 25px;
  top: 25px;
}
.setting-wrapper .btn-hover {
  white-space: nowrap;
}
.pricing .table th {
  border-top: none;
}
.pricing .table th {
  border-bottom: 1px solid var(--iq-body-bg);
}
.prc-box {
  background: var(--iq-body-bg);
  padding: 15px;
  position: relative;
  transition: all 0.4s ease;
}
.type {
  position: absolute;
  background: var(--iq-primary);
  padding: 9px 25px;
  color: var(--iq-white);
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  font-weight: 500;
}
.type:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  right: -15px;
  top: -1px;
  border-left: 16px solid var(--iq-primary-hover);
  border-top: 16px solid transparent;
}
.type:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: -15px;
  top: -1px;
  border-right: 16px solid var(--iq-primary-hover);
  border-top: 16px solid transparent;
}
.prc-box.active {
  background: var(--iq-primary);
}
.prc-box.active .type {
  background: #fff;
  color: var(--iq-primary);
}
.prc-box.active .type:before {
  border-left: 16px solid var(--iq-primary-hover);
  right: -16px;
}
.prc-box.active .type:after {
  border-right: 16px solid var(--iq-primary-hover);
  left: -16px;
}
.prc-wrap {
  border-bottom: none !important;
}
.pricing .table td {
  border-top: none !important;
}
.pricing .table tr {
  border-bottom: 1px solid var(--iq-body-bg);
}
.pricing .table tr:last-child {
  border-bottom: 0;
}
.i_close {
  font-size: 22px;
}
.p-image {
  position: absolute;
  top: auto;
  right: 6px;
  bottom: 10px;
  transition: all 0.3s ease;
  background: var(--iq-primary);
  color: var(--iq-white);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.upload-button {
  font-size: 1.5em;
}
.file-upload {
  display: none;
}
.upload_profile {
  position: relative;
}
.child-cell {
  transition: all 0.4s ease;
}
.child-cell.active {
  color: var(--iq-primary);
}

/*-----------------------*/
.flatpickr-input[readonly] {
  background-color: transparent !important;
}
.flatpickr-calendar,
.flatpickr-months .flatpickr-month,
span.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: var(--iq-body-bg);
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--iq-primary);
  border-color: transparent;
}
.flatpickr-day.selected,
.flatpickr-day.selected:hover {
  background: var(--iq-primary);
  border-color: transparent;
}
.flatpickr-day.today {
  border-color: var(--iq-primary);
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--iq-primary);
  background: var(--iq-primary);
  color: var(--iq-white);
}

/*-----------------------*/

.favorites-slider .slick-arrow,
.inner-slider .slick-arrow,
#trending-slider-nav .slick-arrow,
.trending-contens .slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35px;
  height: 60px;
}

.favorites-slider .slick-arrow i,
.inner-slider .slick-arrow i,
#trending-slider-nav .slick-arrow i,
.trending-contens .slick-arrow i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 60px;
  text-align: center;
  opacity: 1;
  z-index: 9;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  line-height: 5px;
  box-shadow: 0px 9px 19px #01041b0d;
  font-size: 0;
  transform: none;
  color: var(--iq-white);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.favorites-slider .slick-arrow.slick-disabled,
.inner-slider .slick-arrow.slick-disabled,
.trending-contens .slick-arrow.slick-disabled {
  opacity: 0;
}
.favorites-slider .slick-prev,
.inner-slider .slick-prev {
  color: var(--iq-white);
  right: auto;
  left: 9px;
  z-index: 9;
  top: 90px;
}

#trending-slider-nav .slick-prev,
.trending-contens .slick-prev {
  color: var(--iq-white);
  right: auto;
  left: 11px;
  z-index: 9;
  top: 90px;
}

.favorites-slider .slick-next,
.inner-slider .slick-next {
  left: auto;
  color: var(--iq-white);
  right: 6px;
  z-index: 9;
  top: 90px;
}

#trending-slider-nav .slick-next,
.trending-contens .slick-next {
  left: auto;
  color: var(--iq-white);
  right: 11px;
  z-index: 9;
  top: 90px;
}

.favorites-slider .slick-prev:before,
.favorites-slider .slick-next:before,
.inner-slider .slick-prev:before,
.inner-slider .slick-next:before,
#trending-slider-nav .slick-prev:before,
#trending-slider-nav .slick-next:before,
.trending-contens .slick-prev:before,
.trending-contens .slick-next:before {
  font-size: 0;
}
.favorites-slider .slick-arrow i,
.inner-slider .slick-arrow i,
#trending-slider-nav .slick-arrow i,
.trending-contens .slick-arrow i {
  font-size: 17px;
}
.search-page .upcoming-contens .inner-slider .slick-arrow {
  top: 50%;
}
.search-page .upcoming-contens .inner-slider .slick-arrow.slick-next {
  right: 7px;
}
.upcoming-contens .inner-slider .slick-arrow,
#iq-favorites .inner-slider .slick-arrow {
  top: 40%;
}
.trending-contens .slick-arrow {
  top: 50%;
}
.trending-contens .slick-arrow.slick-next {
  right: 0;
}
.trending-contens .slick-arrow.slick-prev {
  left: 0;
}
/*---------------------------------------------------------------------
                      Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top {
  z-index: 999;
  position: fixed;
  margin: 0px;
  color: var(--iq-white);
  background: var(--iq-primary);
  bottom: 30px;
  right: 25px;
  font-size: 26px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 90px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#back-to-top .top:hover {
  color: var(--iq-white) !important;
  background: var(--iq-primary);
}
#back-to-top {
  opacity: 1;
  transition: opacity 0.6s ease;
}
#back-to-top.show {
  opacity: 1;
  transition: opacity 0.6s ease;
}
/*---------------------------------------------------------------------
                      Sign In
-----------------------------------------------------------------------*/
#sign-in-page-box {
  background: #fff;
  border-radius: 10px;
  position: relative;
  width: 100%;
  min-height: 480px;
  height: 93vh;
  margin: 15px auto;
  box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(44, 101, 144, 0.1);
}
.sign-in-detail {
  color: var(--iq-white);
}
.sign-in-page {
  height: 100vh;
  position: relative;
  background: url(../images/login/bg-image-2.jpg) no-repeat scroll 0 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign-in-page-modal-free {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign-in-page .sign-in-page-modal-free .height-self-center {
  height: 100vh;
  border-radius: 15px;
}
.sign-in-page .sign-in-page-modal-free .btn {
  padding: 10px 35px;
  border-radius: 5px;
}

.sign-in-page .sign-in-page-modal-free .form-control,
.m-profile .form-control {
  color: var(--iq-white);
}
.sign-in-page .sign-in-page-modal-free .form-control:focus,
.m-profile .form-control:focus {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--iq-primary);
}
.sign-in-page-modal {
  height: 100%;
  position: relative;
  /* background: url(../images/login/bg-image-2.jpg) no-repeat scroll 0 0; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign-in-page .sign-in-page-modal .height-self-center {
  height: 100vh;
  border-radius: 15px;
}
.sign-in-page-data {
  border-radius: 15px;
}
.sign-in-detail {
  padding: 50px 80px;
}
.sign-in-logo {
  display: inline-block;
  width: 100%;
}
.sign-in-logo img {
  height: 50px;
}
.sign-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iq-social-media {
  margin: 0;
  padding: 0;
  float: right;
}
.iq-social-media li {
  list-style: none;
  float: left;
  margin-right: 10px;
}
.iq-social-media li:last-child {
  margin-right: 0;
}
.iq-social-media li a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: var(--iq-light-primary);
  color: var(--iq-primary) !important;
}
.iq-social-media li a:hover {
  text-decoration: none;
}
.sign-in-page .sign-in-page-modal .btn {
  padding: 10px 35px;
  border-radius: 5px;
}
.sign-user_card {
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 25px;
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto;
  height: 920px !important;
  z-index: 98;
}
.sign-user_logo {
  position: absolute;
  top: -56px;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
}
.sign-user_logo img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid white;
}
.form-control {
  border: 1px solid #454546;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sign-in-page .sign-in-page-modal .form-control,
.m-profile .form-control {
  color: var(--iq-white);
}
.sign-in-page .sign-in-page-modal .form-control:focus,
.m-profile .form-control:focus {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--iq-primary);
}
::placeholder {
  color: #d9d5d5 !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #d9d5d5 !important;
}
::-ms-input-placeholder {
  color: #d9d5d5 !important;
}
.f-link:hover {
  color: var(--iq-primary);
}

/*---------------------------------------------------------------------
                      Privacy and terms
    -----------------------------------------------------------------------*/
.iq-terms-of-use p {
  color: var(--iq-body-text);
}
.iq-terms-of-use ul li {
  color: var(--iq-body-text);
}
.iq-privacy-policy p {
  color: var(--iq-body-text);
}

/*---------------------------------------------------------------------
     Footer
    -----------------------------------------------------------------------*/
footer .footer-standard .widget ul li {
  display: inline-block;
  padding-right: 30px;
}
footer .footer-standard .widget ul > li > a {
  padding: 0;
  font-weight: 400;
}
footer .footer-standard .widget ul > li > a::before {
  display: none;
}
footer .footer-link-title {
  margin-bottom: 20px;
  margin-top: 0;
}
.recentcomments a {
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}
footer .widget {
  background: transparent;
  border: none;
  padding: 0;
}
footer .widget .iq-contact li a:before {
  display: none;
}
footer .widget #recentcomments li a {
  color: var(--iq-primary);
}
footer .widget #recentcomments li .comment-author-link a:hover {
  text-decoration: underline;
  color: var(--iq-primary);
}
footer .widget.widget_nav_menu ul li a:before,
footer .widget ul.menu li a:before {
  top: 14px;
}
footer .widget.widget_nav_menu ul li a {
  padding: 7px 0 7px 15px;
}
footer .widget ul.menu li .sub-menu {
  padding-left: 10px;
}
ul.iq-contact li {
  display: flex;
}
ul.iq-contact li a span {
  padding-left: 15px;
  display: table-cell;
  word-wrap: anywhere;
  word-break: break-word;
}
ul.iq-contact li i {
  position: absolute;
  left: 0;
  line-height: 34px;
  font-size: 22px;
  color: var(--iq-secondary);
}
ul.iq-contact li a:before {
  display: none;
}
.widget.widget_nav_menu ul li .sub-menu li a,
.widget ul.menu li .sub-menu li a {
  font-size: 14px;
}
footer .widget .rss-date {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  margin: 5px 0;
}
footer.footer-one .widget .textwidget p {
  margin-bottom: 0;
}
footer .widget.footer-logo.text-left {
  margin-bottom: 15px;
}
footer {
  display: inline-block;
  width: 100%;
  float: left;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: var(--iq-bg1) !important;
  overflow: hidden;
}
footer.footer-one .footer-top {
  padding-top: 60px;
  padding-bottom: 60px;
}
footer.footer-one .footer-standard .widget {
  margin: 0;
}
footer.footer-one .footer-top .footer-standard .info-share {
  margin-bottom: 0;
}
footer.footer-one .widget {
  background: transparent;
}
footer.footer-one .widget h4.footer-title {
  margin-bottom: 15px;
}
footer .widget #recentcomments li .comment-author-link a {
  color: var(--body-text);
  font-weight: 600;
}
footer .widget #recentcomments li.recentcomments a:before,
footer .widget a.rsswidget:before,
footer ul.wp-tag-cloud li a:before {
  display: none;
}
footer.footer-one .widget ul.menu li a {
  border: none;
  margin-bottom: 4px;
  color: var(--iq-white-color);
}
footer.footer-one .widget ul.menu li a:hover {
  color: var(--iq-primary);
}
footer.footer-one .sub-btn {
  position: absolute;
  right: 0;
}
footer.footer-one .sub-btn button {
  padding: 24px 30px;
  border: none;
  cursor: pointer;
  background-color: var(--iq-primary);
}
footer.footer-one .sub-btn button:hover,
.footer-one .sub-btn button:focus {
  outline: none;
}
footer.footer-one .sub-btn button:before {
  content: "\f2c3";
  position: absolute;
  font-family: "Ionicons";
  z-index: 1;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: var(--iq-white-color);
}
footer.footer-one input[type="submit"] footer.footer-one .info-share li {
  margin-left: 15px;
  margin-right: 0px;
}
footer.footer-one .menu-footer-menu-container .menu {
  margin-bottom: 0;
  padding: 0;
}
footer.footer-one .menu-footer-menu-container .menu li {
  list-style: none;
  display: block;
  margin-right: 10px;
}
footer.footer-one .copyright a:hover,
footer.footer-one .menu-footer-menu-container .menu li a:hover,
footer.footer-one .info-share li a:hover {
  text-decoration: none;
  color: var(--iq-primary);
}
footer.footer-one .menu-footer-menu-container .menu li:last-child {
  margin-right: 0;
}
footer.footer-one .copyright-footer {
  background: var(--iq-body-bg);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px;
  padding: 0 15px;
}
footer.footer-one .copyright-footer .copyright {
  font-size: 16px;
}
footer.footer-one .info-share li a {
  color: var(--iq-secondary);
}
footer.footer-one input[type="email"]:hover,
footer.footer-one input[type="email"]:focus {
  border-color: var(--iq-primary);
  padding: 0 125px 0 15px;
}
footer.footer-one input[type="email"] {
  background: var(--iq-white-color);
  border-color: var(--iq-white-light-color);
  border-radius: 90px;
  color: var(--iq-secondary);
}
.mc4wp-form-fields input[type="submit"] {
  position: absolute;
  right: 15px;
}
footer.footer-one .footer-top .info-share li {
  margin-right: 15px;
  margin-bottom: 15px;
}
footer.footer-one .footer-top .info-share li:last-child {
  margin-right: 0px;
}
.iq-bg-dark {
  position: relative;
}
footer.iq-over-dark-90:before {
  display: none !important;
}
footer .footer_top_block {
  padding-bottom: 100px;
}
footer.footer-one .footer-top .info-share {
  padding-left: 0px;
}
footer.footer-one .footer-top .info-share li {
  margin-right: 12px;
  display: inline-block;
}
footer.footer-one .footer-top .info-share li a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 0px;
  color: var(--iq-white);
  font-size: 17px;
  border-radius: 50%;
  background: rgba(41, 41, 41, 0.76);
  transition: all 0.4s ease 0s;
}
footer.footer-one .footer-top .info-share li a:hover {
  box-shadow: 0 0 10px var(--iq-primary-hover);
  text-shadow: 0 0 2px var(--iq-primary-hover);
  color: var(--iq-primary-hover);
  transition: all 0.4s ease 0s;
}
footer.footer-one .footer-top .info-share li a::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  z-index: -1;
  border-radius: 50%;
  transition: all 0.4s ease 0s;
}

/*--------------   footer links  -----------------------------------*/
ul.f-link li {
  margin-bottom: 4px;
}
ul.f-link li a {
  margin-bottom: 4px;
  color: var(--iq-body-text);
}
ul.f-link li a:hover {
  color: var(--iq-primary);
}
.s-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 12px;
  color: var(--iq-white-color);
  font-size: 17px;
  background: rgb(41, 41, 41, 0.76);
  transition: all 0.4s ease;
}
.s-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--iq-primary);
  transition: all 0.4s ease;
  transform: scale(0.9);
  z-index: -1;
}
.s-icon:hover:before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--iq-primary);
}
.s-icon:hover {
  box-shadow: 0 0 10px var(--iq-primary);
  text-shadow: 0 0 2px var(--iq-primary);
  color: var(--iq-primary);
  transition: all 0.4s ease;
}
.page-numbers li .page-numbers {
  background-color: var(--iq-bg1);
  border: none;
}
.wp-video-shortcode {
  height: 500px !important;
}
.wp-video {
  width: 100% !important;
}
.mejs-container {
  width: 100% !important;
  min-width: 100% !important;
}
.video-container.iq-main-slider iframe {
  height: 70vh;
}

/*---------------------------------------------------------------------
                      Animation
-----------------------------------------------------------------------*/
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes notify {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  70% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  60% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@-webkit-keyframes slide-in {
  100% {
    left: 0;
  }
}
@keyframes slide-in {
  100% {
    left: 0;
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@keyframes trailorPlay {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  70% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
}

/*---------------------------------------------------------------------
                      About Us
-----------------------------------------------------------------------*/

.about-us-detail {
  padding: 10px;
  padding-bottom: 100px;
}

.about-us-row {
  padding: 10px;
}

.widget-container p {
  border-left: 4px solid var(--iq-primary);
  background: transparent
    linear-gradient(270deg, var(--iq-primary) 0%, var(--iq-primary-hover) 100%);
  padding: 5px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-style: italic;
}
.image-box {
  overflow: hidden;
}
.image-box img {
  -webkit-transition: transform 2s ease-in-out;
  -o-transition: transform 2s ease-in-out;
  transition: transform 2s ease-in-out;
  transform-origin: center center;
}
.image-box img:hover {
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.image-icon svg {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}

.image-box {
  padding: 10px;
}

.heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.widget-container h4 {
  margin: 10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-weight: 400;
}

.image-box {
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 0px 0px;
}

.map-container {
  background-color: #191919;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 100px 0px 100px 0px;
}

.iq-title-box .iq-title-desc {
  margin: 10px 0 0;
}

.iq-title-box a {
  color: var(--iq-primary);
  outline: none;
}

.counter {
  color: var(--iq-primary);
  font-family: "Roboto", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}
.counter-title {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-weight: 400;
  text-align: center;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5;
}

.counter .counter-number {
  font-size: inherit;
}

.svg-header {
  padding-top: 100px;
}
.svg-mini-header {
  padding: 20px;
}

/*---------------------------------------------------------------------
     Breadcrumb style 1 2 and 3 4 5
    -----------------------------------------------------------------------*/
.breadcrumb li {
  display: inline-block;
  word-break: break-all;
}
.iq-breadcrumb {
  padding: 150px 0 80px;
  background: var(--iq-bg1);
}
.iq-breadcrumb-two {
  padding: 45px 0;
  position: relative;
  z-index: 2;
}
.iq-genreView-heading {
  padding: 0;
  position: relative;
  z-index: 2;
}
.iq-genreView-heading .breadcrumb {
  display: inline-block;
}
.iq-breadcrumb-one {
  display: block;
  padding: 150px 0 80px;
  z-index: 9;
  position: relative;
}
.iq-breadcrumb-one ol li a {
  color: var(--iq-primary);
  text-decoration: none;
  font-weight: 700;
}
.iq-breadcrumb-one ol li {
  list-style: none;
  display: inline-block;
}
.iq-breadcrumb-one .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: var(--iq-primary);
}
.iq-breadcrumb-one .breadcrumb {
  position: relative;
  display: inline-block;
}
.iq-breadcrumb-one .breadcrumb-item.active {
  color: var(--iq-primary);
  position: relative;
  font-weight: 700;
}
.iq-breadcrumb-one ol {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
  margin-top: 15px;
}
.iq-breadcrumb-two .breadcrumb {
  display: inline-block;
}
.iq-breadcrumb-two ol li {
  display: inline-block;
}
.iq-breadcrumb-three .breadcrumb {
  margin-top: 0;
}
.iq-breadcrumb-three .iq-breadcrumb-one {
  padding: 0;
}
.iq-breadcrumb-three {
  padding: 45px 0;
}
.breadcrumb-bg,
.breadcrumb-video,
.video-iq-bg-over {
  position: relative;
  overflow: hidden;
}
.breadcrumb-bg video,
.breadcrumb-bg #video-background,
.breadcrumb-video video,
.video-iq-bg-over video,
.video-breadcrumb-bg #video-background,
.video-iq-bg-over #video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100%;
}
.breadcrumb-bg.iq-over-dark-80:before,
.breadcrumb-ui:before {
  z-index: 0;
}
.iq-breadcrumb-one {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: var(--iq-bg1) !important;
}

/*---------------------------------------------------------------------
       Background Gradient
      ---------------------------------------------------------------------*/
.iq-bg-over {
  position: relative;
}
.iq-over-dark-10:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.1);
  display: block !important;
}
.iq-over-dark-20:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.2);
  display: block !important;
}
.iq-over-dark-30:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.3);
  display: block !important;
}
.iq-over-dark-40:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.4);
  display: block !important;
}
.iq-over-dark-50:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.5);
  display: block !important;
}
.iq-over-dark-60:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.6);
  display: block !important;
}
.iq-over-dark-70:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.7);
  display: block !important;
}
.iq-over-dark-80:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.8);
  display: block !important;
}
.iq-over-dark-85:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.85);
  display: block !important;
}
.iq-over-dark-90:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.9);
  display: block;
}
.iq-over-dark-95:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  background: rgb(0, 0, 0, 0.95);
  display: block !important;
}

/*---------------------------------------------------------------------
                      Privacy and terms
    -----------------------------------------------------------------------*/
.iq-terms-of-use p {
  color: var(--iq-body-text);
}
.iq-terms-of-use ul li {
  color: var(--iq-body-text);
}
.iq-privacy-policy p {
  color: var(--iq-body-text);
}
.site-main {
  padding: 100px 0;
  display: inline-block;
  width: 100%;
  float: left;
  overflow: hidden;
}

.gallery-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
/* .gallery-item {
  flex-basis: calc(24%);
  margin-bottom: 30px;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .gallery-item {
    flex-basis: calc(42%);
    height: 100%;
  }
} */

/* Adjust width for tablet devices */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .gallery-item {
    flex-basis: calc(45%); 
    height: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .gallery-item {
    flex-basis: calc(45%);
  }
} */

/* Adjust width for desktop devices */
/* @media (min-width: 1400px) {
  .gallery-item {
    flex-basis: calc(19.5%);
  }
} */

.search-clear-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
.search-bar {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.search-bar input[type="text"] {
  padding: 10px 40px 10px 10px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  outline: none;
  width: 20%;
  box-sizing: border-box;
}

.search-bar i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
}

.search-bar input[type="text"]:focus + i {
  color: #555;
}
.gallery-item {
  margin-bottom: 15px;
}
.gallery-item:hover {
  transform: translateY(-5px);
  z-index: 1;
  transform: scale3d(1.1, 1.1, 1) translate3d(-1%, 0, 0) perspective(400px);
  transform-origin: 45% 45%;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
  border-left: 5px solid var(--iq-primary-hover);
  overflow: visible;
}

/*---------------------------------------------------------------------
                          Pricing-Plan
  -----------------------------------------------------------------------*/
.iq-pricing-card {
  padding: 30px;
  position: relative;
  background: var(--iq-bg1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto;
}
.iq-pricing-card .iq-pricing .table {
  border: none;
}
.iq-pricing-card .iq-pricing .table th {
  border: none;
  font-size: 18px;
  line-height: 28px;
  padding: 28px 10px 20px;
}
.iq-pricing-card .iq-pricing .table td {
  border: none !important;
}
.iq-pricing-card .iq-pricing .table tr {
  border-bottom: 1px solid var(--iq-body-bg);
}
.iq-pricing-card .iq-pricing .table tr:last-child {
  border-bottom: 0;
}
.iq-pricing-card .iq-pricing .iq-price-head {
  border-bottom: none !important;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box {
  background: var(--iq-body-bg);
  padding: 30px 10px;
  position: relative;
  transition: all 0.4s ease;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box .iq-price-rate {
  margin-top: 30px;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box small {
  font-size: 20px;
  text-transform: lowercase;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box .type {
  font-size: 18px;
  line-height: 28px;
  position: absolute;
  background: var(--iq-primary);
  padding: 9px 25px;
  color: var(--iq-white-color);
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  font-weight: 500;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box .type:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  right: -15px;
  top: -1px;
  border-left: 16px solid var(--iq-primary-hover);
  border-top: 16px solid transparent;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box .type:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: -15px;
  top: -1px;
  border-right: 16px solid var(--iq-primary-hover);
  border-top: 16px solid transparent;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box.active {
  background: var(--iq-primary);
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box.active .type {
  background: #fff;
  color: var(--iq-primary);
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box.active .type:before {
  border-left: 16px solid var(--iq-primary-hover);
  right: -16px;
}
.iq-pricing-card .iq-pricing .iq-price-head .iq-price-box.active .type:after {
  border-right: 16px solid var(--iq-primary-hover);
  left: -16px;
}
.iq-pricing-card .iq-pricing .i_close {
  font-size: 22px;
}
.iq-pricing-card .iq-pricing .iq-child-cell {
  transition: all 0.4s ease;
  font-size: 18px;
  line-height: 28px;
  padding: 25px 10px 20px;
}
.iq-pricing-card .iq-pricing .iq-child-cell.active {
  color: var(--iq-primary);
}
.iq-pricing-card .iq-pricing .iq-child-cell i {
  font-size: 26px;
  line-height: 36px;
}
.iq-pricing-card .iq-pricing .iq-price-footer {
  padding: 25px 10px 0px;
}
.iq-price-box .text-white {
  font-size: 2.369em;
}
.iq-price-footer .align-items-center .btn-hover {
  padding: 12px 24px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
/*---------------------------------------------------------------------
                            Pricing-Plan-Style-2
    -----------------------------------------------------------------------*/
.iq-pricing-card-two {
  padding: 22px 60px 60px;
  position: relative;
  background: var(--iq-bg1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto;
}
.iq-pricing-card-two .iq-pricing .table {
  border: none;
}
.iq-pricing-card-two .iq-pricing .table th {
  border: none;
  font-size: 18px;
  line-height: 28px;
  padding: 28px 10px 20px;
}
.iq-pricing-card-two .iq-pricing .table td {
  border: none !important;
}
.iq-pricing-card-two .iq-pricing .table tr {
  border-bottom: 1px solid var(--iq-body-bg);
}
.iq-pricing-card-two .iq-pricing .table tr:last-child {
  border-bottom: 0;
}
.iq-pricing-card-two .iq-pricing .iq-price-head .iq-price-box {
  display: inline-block;
  padding: 13px 15px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: var(--iq-white-color);
  background-color: var(--iq-secondary);
}
.iq-pricing-card-two .iq-pricing .iq-price-head .iq-price-box .type {
  font-size: 16px;
  color: var(--iq-white-color);
}
.iq-pricing-card-two .iq-pricing .i_close {
  font-size: 22px;
}
.iq-pricing-card-two .iq-pricing .iq-child-cell {
  transition: all 0.4s ease;
  font-size: 18px;
  line-height: 28px;
  padding: 25px 10px 20px;
}
.iq-pricing-card-two .iq-pricing .iq-child-cell.active {
  color: var(--iq-primary);
}
.iq-pricing-card-two .iq-pricing .iq-child-cell i {
  font-size: 26px;
  line-height: 36px;
}
.iq-pricing-card-two .iq-pricing .iq-price-footer {
  padding: 25px 10px 0px;
}
.iq-pricing-card-two .iq-price-bottom .iq-select-price {
  justify-content: center;
}
.iq-pricing-card-two .iq-price-bottom .iq-select-price .iq-price-rate-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 15px;
  background: var(--iq-body-bg);
  margin-bottom: 30px;
}
.iq-pricing-card-two .iq-price-bottom .iq-select-price .iq-price-rate-wrap i {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  display: none;
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap
  .iq-price-label
  .type {
  background: var(--iq-primary);
  color: var(--iq-white-color);
  padding: 3px 4px 4px;
  font-size: 16px;
  font-weight: 500;
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap
  .iq-price-label
  .type1 {
  background: var(--iq-primary);
  color: var(--iq-white-color);
  padding: 3px 4px 4px;
  font-size: 16px;
  font-weight: 500;
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap
  .iq-price-rate {
  padding: 0px;
  margin-top: 20px;
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap
  .iq-price-rate
  small {
  font-size: 20px;
  text-transform: lowercase;
}
.iq-pricing-card-two .iq-button.btn {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
}
.iq-price-rate-wrap {
  cursor: pointer;
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap.active {
  background: rgba(229, 9, 20, 0.4);
  border: 1px solid rgba(209, 208, 207, 0.6);
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap.active
  .iq-price-label
  .type {
  background-color: var(--iq-white-color);
  color: var(--iq-primary);
}
.iq-pricing-card-two
  .iq-price-bottom
  .iq-select-price
  .iq-price-rate-wrap.active
  i {
  color: var(--iq-white-color);
  display: block;
}
.align-items-center .btn-hover {
  padding: 12px 24px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.iq-price-box span {
  font-size: 16px;
  color: var(--iq-white-color);
}

/*---------------------------------------------------------------------
                            Contact
    -----------------------------------------------------------------------*/

.wrapper {
  padding: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
}
.iq-title-box {
  padding: 10px;
}
.iq-box {
  padding-top: 100px;
}
.contact-detail {
  padding-bottom: 100px;
}

.contact-detail {
  padding: 10px;
  padding-bottom: 100px;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 1px 0;
  border-image-source: linear-gradient(
    to left,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}

.icon-box {
  margin-bottom: 15px;
}
.icon-box .icon-svg {
  fill: #ffffff;
  color: #ffffff;
  border-color: #ffffff;
}

.icon-svg svg {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
}

.icon-box-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.icon-box-content .icon-box-title {
  color: var(--iq-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

h3.icon-box-title > span {
  font-size: 24px;
}

.icon-box-description {
  color: #d1d0cf;
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.iq-contact-list {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 0 1px;
  border-image-source: linear-gradient(
    to top,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}

/*---------------------------------------------------------------------
     Contact form
    -----------------------------------------------------------------------*/
.wpcf7-response-output.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng,
div.wpcf7-aborted {
  position: absolute;
  bottom: -5px;
  left: 0;
  color: red;
  border: none;
  padding: 0;
  margin: 5px 0;
}
.streamit-contact {
  padding: 45px;
  background: var(--iq-white-light-color);
}
.streamit-contact h2 {
  text-align: center;
}
.streamit-contact input:hover,
.streamit-contact input[type="text"]:hover,
.streamit-contact textarea:hover {
  border: 1px solid var(--iq-primary);
}
.streamit-contact .button {
  cursor: pointer;
  margin-top: 15px;
}
.streamit-contact input,
.streamit-contact input[type="text"],
.streamit-contact textarea {
  border: 1px solid var(--iq-white-light-color);
}
.wpcf7-form p:nth-child(n + 6) {
  margin-bottom: 0;
}
div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing,
.wpcf7-not-valid-tip {
  display: none !important;
}
.contact-form .cfield {
  position: relative;
  width: 100%;
  display: inline-block;
}
.contact-form .cfield input[type="submit"] {
  margin-top: 15px;
}
.contact-form .cfield input {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}
.contact-form .cfield input:focus,
.contact-form .cfield.textarea textarea:focus {
  border: 1px solid var(--iq-primary);
  box-shadow: none;
  outline: none;
}
.contact-form .cfield.textarea textarea {
  margin-bottom: 15px;
  width: 100%;
  float: left;
  background: var(--iq-white-color);
  border: 1px solid var(--iq-white-light-color);
  border-radius: 4px;
  padding: 15px 0 0 20px;
  resize: none;
}
.contact-form .cfield .wpcf7-not-valid {
  border: 1px solid var(--iq-warning-color);
}
.contact-form .ajax-loader {
  margin: 32px 0 0 5px;
}
.predict-form {
  background: var(--iq-white-color);
  border-radius: 5px;
  padding: 30px;
}
.predict-form input,
.predict-form textarea {
  margin-bottom: 30px;
}
.contact-form .cfield textarea {
  margin-bottom: 15px;
}
.iq-contact-data {
  padding: 0 0 100px 0;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 1px 0;
  border-image-source: linear-gradient(
    to left,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}

.iq-contact-data .iq-contact-list {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 0 1px;
  border-image-source: linear-gradient(
    to top,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}
.iq-contact-data .iq-contact-list:first-child {
  border: none;
}

input,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="tel"],
input[type="date"],
textarea {
  width: 100%;
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
  background: var(--iq-body-bg);
  border: 1px solid #404043;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: var(--iq-body-text);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.cfield .your-subject input::placeholder {
  color: white !important;
}
.cfield .your-email input::placeholder {
  color: white !important;
}
.cfield .your-name input::placeholder {
  color: white !important;
}

.cfield input {
  color: white !important;
}
textarea {
  padding: 0 15px;
  height: 150px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.comments-area table td,
.comments-area table th {
  border: 1px solid var(--iq-white-light-color);
}
input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: var(--iq-primary);
  box-shadow: none;
  outline: none;
}

/*---------------------------------------------------------------------
                      FAQ
    -----------------------------------------------------------------------*/
.iq-accordion {
  z-index: 9;
  position: relative;
}
.iq-accordion .iq-accordion-title {
  border-radius: 4px;
  position: relative;
  padding: 30px 75px 30px 30px;
  line-height: normal;
  cursor: pointer;
  display: flex;
  text-align: left;
  color: var(--white-color);
  font-weight: 600;
  text-decoration: none;
}
.iq-accordion .iq-accordion-title .accordion-title {
  display: inline-block;
  vertical-align: middle;
}
.iq-accordion .iq-accordion-details {
  display: none;
  text-align: left;
  padding: 30px 0 0;
}
.iq-accordion .iq-accordion-details p {
  margin-bottom: 0;
}
.iq-accordion .iq-accordion-block {
  margin-bottom: 30px;
  padding: 0;
  overflow: hidden;
  background: var(--iq-bg1);
  border-radius: 5px;
}
.iq-accordion .iq-accordion-block:last-child {
  margin-bottom: 0;
}
.iq-accordion .iq-accordion-block .iq-accordion-title i {
  color: var(--primary-color);
  line-height: normal;
  vertical-align: middle;
}
.iq-accordion .iq-accordion-block .iq-accordion-title .iq-icon-right {
  position: absolute;
  right: 30px;
  top: 25px;
  text-align: center;
  background: var(--iq-primary);
  height: 35px;
  width: 35px;
}
.iq-accordion .iq-accordion-block .iq-accordion-title .iq-icon-right > i {
  line-height: 35px;
}
.iq-accordion .iq-accordion-block .iq-accordion-title i::before {
  vertical-align: middle;
}
.iq-accordion .iq-accordion-block .iq-accordion-title i.active {
  display: none;
}
.iq-accordion .iq-accordion-block.iq-active .iq-accordion-title i.active {
  display: inline-block;
  vertical-align: middle;
}
.iq-accordion .iq-accordion-block.iq-active .iq-accordion-title i.inactive {
  display: none;
}
.iq-accordion .iq-accordion-block .iq-accordion-title .iq-icon-left {
  margin-right: 45px;
  line-height: 33px;
  display: inline-block;
  vertical-align: middle;
}
.iq-accordion-shadow .iq-accordion-block.iq-active {
  -webkit-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 4.871px 34.659px 30px 0px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.iq-accordion .iq-accordion-details {
  padding: 30px 30px;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px 0px 0px 0;
  border-image-source: linear-gradient(
    to left,
    rgba(209, 208, 207, 0),
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0)
  );
}

/*---------------------------------------------------------------------
                                General
  -----------------------------------------------------------------------*/

h1 {
  font-size: 4.209em;
}
h2 {
  font-size: 3.157em;
}
h3 {
  font-size: 2.369em;
}
h4 {
  font-size: 1.777em;
}
h5 {
  font-size: 1.333em;
}
h6 {
  font-size: 1rem;
}

/*-------------------------------------------------------------------
                    header menu                                          
----------------------------------------------*/
.navbar ul li:hover > .sub-menu {
  display: block !important;
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.navbar ul li:hover .sub-menu {
  display: block;
  padding: 15px 0;
  background: var(--iq-body-bg);
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 0;
  display: inline-block;
  min-width: 256px;
  -webkit-box-shadow: 0px 1px 30px 0px rgb(36 38 43 / 10%);
  -moz-box-shadow: 0px 1px 30px 0px rgba(36, 38, 43, 0.1);
  box-shadow: 0px 1px 30px 0px rgb(36 38 43 / 10%);
}
.navbar ul li .sub-menu li > .sub-menu {
  display: none;
}
.navbar .navbar-nav li:last-child .sub-menu li:hover > .sub-menu,
.navbar .navbar-nav li:nth-last-child(2) .sub-menu li:hover > .sub-menu {
  left: auto;
  right: 100%;
  top: -15px;
}
.navbar ul li:last-child {
  margin-right: 0;
}

.navbar ul.navbar-nav > li.menu-item:hover > a,
.navbar ul.navbar-nav > li.menu-item:hover > i {
  color: var(--iq-primary);
}
.navbar ul.navbar-nav > li.menu-item > a {
  text-transform: uppercase;
}
.navbar ul.navbar-nav > li:hover > a {
  font-weight: 700;
}
.navbar ul li.menu-item a {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding: 0px 0px 0px 10px;
  line-height: 70px;
  position: relative;
  z-index: 9;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}
.navbar ul li .sub-menu {
  display: none;
}
/* .navbar ul {
    float: inherit;
    display: block;
    text-align: center;
} */
.navbar ul li .sub-menu li {
  margin-right: 0;
  padding: 0 15px;
}
.navbar ul.sub-menu li {
  display: block;
  text-align: left;
}
.navbar ul li {
  list-style: none;
  margin-right: 18px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
}
.menu-sticky .navbar ul li .sub-menu > li a {
  line-height: 1em;
}
.navbar ul li .sub-menu li > a {
  font-size: 14px;
  border-radius: 0;
  display: inline-block;
  width: 100%;
  line-height: 1em;
  padding: 15px;
  color: var(--iq-body-text);
  position: relative;
  overflow: hidden;
}
.navbar ul li.menu-item a {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding: 0px 0px 0px 10px;
  line-height: 70px;
  position: relative;
  z-index: 9;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}
.navbar ul li .sub-menu li > a::before {
  position: absolute;
  content: "";
  background: var(--iq-primary);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 4px;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar ul li .sub-menu li.current-menu-parent > a::after,
header
  .navbar
  ul
  li
  .sub-menu
  li
  .sub-menu
  li.menu-item.current-menu-item
  > a::after,
header .navbar ul li .sub-menu li .sub-menu li.menu-item:hover > a::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar ul li .sub-menu li:hover > a::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
.navbar ul li .sub-menu li > a::after {
  position: absolute;
  content: "";
  background: transparent
    linear-gradient(
      270deg,
      var(--iq-form-gradient-color) 0%,
      var(--iq-to-gradient-color) 100%
    );
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
header .navbar ul li .sub-menu li.current-menu-parent > a::after,
header
  .navbar
  ul
  li
  .sub-menu
  li
  .sub-menu
  li.menu-item.current-menu-item
  > a::after,
header .navbar ul li .sub-menu li .sub-menu li.menu-item:hover > a::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
header .navbar ul li .sub-menu li:hover > a::before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
}
/* RTL BOX */
.rtl-box {
  position: fixed;
  top: 115px;
  right: 0px;
  background-color: var(--white);
  z-index: 1000;
  transition: all 500ms ease-in-out;
  transform: translateX(100%);
}
.rtl-box.show {
  transition: all 500ms ease-in-out;
  transform: translateX(0);
}
.rtl-btn {
  position: absolute;
  left: -35px;
  padding: 0em;
  background-color: var(--dark);
  border: 2px solid var(--dark);
}
.rtl-btn:focus {
  outline: none;
  box-shadow: none;
}
.modes {
  margin: 0;
  padding: 0.938em;
}
.modes li {
  cursor: pointer;
  color: var(--iq-secondary);
  display: block;
  border: 1px solid rgb(82, 79, 79);
  text-align: center;
  padding: 0.313em 1.5em;
}
.modes li {
  list-style: none;
}
.dir-btn.active {
  background: var(--dark);
  color: var(--white);
}

/* blog */
.widget {
  margin-bottom: 1.875em;
  padding: 1.875em;
  display: inline-block;
  width: 100%;
  position: relative;
  background: linear-gradient(
    180deg,
    rgb(25 25 25) 0%,
    rgb(25 25 25) 10%,
    rgba(83, 100, 141, 0) 100%
  );
  border-top: 0.25em solid rgba(209, 208, 207, 0.6);
}
label {
  color: gray;
}
.widget .search-form input {
  background: rgba(209, 208, 207, 0.6);
}
.screen-reader-text {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  display: none;
}
.search-form {
  position: relative;
}
.search-form label {
  display: none;
}
.search-form input {
  padding-right: 2.5em;
  box-shadow: none;
  border-radius: 0;
  color: var(--iq-body-text);
  border: 1px solid rgba(36, 38, 43, 0.14);
  background: var(--iq-bg1);
  transition: all 0.3s ease-in-out;
  border-radius: 0px;
}
.search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--iq-white-color);
  cursor: pointer;
  font-size: 18px;
  background: var(--iq-primary);
  border: none;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  height: 48px;
  width: 48px;
}
.widget .widget-title {
  font-size: 1.375em;
  position: relative;
  margin-bottom: 1.364em;
  padding-bottom: 0.682em;
  border: 0.045em solid;
  border-image-slice: 1;
  border-width: 0 0 0.045em 0;
  border-image-source: linear-gradient(
    to right,
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0.3),
    rgba(209, 208, 207, 0)
  );
  margin-top: 0;
}
.post-img-holder {
  width: 80px;
  margin-right: 20px;
  flex: none;
  line-height: 0;
}
.post-img-holder a {
  display: block;
  width: 100%;
  height: 70px;
  overflow: hidden;
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.iq-widget-menu .post-blog {
  line-height: 0px;
}
.iq-widget-menu .post-img .post-blog .blog-box ul li {
  margin-bottom: 0px;
}
.widget ul li:last-child {
  padding-bottom: 0;
}
.widget_categories ul li,
.widget_archive ul li,
.widget_pages ul li {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(82, 95, 129, 0.1);
}
.widget ul {
  padding: 0;
  margin: 0;
}
.iq-widget-menu .iq-post li a.date-widget {
  color: var(--iq-primary);
  margin-bottom: 10px;
  display: block;
  font-size: 12px;
  font-weight: 700;
}
.iq-widget-menu ul.iq-post li {
  margin-bottom: 24px;
}
.iq-widget-menu ul.iq-post li .post-img {
  display: flex;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.widget ul li {
  padding-bottom: 15px;
  list-style: none;
  margin-bottom: 15px;
  border-bottom: 0.063em solid rgba(82, 95, 129, 0.1);
}
.widget_tag_cloud ul {
  margin: 0;
  padding: 0;
}
.widget_tag_cloud ul li {
  padding: 0;
  display: inline-block;
  margin: 3px 5px 3px 0;
}
.widget_tag_cloud ul li a {
  background: var(--iq-primary);
  display: inline-block;
  color: var(--iq-white-color);
  padding: 2px 10px;
}
.iq-blog-box {
  position: relative;
  overflow: hidden;
  margin-bottom: 3.125em;
  padding-bottom: 2.5em;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 0 0 0.063em 0;
  border-image-source: linear-gradient(
    to right,
    rgba(209, 208, 207, 0.6),
    rgba(209, 208, 207, 0.3),
    rgba(209, 208, 207, 0)
  );
}
.iq-blog-box .iq-blog-image {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
}
.iq-blog-box .iq-blog-image img {
  margin-bottom: 1.875em;
  background: var(--iq-white-light-color);
}
img {
  max-width: 100%;
  height: auto;
}
.has-post-thumbnail .iq-blog-box .iq-blog-detail {
  display: inline-block;
  float: left;
  width: 100%;
}
.iq-blog-box .iq-blog-detail .iq-blog-meta ul {
  margin: 0 0 1.25em;
  padding: 0;
  line-height: 1em;
}
.iq-blog-meta ul li {
  color: var(--iq-white-color);
  text-transform: uppercase;
  font-style: italic;
  margin: 0 0.625em 0.625em 0;
  font-size: 0.875em;
  border-left: 0.25em solid var(--iq-primary);
  background: transparent
    linear-gradient(
      270deg,
      var(--iq-form-gradient-color) 0%,
      var(--iq-to-gradient-color) 100%
    );
  padding: 0.625em 0.625em 0.625em 0.938em;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.blog-button .button-link,
.comment-respond .form-submit .submit,
.commentlist .iq-comments-media .reply a {
  background: var(--iq-primary-hover);
  color: var(--iq-white-color);
  display: inline-block;
  vertical-align: middle;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.iq-blog-box .iq-blogcat {
  padding: 0;
  display: inline-block;
  width: 100%;
  position: static;
  margin: 1.875em 0 0;
}
.iq-blog-box .iq-blogcat li {
  list-style: none;
  float: left;
  margin: 0 0.625em 0.625em 0;
}
.iq-blog-box .iq-blogcat li a {
  background: rgba(41, 41, 41, 0.76);
  color: var(--iq-body-text);
  padding: 0.313em 0.625em;
  text-transform: uppercase;
  font-size: 0.875em;
}
.iq-blog-box .iq-blogtag {
  padding: 0;
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0;
}
.iq-blog-box .iq-blogtag li {
  list-style: none;
  float: left;
  margin: 0 0.625em 0.625em 0;
}
.iq-blog-box .iq-blogtag li a {
  background: rgba(41, 41, 41, 0.76);
  color: var(--iq-body-text);
  padding: 0.313em 0.625em;
  text-transform: uppercase;
  font-size: 0.875em;
}

/* blog-details */
blockquote,
.wp-block-quote.is-style-large {
  background: rgba(83, 81, 79, 0.4);
  padding: 15px 30px;
  border-left: 4px solid var(--iq-primary);
  margin-bottom: 30px;
  border-radius: 8px;
}
blockquote cite {
  font-family: "Heebo", sans-serif;
  font-weight: bold;
}
cite a {
  color: var(--iq-primary);
}
.comments-area,
.comment-respond {
  margin-top: 45px;
  clear: both;
}
.comments-area .comments-title,
.comment-respond .comment-reply-title {
  position: relative;
  padding-bottom: 0px;
}
form {
  display: block;
  margin-top: 0em;
}
.comment-respond .comment-form-comment {
  margin: 30px 0;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}
.comment-form-author,
.comment-form-email,
.comment-form-url {
  margin-bottom: 30px;
  display: block;
  width: 100%;
}
.comment-respond textarea,
.comment-respond input {
  float: inherit;
}
/* p.comment-form-cookies-consent {display: flex;width: 100%; align-items: start; margin: 0px 0 30px 0;} */
input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
  height: 25px;
  display: inline-block;
}
label {
  color: var(--iq-secondary);
  margin-bottom: 0;
}
.comment-respond .form-submit {
  display: inline-block;
  margin-bottom: 0;
}
.blog-button .button-link,
.comment-respond .form-submit .submit,
.commentlist .iq-comments-media .reply a {
  background: var(--iq-primary-hover);
  color: var(--iq-white-color);
  border: none;
  position: relative;
  z-index: 9;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
embed,
iframe,
object {
  max-width: 100%;
  width: 100%;
}

/* multi lang select list */
.select-list {
  padding: 9px;
  border: none;
  /* border: 2px solid var(--iq-primary); */
  color: var(--iq-body-text);
  background-color: transparent;
}
.select-list:hover {
  color: var(--iq-primary);
}

.select-list > option {
  color: white;
  background: var(--iq-bg1);
}

/* sign in page logo and switch lang container */
.signinlogo {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  position: absolute;
  top: 2rem;
}

/* gender dropdown width */
.gender-dropdown .dropdown-toggle {
  width: 100%;
  background-color: var(--iq-body);
  border: 1px solid #404043;
  text-align: left;
  padding-left: 15px;
  color: #d9d5d5;
  font-size: 14px;
}
.gender-dropdown .dropdown-toggle::after {
  margin-left: 1rem;
}
.transparent-modal {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for desired transparency */
  backdrop-filter: blur(5px); /* Optional: Apply additional blur effect */
  z-index: 9999; /* Ensure the overlay is on top of the modal */
}
/* login-signup page */
.maincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.maincontainer h3 {
  margin-bottom: 1rem;
  color: var(--iq-white);
}

.maincontainer p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.maincontainer a {
  font-size: 14px;
  text-decoration: none;
  margin: 5px 0;
}

.button {
  border-radius: 20px;
  border: 1px solid #1c75bc;
  background-color: #1c75bc;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 8px;
  transition: 0.3s;
}

.button:active {
  transform: scale(0.95);
}

/* .button:focus {
  outline: none;
  background: var(--iq-primary);
} */
.button:hover {
  background: var(--iq-primary);
}

.button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.login-signup-form {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.maincontainer input {
  background: var(--iq-body-bg);
  border: 1px solid #404043;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 48px;
}

.login-signup-container {
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  height: 560px;
}

.login-signup-form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.login-signup-container.right-panel-active .sign-in-container {
  transform: translateX(100%);
  opacity: 0;
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.login-signup-container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.login-signup-container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #1c75bc;
  background: -webkit-linear-gradient(to right, #27a1e1, #1c75bc);
  background: linear-gradient(to right, #27aae1, #1c75bc);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.overlay-container h3 {
  color: white;
}
.login-signup-container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.login-signup-container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.login-signup-container.right-panel-active .overlay-right {
  transform: translateX(20%);
}
.maincontainer h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--iq-white);
}

.next-prev-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}
/* when on page > 1 hide overlay and signin form */
/* form2 for subscription and otp */
.form2 {
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: rgba(0, 0, 0, 0.384);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 768px;
}
.Subscription {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 480px;
}
.subscriptionCards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin: auto;
  margin: 30px 0;
}
.sub-btn {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
}
.subCardFree {
  background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0);
  width: 30rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; /* Align vertically */
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 1.6rem;
  transition: transform 0.2s;
  min-height: 320px;
  transition: transform 0.2s ease;
}
.subCard {
  background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0);
  width: 15rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 1.6rem;
  transition: transform 0.2s;
  min-height: 320px;
  transition: transform 0.2s ease;
}

.premium-card {
  transform: scale(1.1);
  transition: "transform 0.2s ease";
}
.free-card {
  transition: "transform 0.2s ease";
}
.free-card:hover {
  transform: scale(1.1);
}
.free-card:hover ~ .premium-card {
  transform: scale(1);
}
.subCardBody {
  flex: 1 1 auto;
  padding: 1.25rem;
  text-align: center; /* Align text within the subCardBody */
}
.btn-price {
  display: flex;
  flex-direction: column;
}
.child-btn-price {
  margin-left: 1.5rem;
  font-size: 15px !important;
}
.subBtn {
  border-radius: 18px;
  border: 1px solid #1c75bc;
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.3s;
  /* margin-top: 8px; */
  color: #1c75bc;
  opacity: 0.6;
  margin: 10px 20px;
}
.cardText {
  margin-bottom: 30px;
}
.subBtn:hover {
  border: 1px solid #27aae1;
  background-color: #27aae1;
  color: #ffffff;
  opacity: 1;
}
.subBtn:focus {
  border: 1px solid #27aae1;
  background-color: #27aae1;
  color: #ffffff;
  opacity: 1;
}
.subBtn-active {
  border: 1px solid #27aae1;
  background-color: #27aae1;
  color: #ffffff;
  opacity: 1;
}
.activeCard {
  transition: "transform 0.2s ease";
  transform: scale(1.1);
}
.inActiveCard {
  transition: "transform 0.2s ease";
  transform: scale(1);
}
.inActiveCard:hover {
  transform: scale(1.1);
}
.inActiveCard:hover ~ .activeCard {
  transform: scale(1);
}

.heading-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  width: 90%;
}
.heading-icon .heading {
  font-size: medium;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--iq-white);
  font-family: "Roboto", Sans-serif;
  text-align: center;
  width: 100%;
}
.prev-icon {
  color: var(--iq-primary);
  cursor: pointer;
  margin-right: 1rem;
}
.otpbody {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin: 30px 0;
  flex-direction: column;
}
.otp-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.select-lang .dropdown-menu {
  background: var(--iq-bg1);
  opacity: 0.8;
}
.select-lang .dropdown-item {
  color: var(--iq-white);
}
.select-lang .dropdown-item:hover {
  color: var(--iq-bg1);
}
.select-lang {
  margin-right: 10px;
}

/* mobile form===================================================================================== */
.sign-in-mob {
  height: 100vh;
  position: relative;
  background: rgb(16, 117, 189);
  /* background: linear-gradient(to bottom left, #1c75bc, black, #27aae1); */

  background: linear-gradient(
    45deg,
    #27aae1 0%,
    #040107 37%,
    #0f051e 63%,
    #1c75bc 99%
  );

  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobForm {
  /* border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: rgba(0, 0, 0, 0.6);

  backdrop-filter: blur(10px); */
  width: 400px;
}
.signIn-Form {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  gap: 1rem;
  margin: 10px;
  /* height: 570px; */
}
.logo {
  width: 60%;
  margin: 10px 0;
}
.signinForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.inputFields {
  /* margin-top: 5px; */
  padding: 0 5px;
}
.no-account {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 5.5rem;
  margin-bottom: 1rem;
  padding-left: 13px;
}

.signinForm .heading-icon {
  width: 100% !important;
}
.sub-Card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 15px;
  gap: 2rem;
}
/* cards */
.wrapper {
  width: 90%;
  margin: 20px;
  /* background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0); */
  border-radius: 26px;
  padding: 10px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wrapper header {
  height: 55px;
  display: flex;
  align-items: center;
  /* border: 1px solid #1075bd; */
  border-radius: 30px;
  position: relative;
  background-color: #27aae1;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.wrapper label {
  color: white;
}
header label {
  height: 100%;
  z-index: 2;
  width: 30%;
  display: flex;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}
#tab-1:checked ~ header .tab-1,
#tab-2:checked ~ header .tab-2,
header label:nth-child(2) {
  width: 40%;
}
header .slider {
  position: absolute;
  height: 85%;
  border-radius: inherit;
  /* background: linear-gradient(145deg, #1075bc 0%, #27aae1 100%); */
  background: #1075bd;
  transition: all 0.3s ease;
}
#tab-1:checked ~ header .slider {
  left: 0%;
  width: 95px;
  transform: translateX(5%);
}
#tab-2:checked ~ header .slider {
  left: 79%;
  width: 95px;
  transform: translateX(-50%);
}
/* #tab-3:checked ~ header .slider {
  left: 100%;
  width: 95px;
  transform: translateX(-105%);
} */
.wrapper input[type="radio"] {
  display: none;
}
.card-area {
  overflow: hidden;
  width: 100%;
}
.card-area .cards {
  display: flex;
  width: 300%;
}
.cards .myrow {
  width: 33.4%;
}
.cards .myrow-1 {
  transition: all 0.3s ease;
}
#tab-1:checked ~ .card-area .cards .myrow-1 {
  margin-left: 0%;
}
#tab-2:checked ~ .card-area .cards .myrow-1 {
  margin-left: -33.4%;
}
/* #tab-3:checked ~ .card-area .cards .row-1 {
  margin-left: -66.8%;
} */
.myrow .price-details {
  margin: 20px 0;
  text-align: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #e6e6e6;
}
.price-details .price {
  font-size: 38px;
  font-weight: 600;
  position: relative;
  font-family: "Noto Sans", sans-serif;
  padding-left: 13px;
  color: white;
}
.price-details .price::before,
.price-details .price::after {
  position: absolute;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: var(--iq-body-text);
}
.price-details .price::before {
  content: "$";
  left: 1px;
  top: 0px;
  font-size: 20px;
}
.price-details .price::after {
  content: "/mon";
  right: -33px;
  bottom: 5px;
  font-size: 13px;
}
.price-details p {
  margin: 0px;
  color: white;
  font-weight: 400;
  font-size: 12px;
}
.row .features li {
  display: flex;
  font-size: 15px;
  list-style: none;
  margin-bottom: 10px;
  align-items: center;
}
.features li i {
  /* background: linear-gradient(#d5a3ff 0%, #77a5f8 100%); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.features li span {
  margin-left: 10px;
}
.wrapper button {
  border-radius: 5px;
  border: none;
  outline: none;
  height: 60px;
  width: 150px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  /* background: linear-gradient(145deg, #d5a3ff 0%, #77a5f8 100%); */
  background-color: #27aae1;
  transition: transform 0.3s ease;
}
.wrapper button:hover {
  transform: scale(1.05);
  /* background: var(--iq-primary-hover);
  border-color: var(--iq-primaryy); */
}
.signinForm .heading-icon {
  align-items: flex-start;
}
@media screen and (max-width: 420px) {
  .mobForm {
    width: 300px;
  }
  .signIn-Form {
    margin: 10px;
  }
  .wrapper {
    width: 90%;
    margin: 0;
  }
  .no-account {
    padding-left: 15px;
  }
}

/* new input of mobile login */
.inputFields input,
input[type="email"] input[type="password"] {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid var(--iq-secondary);
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.inputFields input::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="text"]::placeholder {
  color: var(--iq-secondary) !important;
  font-size: 14px;
}
.inputFields input[type="email"] :hover {
  border-bottom-color: #27aae1;
}

.signinForm .button {
  margin-top: 0px;
}

.sign-in-mob .maincontainer {
  margin: 0 !important;
}

/* 2fa btn */
/* .fa-btn .btn {
  padding: 0.375rem 0.75rem !important;
} */
.fa-btn .switch-off .switch-on {
  position: absolute !important;
}

/* modal 2fa */
.enable-modal input {
  background: transparent !important;
  border: 1px solid var(--iq-body-text) !important;
  color: white;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-radius: 10px !important;
}
.enable-modal input:focus {
  border-color: var(--iq-primary) !important;
  box-shadow: none !important;
  outline: none !important;
  color: white !important;
}

.enable-modal .dis-email {
  background-color: transparent !important;
}
.enable-modal .modal-footer {
  border-top: 1px solid #6d6d6d6d !important;
}
.enable-modal .modal-header {
  border-bottom: 1px solid #6d6d6d !important;
}
.otp-code {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.otp-code p {
  color: var(--iq-primary);
  cursor: pointer;
}
.otp-code p:focus {
  color: var(--iq-primary-hover);
}
.otp-code p:hover {
  color: var(--iq-primary-hover);
}
.success2FA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}
.success2FA span {
  color: var(--iq-body-text);
  text-align: center;
}
@media screen and (max-width: 420px) {
  input.OtpInput__input {
    width: 2rem;
    height: 2rem;
    margin: 0 0.1rem;
  }
}

.bill-history {
  overflow-y: scroll;
  height: 280px;
}

/* width */
.bill-history::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.bill-history::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.bill-history::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 10px;
}

/* Handle on hover */
.bill-history::-webkit-scrollbar-thumb:hover {
  background: var(--iq-primary);
}

.otp-body input {
  background-color: white;
  padding: 0 9px !important;
  color: black;
}
.image-upload > input {
  display: none;
}
.user-profile-img {
  cursor: pointer;
}
.user-update-info input {
  background-color: transparent;
  border: none;
  padding: 0;
  padding-right: 20px;
  width: fit-content;
}
.user-update-info .gender-dropdown .dropdown-toggle {
  background-color: transparent !important;
  border: none;
  padding: 0;
  padding-top: 8px;
}
.user-update-info .gender-dropdown .dropdown-item:focus,
.user-update-info .gender-dropdown .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: var(--iq-primary);
}
.user-info-dropdown-menu {
  height: 110px !important;
  overflow-y: scroll;
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 31px) !important;
  z-index: 1000;
}
.user-info-dropdown-menu .dropdown-item {
  white-space: normal !important;
}
/* width */
.user-info-dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.user-info-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.user-info-dropdown-menu::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 10px;
}

/* Handle on hover */
.user-info-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: var(--iq-primary);
}
.sub-card-free {
  width: 260px;

  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-card-free .card-plan {
  display: flex;
  flex-direction: column;
}
.subCardFree h4 {
  font-weight: 500;
  font-size: 25px;
}

.subCardFree ul {
  list-style: none;
  margin: 0px;
  font-size: 20px;
  padding-left: 5px;
  color: black;
}
.subcard-ul {
  /* list-style: none; */
  margin: 0px;
  font-size: 12px;
  padding: 0;
  color: white;
}
.premium-card-mob {
  display: flex;
  background-image: linear-gradient(1deg, #1171b6, #1d94c9, #21a6e0);
  flex-direction: column;
  border-radius: 12px;
  border: none;
  transition: transform 0.2s ease;
}
.recommended {
  text-align: center;
  background-color: var(--iq-primary-hover);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 4px 0;
  font-weight: 400;
}
.premium-card-mob:hover {
  transform: scale(1.1);
}
.card-button {
  border-radius: 10px;
  border: 1px solid #1c75bc;
  background-color: var(--iq-primary);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  width: 100%;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  margin-top: 8px;
  transition: 0.3s;
}
.card-button:hover {
  background-color: var(--iq-primary-hover);
}
@media screen and (max-width: 770px) {
  .bill-history {
    height: 150px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 420px) {
  .bill-history {
    height: 130px;
    overflow-y: scroll;
  }
}
/* user forgot password */
.sign-user_card-reset-pass {
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 25px;
  box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto;
}
/* otp parent div on reset password */
.otp-parent-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .css-13cymwt-control {
  background: transparent !important;
  height: 40px;
  padding: 0;
  margin: 0;
  border: none !important;
  color: #d9d5d5 !important;
}
.css-1fdsijx-ValueContainer {
  padding: 0 !important;
  height: 40px;
  color: #d9d5d5 !important;
  font-size: 14px;
  width: 100%;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1jqq78o-placeholder {
  color: #d9d5d5 !important;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-qbdosj-Input {
  width: 100px;
  color: #d9d5d5 !important;
}
.css-1fdsijx-ValueContainer .css-166bipr-Input {
  color: #d9d5d5 !important;
}
.css-qbdosj-Input input {
  width: 100px !important;
  color: #d9d5d5 !important;
  min-width: 100px !important;
}
.css-t3ipsp-control:hover {
  border: none !important;
}
.css-t3ipsp-control {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-1fdsijx-ValueContainer ::after {
  display: inline-block;
  /* width: 0;
  height: 0; */
/* margin-left: 15px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
 */

/* .css-1dimb5e-singleValue {
  color: #d9d5d5 !important;
}  */

/* vertical slider home page */
/* .slider {
  color: white;
}
.verticle-slider .slider__flex {
  display: flex;
  align-items: flex-start;
}
.swiper-button-prev {
  left: 25px;
  right: auto;
} */
/* ----------------vericle slider---------- */
.slider {
  color: var(--white);
}
.slider .swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.verticle-slider h4.main-title {
  position: absolute;
  left: 1.875em;
  top: 1.875em;
}
.verticle-slider .slider-flex {
  display: flex;
  align-items: flex-start;
}
.verticle-slider .slider--col {
  margin: 7.625em 2em 0px;
}
.verticle-slider .slider-prev,
.verticle-slider .slider-next {
  position: absolute;
  z-index: 10;
  width: 100%;
  font-size: 1.875em;
  display: flex;
  justify-content: center;
}
.verticle-slider .slider-next {
  bottom: 0;
}
.verticle-slider i.vertical-aerrow {
  background: rgba(0, 0, 0, 0.5);
  width: 4.375em;
  text-align: center;
}
.verticle-slider .slider-prev:focus,
.verticle-slider .slider-next:focus {
  outline: none;
}
.verticle-slider .slider-thumbs {
  height: 53.125em;
  width: 21.875em;
}
.verticle-slider .slider-thumbs .slider-image {
  transition: 0.25s;
}
.verticle-slider .slider-thumbs .slider-image:hover {
  opacity: 1;
}
.verticle-slider .slider-images {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.verticle-slider .slider-images .slider-image img {
  transition: 3s;
}
.verticle-slider .slider-image {
  height: 100%;
  overflow: hidden;
}
.verticle-slider .slider-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}
.verticle-slider .hover-buttons {
  opacity: 0;
}
.slider-thumbs .swiper-slide.swiper-slide-active .hover-buttons {
  opacity: 1;
}
.slider-thumbs .swiper-slide .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 0.5);
  opacity: 0;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  border-left: 4px solid var(--iq-primary);
  z-index: 9;
} /* ----------------vericle slider---------- */
.slider {
  color: var(--white);
}
.slider .swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.verticle-slider h4.main-title {
  position: absolute;
  left: 1.875em;
  top: 1.875em;
}
.verticle-slider .slider-flex {
  display: flex;
  align-items: flex-start;
}
.verticle-slider .slider--col {
  margin: 7.625em 2em 0px;
}
.verticle-slider .slider-prev,
.verticle-slider .slider-next {
  position: absolute;
  z-index: 10;
  width: 100%;
  font-size: 1.875em;
  display: flex;
  justify-content: center;
}
.verticle-slider .slider-next {
  bottom: 0;
}
.verticle-slider i.vertical-aerrow {
  background: rgba(0, 0, 0, 0.5);
  width: 4.375em;
  text-align: center;
}
.verticle-slider .slider-prev:focus,
.verticle-slider .slider-next:focus {
  outline: none;
}
.verticle-slider .slider-thumbs {
  height: 53.125em;
  width: 21.875em;
}
.verticle-slider .slider-thumbs .slider--image {
  transition: 0.25s;
}
.verticle-slider .slider-thumbs .slider--image:hover {
  opacity: 1;
}
.verticle-slider .slider-images {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.verticle-slider .slider-images .slider--image img {
  transition: 3s;
}
.verticle-slider .slider--image {
  height: 100%;
  overflow: hidden;
}
.verticle-slider .slider--image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}
.verticle-slider .hover-buttons {
  opacity: 0;
}
.slider-thumbs .swiper-slide.swiper-slide-active .hover-buttons {
  opacity: 1;
}
.slider-thumbs .swiper-slide .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 0.5);
  opacity: 0;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  border-left: 4px solid var(--iq-primary);
  z-index: 9;
}
.slider-thumbs .swiper-slide.swiper-slide-active .block-images::before {
  opacity: 1;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}

.slider-images .top-ten-slider-nav .swiper-bg:before {
  z-index: 0;
}
.swiper-slide-thumb-active .hover-buttons {
  opacity: 1;
}
.verticle-slider .block-description .iq-title {
  font-size: 1.33em;
}
.verticle-slider .slider-images .block-images {
  height: 100%;
}
.verticle-slider .slider-images .block-description {
  left: 0;
  width: 100%;
  align-items: center;
}

.confirm {
  width: 360px;
  height: 280px;
  background: #fff;
  transition: 0.6s;
  z-index: 100;
  position: absolute;
  top: -280px;
  left: calc(50% - 180px);
}

.confirm.show {
  top: calc(50% - 140px);
}

.confirm-content {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  padding: 1.5rem;
}

.confirm-content h4 {
  font-size: 1.2rem;
  font-weight: 800;
  position: relative;
}

.confirm-content h4::after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--secondary);
  position: absolute;
  bottom: -7px;
  left: 0;
}

.confirm-content h2 {
  font-size: 2rem;
  font-weight: 300;
  margin-top: 3rem;
}

.confirm-content p {
  font-size: 1.1rem;
  color: var(--gray);
  text-align: center;
  margin-top: 0.5rem;
}

.confirm-btns {
  height: 30%;
}

.confirm-btns button {
  width: 50%;
  height: 100%;
  background: var(--secondary);
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  padding: 1rem 0;
  cursor: pointer;
}

.confirm-btns button:hover {
  background: var(--secondary-dark);
}

.overlay {
  background: #1c75bc;
  background: linear-gradient(to right, #27aae1, #1c75bc);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.confirm.show + .overlay {
  display: block;
}

.edit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 400px;
  max-width: 90%;
  transition: all 0.3s ease-in-out;
}

.gradient-line {
  margin: 4rem 0;
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    rgb(22, 22, 22) 0%,
    rgb(97, 94, 94) 25%,
    rgb(97, 96, 96) 25%,
    rgb(97, 96, 96) 75%,
    rgb(97, 94, 94) 75%,
    rgb(22, 22, 22) 100%
  );
}
.password-input-group {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Del user profil modal */
.deleteModal .modal-content {
  background-color: #141414 !important;
  box-shadow: 0 0 9px #6d6d6d;
}
.deleteModal .modal-body {
  padding: 1rem !important;
}
.deleteModal .delBtn {
  background: var(--iq-primary);
  background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0);
  border: none;
}
/* ENDS Del ---- */

.enable-modal .modal-content {
  background-color: #141414 !important;
  box-shadow: 0 0 9px #6d6d6d;
}
.enable-modal .modal-body {
  padding: 1rem !important;
}
.sign-in-page .height-self-center {
  height: 100vh;
  border-radius: 15px;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/* STRIPE
 */
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  /* background-color: white; */
  background-color: transparent;
  will-change: opacity, transform;
  /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  border: 2px solid var(--iq-body-text);
  transition: border-color 0.3s ease-in-out;
  border-color: var(--iq-body-text);
}

.FormGroup:focus-within,
.FormGroup:hover {
  border-color: var(--iq-primary);
}
.FormRow {
  position: relative;
  margin-left: 15px;
}

.FormLabel {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #2c2c2c;
  padding: 0 5px;
  color: var(--iq-primary); /* Adjust label color */
  font-size: 12px; /* Adjust label font size */
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
.cardHolder input {
  background-color: transparent;
  border: none;
  display: block;
  font-family: sans-serif;
  font-size: 1em;
  height: 1.2em;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  width: 100%;
  color: white;
  font-weight: 500 !important;
  font-family: Segoe UI;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}
.cardHolder input[type="text"]::placeholder {
  color: white !important;
  font-weight: 500;
  font-family: Segoe UI;

  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}
.stripe-Modal .modal-content {
  width: 100%;
  background-color: #141414 !important;
  box-shadow: 0 0 9px #6d6d6d;
  /* background-color: #2c2c2c !important;
  box-shadow: 0 0 9px var(--iq-primary); */
}
.stripe-Modal .modal-body {
  padding: 1rem;
}
.modal-footer {
  /* border-top: 1px solid var(--iq-body-bg) !important; */
  border-top: 1px solid #6d6d6d !important;
}
.modal-header {
  border-bottom: 1px solid #6d6d6d !important;
  /* border-bottom: 1px solid var(--iq-body-bg) !important; */
}
.password-input {
  display: flex;
  align-items: center;
  width: 100%;
}
.password-toggle {
  margin-left: -2rem;
  margin-bottom: 10px;
  z-index: 1000;
}
.Toastify__toast-icon svg {
  fill: var(--iq-primary);
}
/* live channels genre list */

.tag-item {
  background-color: #393736;
  display: inline-block;
  min-width: 90px;
  padding: 0.1em 0.5em;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.tag-item .text {
  color: white;
}

.tag-item .close-tag {
  height: 15px;
  width: 15px;
  color: var(--iq-body-text);
  background-color: var(--iq-body-bg);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 15px;
  cursor: pointer;
  text-shadow: 0 1px 0 #fff;
  line-height: 1;
  opacity: 0.5;
}

.channel-genre {
  overflow-x: auto;
}
.channel-genre::-webkit-scrollbar {
  width: 2px !important;
  height: 7px !important;
}

/* Track */
.channel-genre::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.channel-genre::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 5px;
}

/* Handle on hover */
.channel-genre::-webkit-scrollbar-thumb:hover {
  background: var(--iq-primary);
}
.tag-item.selected {
  background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0);

  /* Additional styles for selected genre */
}

.tag-item.selected .text {
  color: white;
}
.userSettings-Phone .react-tel-input .form-control {
  width: auto;
  background-color: transparent;
}
/* zainab ------------------------------------------------------------------------------------------------------------------*/
.new-video-slider .heading-length {
  white-space: normal !important;
}
@media (min-width: 570px) and (max-width: 720px) {
  .video-page-btn svg {
    width: 30px !important;
    height: 30px !important;
  }
  .new-video-slider .paraOverflow {
    -webkit-line-clamp: 3 !important;
  }
  .new-video-slider .w-trailor-slider {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 800px) {
  .video-page-btn .btn {
    padding: 0.2rem 0.7rem;
    font-size: 1rem;
  }
  .video-page-btn svg {
    width: 48px !important;
    height: 48px !important;
  }
  .new-video-slider .paraOverflow {
    -webkit-line-clamp: 2 !important;
  }
}
@media (max-width: 500px) {
  .new-video-slider .paraOverflow {
    -webkit-line-clamp: 2 !important;
  }
  .new-video-slider .paraOverflow {
    margin-bottom: 0px !important;
  }
  .video-page-btn .btn {
    padding: 0.6rem 0.7rem;
    font-size: 1rem;
  }
  .new-video-slider {
    top: unset !important;
    bottom: 0% !important;
  }
  .new-video-slider .tag {
    display: none;
  }
  .new-video-slider .heading-length {
    -webkit-line-clamp: 2;
  }
  .new-video-slider .title {
    font-size: 20px !important;
  }
}
/* @media (min-width: 800px) and (max-width: 950px) {
  .new-video-slider .paraOverflow {
    -webkit-line-clamp: 2 !important;
  }
} */

.new-video-slider .buttons-container {
  justify-content: space-between !important;
}
@media (max-width: 599px) {
  .shows-content .btnMain {
    padding: 0.3rem 0.3rem;
    font-size: 0.7rem;
  }
  .shows-content h1 {
    -webkit-line-clamp: 2 !important;
  }
}
@media (min-width: 550px) {
  .shows-content {
    top: 30px !important;
  }
}
@media (min-width: 721px) and (max-width: 750px) {
  .new-video-slider {
    top: 5% !important;
    bottom: 0% !important;
  }
}
@media (min-width: 721px) and (max-width: 1000px) {
  .new-video-slider {
    top: unset;
    bottom: 0% !important;
  }
  .new-video-slider .paraOverflow {
    -webkit-line-clamp: 1 !important;
  }
  .new-video-slider .paraOverflow {
    margin-bottom: 0px !important;
  }
}
.text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 100%;
}
.iq-icon-box {
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;
  margin-right: 10px;
  font-size: 26px;
  text-decoration: none;
}
.iq-icon-box:hover {
  text-decoration: none;
}
.iq-icon-box-top {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
}

/* live channel banner - home page */

/* .live-channel-banner-content {
  padding: 0rem !important;
} */
@media screen and (max-width: 990px) {
  .live-channel-banner-content {
    display: "none";
  }
}

.live-channel-banner-content {
  position: absolute !important;
  top: 30% !important;
  left: 30% !important;
  z-index: 9999 !important;
}

@media (max-width: 600px) {
  .movie-desc-navigator-btn {
    font-size: 0.9rem;
  }
  .movie-desc-navigator__desc-text {
    font-size: 0.9rem !important;
  }
  .comments-image {
    width: 40px !important;
    height: 40px !important;
  }
  .user-title {
    font-size: 0.8rem !important;
    margin-top: 0;
  }
  .user-comments-date {
    font-size: 0.8rem !important;
  }
  .comment-section-actions i {
    font-size: 1rem;
  }
  .comment-section-actions {
    gap: 0.3rem;
  }
}
.user-title {
  margin-bottom: 0;
}

.all-images::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(20, 20, 20, 0.4) 50%,
    rgba(83, 100, 141, 0) 100%
  );
  transition: all 0.6s ease 0s;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: relative;
  width: 400px;
  max-width: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
}

.popup-content {
  margin-bottom: 20px;
}

.complete-profile-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-dialog-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.modal-header-custom {
  position: relative;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 28px;
  height: 28px;
  margin: 10px;
}

.complete_profile .modal-dialog {
  margin-right: 0;
  margin-top: 100px;
}
.complete_profile .modal-content {
  width: 100%;
  background-color: #0e0e0e !important;
  box-shadow: 0 0 9px #ffffff;
  opacity: 0.7;
}
.complete_profile .modal-body {
  padding: 1rem;
}
.share-box > * {
  flex-basis: 100%; /* Make the content wrap to the next line */
}



/* responsive part  */

@media (min-width: 1300px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1499px) {
  .container {
    max-width: 1266px;
  }
  #home-slider h1.slider-text {
    margin: 25px 0px 0px;
  }
  #home-slider p {
    margin: 32px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .search:focus,
  .search:hover {
    width: 220px !important;
  }
  .movie-detail .trending-info .trending-text {
    font-size: 40px;
    line-height: 1.3;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .search-box {
    left: auto !important;
    right: 0 !important;
  }
  .three-column {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 5px;
    -moz-column-gap: 5px;
    column-gap: 5px;
  }
  .movie-detail .trending-info .trending-text {
    font-size: 37px;
    line-height: 1.3;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  li.slide-item.slick-active
    + li.slide-item.slick-active
    + li.slide-item.slick-active
    + li.slide-item.slick-active:hover
    .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(-6%, 0, 0) perspective(500px);
  }
  .watch-title {
    position: absolute;
    top: 50%;
    left: -20%;
    transform: translateY(-50%);
    font-size: 17px;
    letter-spacing: 3.5px;
    font-weight: 600;
    color: var(--iq-white);
  }
  .watch-title:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -36%;
    transform: translateY(-50%);
    background: var(--iq-white);
    width: 52px;
    height: 2px;
  }
  .col-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (max-width: 1199px) {
  li.slide-item.slick-active
    + li.slide-item.slick-active
    + li.slide-item.slick-active:hover
    .block-images {
    transform: scale3d(1.1, 1.1, 1) translate3d(-6%, 0, 0) perspective(500px);
  }
  .video-container {
    height: 100%;
  }

  #home-slider .slick-bg {
    height: 100%;
  }
  .trailor-video {
    margin-top: 2rem !important;
  }
}
.movie-slider-size {
  height: 30vw;
}

@media (max-width: 550px) {
  .shows-img .btn-hover {
    max-width: 100%;
    max-height: 80px;
  }
  .iq-button.btn {
    padding: 5px;
    letter-spacing: 1px;
  }
  .btn {
    font-weight: 200;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
  .donate-button-text {
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 0.75rem;
  }
  .movie-slider-size {
    height: 100% !important;
  }
  .donate-button {
    height: 2.3rem;
  }
  .donate-icon {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 766px) and (max-width: 992px) {
  .movie-time .paraOverflow {
    max-width: 175px;
  }
  .movie-time .playbtn svg {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 900px) and (max-width: 1199px) {
  .movie-time .live-desc {
    max-width: 200px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
}

@media (max-width: 1200px) and (max-width: 1499px) {
  .movie-time .live-desc {
    max-width: 200px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
}

@media (max-width: 1500px) and (max-width: 2200px) {
  .movie-time .live-desc {
    max-width: 300px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
}
@media (max-width: 991px) {
  li.slide-item .block-description .parallax-ratting span,
  li.slide-item .block-description .movie-time span,
  li.slide-item .block-description .ratting-start,
  .block-description .movie-content li {
    font-size: 10px;
  }
  .hover-buttons .btn {
    font-size: 10px;
  }
  #home-slider .slick-bg {
    padding: 50px 0;
  }
  .r-mb-23 {
    margin-bottom: 23px;
  }
  header .navbar-light .navbar-brand img.logo {
    width: 70px;
  }
  /* .navbar-collapse { position: absolute; top: 50px; left: unset; padding-bottom: 15px; width: 100%;  background: var(--iq-bg1); padding-top: 40px; -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); }  */
  .navbar-collapse {
    background: var(--iq-bg1);
  }
  .vertical_s {
    position: static;
  }
  #top-ten-slider-nav {
    width: 100%;
    height: 100%;
  }
  /* .navbar-collapse.collapsing { height: auto; -webkit-transition: left 0.3s ease; -o-transition: left 0.3s ease; -moz-transition: left 0.3s ease; transition: left 0.3s ease; left: -100%; }  */
  /* .navbar-collapse.show {  -webkit-transition: all 0.3s ease-in; -o-transition: all 0.3s ease-in; -moz-transition: all 0.3s ease-in; transition: all 0.3s ease-in; z-index: 999; }  */

  header .navbar ul li.menu-item a {
    line-height: 10px;
    float: left;
    width: 100%;
    text-align: left;
    padding: 10px 30px;
    color: var(--iq-white);
  }
  header .navbar ul .navbar-nav {
    background-color: black;
  }
  .mobile-more-menu {
    display: block;
  }
  .menu-right {
    display: none;
  }
  .navbar-brand {
    padding: 0;
    margin-right: 0;
  }
  .more-menu .iq-sub-dropdown {
    width: 90%;
    margin: 0 auto;
    left: 40px;
    right: 0;
    top: 60px;
  }
  .mobile-more-menu .iq-user-dropdown img {
    width: 40px;
  }
  .iq-search-bar .search-input {
    background: var(--iq-body-bg);
    color: var(--iq-body-text);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.75);
  }
  .navbar-right li {
    position: static !important;
  }
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .main-header {
    padding: 10px 0;
  }
  .vertical_s {
    display: none;
  }
  .r-mt-15 {
    margin-top: 15px;
  }
  .block-space {
    padding: 25px 0;
  }
  .banner-wrapper .trending-info .trending-dec {
    width: 100%;
  }
  .banner-caption,
  .overlay-s-name {
    left: 30px;
  }
  .scroll-gene {
    width: 100%;
    height: 600px;
  }
  .genres-box .dropdown-menu {
    height: 250px;
    overflow-y: auto;
  }
  .search-box {
    min-width: 16.2rem;
    top: 47px;
  }
  .topten-title-sm {
    display: block;
  }
  .topten-title {
    display: none;
  }
  .device-margin {
    margin-top: 15px;
  }
  #home-slider h1.slider-text {
    font-size: 35px;
  }
  header .navbar ul li {
    display: block;
  }

  .iq-main-slider {
    padding-top: 60px !important;
  }
  .m-profile {
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .trending-info .trending-dec {
    width: 52%;
  }
  .svg-header {
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  li .block-images::before {
    content: none;
  }
  /* li .block-description,
  li.slide-item .block-social-info {
    display: none !important;
  }
  li.slide-item.slick-current .block-description {
    display: none !important;
  } */
  li:hover .block-images,
  li.slide-item:hover .block-images,
  li.slide-item.slick-active + li.slide-item.slick-active:hover .block-images {
    transform: none;
  }
  .type {
    padding: 9px 10px;
    font-size: 13px;
  }
  .movie-info-container {
    flex-direction: column;
  }
  .trailer-container {
    width: 100%;
    height: 80%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .parallax-window {
    padding: 60px 0;
  }
  .banner-wrapper {
    height: 100%;
    padding: 100px 0;
  }
  .trending-content {
    padding: 0;
  }
  .banner-wrapper .trending-info .text-detail,
  .banner-wrapper .series {
    justify-content: flex-start;
  }
  .trending-info .trending-dec {
    width: 100%;
  }
  .banner-wrapper .trending-info .trending-dec {
    text-align: left;
  }
  .trending-pills {
    flex-direction: column;
  }
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  #home-slider h1.slider-text {
    line-height: 62px;
    margin: 0px;
  }
  .trending-info .block-social.social1 {
    margin-left: 0 !important;
  }
  .trending-info .trending-text {
    font-size: 35px !important;
    line-height: 1.3;
  }
  .trending-info {
    padding: 60px 15px;
  }
  #home-slider p {
    margin: 22px 0;
  }
  .r-mt-20 {
    margin-top: 20px;
  }
  .p-btns {
    flex-direction: column;
  }
  .main-title {
    font-size: 1em;
  }
  .sign-info {
    flex-direction: column;
  }
  .sign-info .btn {
    margin-bottom: 15px;
  }
  .banner-caption,
  .overlay-s-name {
    left: 15px;
  }
  .episode-name {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .text-detail.episode-name .trending-year {
    padding-left: 0;
    font-size: 24px;
  }
  .episodes-slider1 .owl-nav button.owl-prev,
  .episodes-slider1 .owl-nav button.owl-next {
    width: 30px;
    height: 30px;
    font-size: 27px;
  }
  #top-ten-slider .NextArrow,
  #top-ten-slider .PreArrow {
    width: 30px;
    height: 30px;
    font-size: 23px;
  }
  .episode-name .trending-year:before {
    content: none;
  }
  .r-mb-15 {
    margin-bottom: 15px;
  }
  /*  header#main-header { position: static; height: 100vh;}
*/
  .iq-main-header {
    margin-bottom: 10px;
  }

  .shows-img .movie-time {
    display: none !important;
  }
  .genres-box {
    top: 10px;
  }
  .shows-content {
    bottom: 14px;
  }
  .main-content {
    padding-top: 25px;
  }
  #home-slider p {
    width: 100%;
  }
  .slick-bg:before {
    background: linear-gradient(
      -360deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(20, 20, 20, 1) 40%,
      rgba(83, 100, 141, 0) 100%
    );
  }
  .m-profile {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .m-profile .sign-user_card {
    padding: 15px;
  }
  .lang-dropdown {
    margin-left: 0;
    margin-top: 1.2rem;
  }
  .manage-gen .select2-container {
    width: 100% !important;
  }
  .manage-gen .select2-search--dropdown .select2-search__field {
    width: 98%;
  }
  .trending-info .text-detail {
    font-size: 17px;
  }
  .play-button i {
    font-size: 16px;
  }
  .play-button:after,
  .play-button:before {
    width: 40px;
    height: 40px;
  }
  .manage-p {
    height: 100%;
  }
  .banner-caption {
    bottom: 10px;
  }
  .w-name {
    font-size: 18px;
  }
  .overlay-wrapper:before {
    background-image: linear-gradient(
      to bottom,
      rgba(51, 51, 51, 0),
      rgba(0, 0, 0, 0.8) 40%,
      var(--iq-black) 100%
    );
  }
  .favorites-slider .slick-arrow,
  .favorites-slider .slick-arrow:hover,
  #trending-slider-nav .slick-arrow,
  #trending-slider-nav .slick-arrow:hover {
    background: none;
  }
  .favorites-slider .slick-prev,
  #trending-slider-nav .slick-prev {
    right: 37px;
  }

  footer.footer-one .footer-top {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  footer.footer-one .widget .textwidget p {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .iq-search-bar a.search-toggle {
    display: none;
  }
}
@media (max-width: 576px) {
  .movie-detail .trending-info .trending-text {
    padding-top: 12px;
    font-size: 24px !important;
    line-height: 1.1;
    margin: 0;
  }
  .w-trailor {
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    font-weight: 500;
    margin-left: 10px;
  }
  .playbtn svg {
    width: 60px;
    height: 60px;
  }
  .movie-tags {
    font-size: 1.1rem;
  }
  .movie-tags span {
    font-size: 1rem;
    align-items: center;
    margin-top: 0.2rem;
  }
}
@media (max-width: 479px) {
  header .navbar ul li.list-inline-item a {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .icon-movies {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
}

@media (min-width: 1499px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 479px) {
  header .navbar ul li.list-inline-item a {
    padding-left: 20px;
    padding-right: 20px;
  }
  header .navbar .iq-usermenu-dropdown > ul > li {
    position: static;
  }
  .search-box {
    left: 0;
    right: 0;
    min-width: 100%;
    width: 100%;
  }
  header .iq-usermenu-dropdown .iq-sub-dropdown {
    width: 250px;
  }
  .banner-caption,
  .overlay-s-name {
    left: 15px;
  }
  #tvshows-slider .slick-slide img {
    height: 50vh;
  }
  .banner-wrapper .trending-info .trending-dec {
    display: none;
  }
  .banner-wrapper {
    height: 60vh;
  }
  .iq-breadcrumb-two > h2 {
    font-size: 1.9em;
  }
  .trending-pills.nav-pills .nav-item a {
    margin: 0 10px;
  }
  .page-numbers li .page-numbers {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
  .page-numbers li .page-numbers.current {
    height: 35px;
    width: 35px;
  }

  .iq-pricing-card-two {
    padding: 22px 30px 30px;
  }
}
/*---------------header menu----------------*/
@media screen and (min-width: 1200px) {
  header .navbar ul li .sub-menu li a,
  header.menu-sticky .navbar ul li .sub-menu li a {
    white-space: nowrap;
  }
}
@media screen and (max-width: 4000px) and (min-width: 1200px) {
  .navbar ul li:hover a,
  .navbar ul li:hover i {
    color: var(--primary-color);
    text-decoration: none;
  }
}
@media (max-width: 991px) {
  .navbar ul.navbar-nav {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;
    display: inline-block;
    width: 100%;
  }
  .navbar-nav {
    padding-left: 0px !important;
  }
  .navbar ul {
    /* float: left; */
    width: 100%;
    background: var(--iq-white-color);
  }
  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    padding: 15px;
    z-index: inherit;
    text-align: left;
    font-weight: normal;
  }
  header .navbar ul li.menu-item a {
    line-height: normal;
  }
  header .navbar ul li.menu-item a {
    line-height: 10px;
    float: left;
    width: 100%;
    text-align: left;
    padding: 10px 30px;
  }
  header .navbar ul li.menu-item a {
    line-height: 10px;
    float: left;
    width: 100%;
    text-align: left;
    padding: 10px 30px;
    color: var(--iq-white);
  }
  header .navbar ul li:hover .sub-menu {
    box-shadow: none;
  }
  header .navbar ul li .sub-menu,
  header .navbar ul li:hover .sub-menu {
    display: none;
    position: relative;
    top: 0;
    width: 100%;
    padding: 0px;
    z-index: 9;
  }
  header .navbar ul li.menu-item a {
    line-height: normal;
  }
  header .navbar ul li.menu-item a {
    line-height: 10px;
    float: left;
    width: 100%;
    text-align: left;
    padding: 20px 20px 20px 15px;
  }
  header .navbar ul li:hover .sub-menu {
    box-shadow: none;
  }
  header .navbar ul li:hover .sub-menu {
    box-shadow: none;
  }
  header .navbar ul li .sub-menu li .sub-menu li.menu-item a {
    background: transparent;
  }

  .navbar .navbar-nav li:last-child .sub-menu li:hover > .sub-menu,
  .navbar .navbar-nav li:nth-last-child(2) .sub-menu li:hover > .sub-menu {
    left: auto;
    right: 0px;
    top: 0px;
  }
}
@media (max-width: 1024px) {
  .site-main {
    padding: 50px 0px 50px 0px;
  }
}

@media (max-width: 767px) {
  .icon-box-content {
    text-align: center;
  }

  .image-box img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .iq-contact-list {
    border: 0px;
  }

  .image-box svg {
    width: 150px;
    height: 150px;
  }
  .svg-header {
    padding-top: 50px;
  }
  .icon-box-content .widget-container .size-default {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: block;
    overflow-x: auto;
  }
}

@media (min-width: 992px) {
  .verticle-btn {
    opacity: 0 !important;
  }
  .slider-images .block-description {
    display: none;
  }
}

@media (max-width: 991px) {
  .verticle-slider .slider--col {
    display: none;
  }
  .verticle-slider .slider-images {
    position: unset !important;
    height: 18.75em !important;
  }
  .btn-verticle {
    opacity: 0;
  }
  .verticle-slider h4.main-title {
    left: auto;
    width: 100%;
    text-align: center;
  }
  .slider-images .hover-buttons {
    opacity: 10 !important;
  }
}
