.live-channel {
  border-left: 5px solid var(--iq-primary);
  background: transparent
    /* linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 55, 65, 0.3) 100%); */
    linear-gradient(
      270deg,
      rgba(1, 7, 11, 0) 0%,
      rgba(39, 169, 225, 0.171) 50%,
      rgba(6, 148, 209, 0.5) 100%
    );
  padding: 10px 10px 10px 0px;
  width: 170px;
  position: relative;
  overflow: hidden;
  height: 3.5rem;
  margin-bottom: 1rem;
}
