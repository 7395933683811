.movie-banner {
  position: relative;
  width: 100%;
  height: 110vh;
}

.inner-container-watch-trailer-mbl {
  background: transparent
    /* linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 55, 65, 0.3) 100%); */
    linear-gradient(
      270deg,
      rgba(11, 1, 2, 0.7) 30%,
      rgba(39, 170, 225, 0.3) 100%
    );
  padding: 0.2rem;
  border-radius: 4px;
}
.podcast-banner {
  position: relative;
  width: 100%;
  height: 100vh;
}
.video-banner {
  position: relative;
  width: 100%;
  height: 90vh;
}
.lock-banner {
  position: relative;
  width: 70%;
  height: 70vh;
}
@media screen and (max-width: 1400px) {
  .movie-banner {
    height: 120vh;
  }
  .video-banner {
    height: 90vh;
  }
}

@media screen and (max-width: 1200px) {
  .lock-banner {
    height: 50vh;
  }
}
@media screen and (max-width: 1000px) {
  .lock-banner {
    width: 100%;
  }
  .lock-banner-content {
    margin: 0px;
  }
  .unlock-text {
    font-size: 1.7rem !important;
  }
}
@media screen and (max-width: 600px) {
  .unlock-text {
    font-size: 1.1rem !important;
  }
  .unlock-svg {
    width: 30px !important;
    height: 30px !important;
  }
  .lock-banner {
    height: 30vh;
  }
}
.poster {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.overlay_poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(29 27 27 / 66%);
}

.content {
  position: absolute;
  top: 5%;
  left: 30px;
  color: white;
}

@media screen and (min-width: 1400px) {
  .content {
    position: absolute;
    top: 10%;
    left: 30px;
    color: white;
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
}

.description {
  font-size: 12px;
}

.lock-text {
  font-size: 2rem !important;
}

.details {
  font-size: 14px;
}

.season-trailer-container-main {
  position: absolute;
  bottom: 10px;
  right: 30px;
  z-index: 1;
}

.unlock-text {
  font-size: 1.7rem;
  font-weight: 600;
  color: white;
}
.unlock-button {
  margin-top: 10rem !important;
}
.login-unlock-text {
  font-size: 1.7rem;
}
.unlock-button-link svg:hover {
  color: #091a25;
}

.unlock-button-link svg:hover {
  color: #091a25;
}

.unlock-svg {
  width: 60px;
  height: 60px;
}

@media (min-width: 1400px) {
  .season-banner-dec {
    font-size: 1rem;
  }
}
@media (max-width: 1400px) {
  .season-banner-dec {
    font-size: 0.8rem;
  }
}

.w-trailor-mbl {
  font-size: 1rem;
  padding: 0.6rem;

  color: white;
}
