.delete-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 8rem;
  min-height: 80vh;
  max-width: 60%;
  margin: auto;
}

.delete-main .left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.delete-main .left img {
  width: 20rem;
}
.delete-main .right {
  width: 80%;
}

.delete-main .right span {
  font-size: 0.7rem;
  text-align: center;
}
.delete-main .right h2 {
  font-size: 1.3rem;
}

@media (min-width: 1500px) {
  .delete-main .left img {
    width: 25rem;
  }
  .delete-main .right h2 {
    font-size: 3.6rem;
  }
  .delete-main .right span {
    font-size: 1.4rem;
  }
}

@media (min-width: 1200px) {
  .delete-main .left img {
    width: 24rem;
  }
  .delete-main .right h2 {
    font-size: 3rem;
  }
  .delete-main .right span {
    font-size: 0.9rem;
  }
}

@media (min-width: 900px) {
  .delete-main {
    flex-direction: row;
  }
  .delete-main .right h2 {
    font-size: 2.6rem;
  }
  .delete-main .right span {
    font-size: 0.8rem;
  }

  .delete-main .right {
    width: 50%;
  }
}

@media (min-width: 800px) {
  .delete-main .right h2 {
    font-size: 2rem;
  }
  .delete-main .right span {
    font-size: 0.8rem;
  }
  .delete-main .right {
    margin-left: 6rem;
    max-width: 25rem;
  }
}

@media (min-width: 300px) {
  .delete-main .left img {
    width: 24rem;
  }
  .delete-main .right h2 {
    font-size: 1.4rem;
  }
  .delete-main .right span {
    font-size: 0.8rem;
  }
  .delete-main .right {
    /* margin-left: 4rem; */
    width: 90%;
  }
}
