/* HOME PAGE CSS */
#iq-topten {
  margin-top: 60px;
}

#iq-upcoming-movie {
  margin-top: 20px;
}

#iq-suggestede {
  margin-top: 40px;
}

#parallex {
  margin-top: 40px;
}

.iq-main-header {
  margin-bottom: 40px;
}

#margin {
  margin-top: 40px;
}

.button-hover {
  background: var(--iq-primary-hover) !important;
  border: none;
  color: var(--iq-white);
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 5px;
  background: var(--iq-primary-hover);
}
.swiper-slide:hover .block-images {
  overflow: visible;
  border-left: 5px solid var(--iq-primary-hover);
}
.iq-custom-select1 {
  width: 150px;
  border: 1px solid black;
  position: absolute;
}

.swiper-slide .block-images::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.8); */
  width: 100%;
  height: 100%;
  opacity: 0;
}
.swiper-slide:hover .block-images::before {
  opacity: 1;
  z-index: 9;
}
.swiper-slide:hover .block-images::before {
}
.swiper-slide:hover .block-images {
  overflow: visible;
}
.swiper-slide:hover .block-images .img-box {
  position: relative;
}
.swiper-slide:hover .block-images {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
  transform-origin: 60% 60%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9); */
}
.block-social-info {
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 25px;
  z-index: 999;
  display: flex;
  align-items: center;
}
.block-social-info-button {
  position: absolute;
  top: 0;
  left: auto;
  bottom: 5px;
  z-index: 999;
  display: flex;
  align-items: end;
  opacity: 0;
}
.swiper-slide:hover .block-social-info-button {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}
.swiper-slide:hover .title-desc {
  width: 80%;
  -webkit-line-clamp: 2;
  margin-bottom: 0;
}
.swiper-slide:hover .live-title-desc {
  width: 80%;
  -webkit-line-clamp: 1;
  margin-bottom: 0;
}
.gallery-item:hover .block-description {
  width: 80%;
}
.swiper-slide:hover .card-slider-desc {
  display: block;
  -webkit-line-clamp: 2;
}
.card-slider-desc {
  display: none;
  width: 72%;
  line-height: 1;
  color: white !important;
}
.card-slider-desc span {
  font-size: 0.8rem;
}
.card-slider-badge {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 9;
}

.card-slider-badge-hidden {
  display: none;
}

.swiper-slide:hover .card-slider-badge-hidden {
  display: block;
}
/* .swiper-slide:hover .card-slider-badge {
  display: none;
} */

.swiper-slide:hover .card-slider-content {
  flex-direction: column;
  display: inline-block;
}
.music-play-lists li {
  position: relative;
  height: 30px;
  width: 30px;
  line-height: 35px;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 0 auto 7px;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
}
.music-play-lists span {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  background: var(--iq-white);
  color: var(--iq-primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -o-display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  cursor: pointer;
}

.music-play-lists .count-box {
  height: 15px !important;
  width: 15px !important;
  line-height: 15px i !important;
  font-size: 8px !important;
  /* background: var(--iq-primary) !important; */
  color: var(--iq-white-color) !important;
  position: absolute;
  right: 0;
  top: 0px;
  padding: 0;
  text-align: center !important;
}

.music-play-lists li:hover span {
  background: var(--iq-primary);
  color: var(--iq-white);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
}
.block-description {
  position: absolute;
  left: 25px;
  /* top: 0; */
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-slide:hover .block-social-info {
  animation: fadeIn 0.6s ease-in-out;
  opacity: 1;
}
.swiper-slide:hover .block-description {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
}
/* Styling updated for all pages */
.slider-img-main:hover .block-description {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
  top: 0;
}

.slider-img-main:hover .block-description h6.iq-title {
  display: block;
}
.slider-img-main:hover .card-slider-desc {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
  top: 0;
  display: flex;
}
.slider-img-main:hover .block-social-info-button {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
  top: 0;
}
/*Styling updated for all pages ends here */

/* .swiper-slide:hover .block-images{ transform: scale3d(1.1, 1.1, 1) translate3d(1%, 0, 0) perspective(500px); } */
/* .swiper-slide:hover .block-images1{ transform: scale3d(1.1, 1.1, 1) translate3d(6%, 0, 0) perspective(500px); } */
/* .swiper-slide:hover .block-images2{ transform: scale3d(1.1, 1.1, 1) translate3d(-6%, 0, 0) perspective(500px); } */
.swiper-slide .block-images::after {
  position: absolute;
  content: "";
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  /* background: linear-gradient(
    0deg,
    rgb(0 0 0 / 50%),
    rgba(0, 0, 0, 40%),
    hsl(0deg 0% 0% / 5%)
  ); */
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
}

/* .block-description .card-slider-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.swiper-slide .vertical-block-images::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  /* background: linear-gradient(
    0deg,
    rgb(0 0 0 / 70%),
    rgba(0, 0, 0, 60%),
    hsla(0, 0%, 0%, 0.658)
  ); */
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
}

@media (max-width: 991px) {
  .slider--image .live-channel-banner-content {
    display: none !important;
  }
}
@media (max-width: 1500px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 33% !important;
  }
}

@media (max-width: 1400px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 35% !important;
  }
}

@media (max-width: 1300px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 40% !important;
  }
}
@media (max-width: 1200px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 40% !important;
  }
}
@media (max-width: 1100px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 43% !important;
  }
}
@media (max-width: 1000px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 50% !important;
  }
}
@media (max-width: 991px) {
  .slider--image .live-channel-banner-content {
    top: 30% !important;
    left: 60% !important;
  }
}
.slider--image .live-channel-banner-content {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 9999;
}

/* .css-yk16xz-control{
    background: transparent !important;
    color:var(--iq-white);
    border: 1px solid white !important;
    border-radius: 0 !important;
    font-size: 14px; */
/* height: 5px !important; */
/* } */
.form-control1 {
  background: transparent !important;
  color: var(--iq-white);
  border: 1px solid white !important;
  border-radius: 0 !important;
  font-size: 14px;
  height: 45px;
  /* height: 5px !important; */
}

#cars select option {
  background-color: red !important;
}
/* 
.css-yk16xz-control{
    height: 45px !important;
} */
/* 
.css-g1d714-ValueContainer{
    height: 45px !important;
}

.css-1wa3eu0-placeholder{
    color: white !important;
} */

/* .searchbox {  background: #141414 !important; color: white; width: 100% }  */
.img-size {
  width: 100%;
}
.show .search-box {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  margin-top: 1rem !important;
}
.navbar-right .show .iq-sub-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.navbar-right li .iq-sub-dropdown .iq-sub-card {
  color: white !important;
}
.search {
  left: auto !important;
  right: 0 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  opacity: 1;
  background: linear-gradient(
    to top,
    rgba(83, 100, 141, 0) 0%,
    #27aae1 85%
  ) !important;
}

.trending-pills.nav-pills .nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3px;
  background: var(--iq-primary) !important;
  opacity: 1;
  transition: all 0.8s linear;
}
.iq-accordion .iq-accordion-block .iq-accordion-title .iq-icon-right:hover {
  color: white;
}

.iq-accordion .iq-accordion-block .iq-accordion-title .iq-icon-right {
  color: white;
}
.css-yk16xz-control {
  border: 1px solid white !important;
  border-radius: unset !important;
  color: var(--iq-white);
}
.css-1pahdxg-control {
  border: 1px solid white !important;
  border-radius: unset !important;
  box-shadow: none !important;
}

.css-1wa3eu0-placeholder {
  color: var(--iq-white) !important;
  font-size: 14px;
}

.css-tlfecz-indicatorContainer {
  background: transparent !important;
}

.css-yk16xz-control {
  background: transparent !important;
}
.css-1gtu0rj-indicatorContainer {
  background: transparent !important;
}

.btn-primary1:hover {
  background: var(--iq-primary-hover);
  border: none !important;
}
/* #movieshow .swiper-container{
    height: 630px !important;
} */
/* 
#movieshow .swiper-slide .swiper-slide-duplicate .swiper-slide-prev{
    height: 500px !important;
} */
/* #movieshow .swiper-slide.swiper-slide-active{
    width: 1050.2px !important;
} */
/* .img1{
    height: 100%;
} */
/* 
#movieshow .swiper-slide.swiper-slide-duplicate.swiper-slide-prev .shows-img {
    height: 100% !important;
    width: 100% !important;
}

#movieshow .swiper-slide.swiper-slide-next .shows-img {
    height: 100% !important;
    width: 100% !important;
}

#movieshow .swiper-slide.swiper-slide-active .shows-img {
    height: 100% !important;
    width: 100% !important;
} */
.css-1rhbuit-multiValue {
  background: transparent !important;
  border: none !important;
  color: white !important;
}
.css-12jo7m5 {
  color: white !important;
}
.css-12jo7m5 {
  font-size: 88% !important;
}
.css-xb97g8:hover {
  background-color: transparent !important;
  color: #343a40 !important;
}
.css-xb97g8 {
  color: #bdc6d0 !important;
}
/* .css-2613qy-menu{
    background-color: red !important;
    color: pink;
} */
.form1 {
  width: 50%;
}
.form2 {
  width: 100%;
}
.iq-text {
  width: 115px !important;
}
/* .css-yk16xz-control{
    width: 115px !important;
} */
.css-1uccc91-singleValue {
  color: #ffffff !important;
}
div#f2 {
  width: 125px !important;
}
div#f3 {
  width: 125px !important;
}
div#f4 {
  width: 125px !important;
}
div#f5 {
  width: 125px !important;
}
div#f6 {
  width: 125px !important;
}
div#f7 {
  width: 125px !important;
}

.live-banner-description {
  width: 50%;
}

.all-title-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}

.iq-title-box .all-title-desc {
  margin: 10px 0 0;
}

.all-card-container:hover .all-title-desc {
  width: 81%;
  -webkit-line-clamp: 2;
}

.all-card-container:hover .block-description h6.iq-title {
  display: inline;
}

.all-card-container:hover .card-slider-desc {
  display: block;
  -webkit-line-clamp: 2;
}
.card-slider-desc {
  display: none;
  width: 72%;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 5px;
}
.card-slider-desc span {
  font-size: 0.8rem;
}
.all-card-container:hover .card-slider-desc {
  animation: fadeIn 1.5s ease-in-out;
  opacity: 1;
  top: 0;
  display: flex;
}

.paymanetcard-image {
  /* width: 60vh; */
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymanetcard-image img {
  width: 50vh;
}

@media (max-width: 600px) {
  .paymanetcard-image {
    height: 30vh;
  }
  .paymanetcard-image img {
    width: 40vh;
  }
}

/* number */
.react-international-phone-input-container {
  display: flex;
  width: 100%;
}

.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: 48px !important;
  box-sizing: border-box;
  margin: 0;
  background-color: #141414;
  border: 1px solid #404043;
  color: #fff;
  font-family: inherit;
  font-size: 13px;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.react-international-phone-country-selector-button {
  display: flex;
  height: 48px !important;
  box-sizing: border-box;
  background-color: #141414;
  border: 1px solid #404043;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-transform: none;
}
.react-international-phone-country-selector-button__flag-emoji {
  margin: 0 5px 0 10px;
}

.contact-input[type="number"]::-webkit-inner-spin-button,
.contact-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.contact-input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 500px) {
  .genre-type-desktop {
    display: none !important;
  }
}
@media (min-width: 501px) {
  .genre-type-mobile {
    display: none !important;
  }
}

.genre-type-mobile {
  position: absolute;
  top: 6px;
  right: 6px;
}

.trailer-modal .modal-content {
  width: 100%;
  background-color: #14141400 !important;
  /* background-color: #2c2c2c !important;
  box-shadow: 0 0 9px var(--iq-primary); */
}
.trailer-modal .modal-body {
  padding: 0rem;
}

.search-content:hover .search-content-play-now {
  opacity: 1;
}
.search-content-play-now {
  opacity: 0;
}

@media (max-width: 991px) {
  .main-slider-logo {
    display: none;
  }
}

.margin-bottom-footer {
  margin-bottom: 100vh !important;
}

.Payment-Type {
  margin: 10px;
  width: 100%;
  font-size: 20px !important;
  padding: 10px;
}
.Bank-Type {
  margin: auto;
  display: flex;
  width: 100%;
  font-size: 20px !important;
  padding: 10px;
  justify-content: center;
}
.three-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.threeModal .modal-content {
  background-color: #141414 !important;
  box-shadow: 0 0 9px #6d6d6d;
  width: 95% !important;
  margin: auto;
}
.three-pay-btn {
  width: 100%;
}

.exclusiveModal .modal-content {
  background-color: #141414 !important;
  box-shadow: 0 0 9px #6d6d6d;
}
.exclusiveModal .modal-body {
  padding: 1rem !important;
  display: flex !important;
  justify-content: space-around;
}
.exclusiveModal .delBtn {
  background: var(--iq-primary);
  background-image: linear-gradient(45deg, #1171b6, #1075bd, #21a6e0);
  border: none;
}
