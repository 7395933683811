.custom-tooltip {
  position: relative; /* making the .custom-tooltip span a container for the tooltip text */
}

.custom-tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #155488;
  color: #fff;
  text-align: center;

  opacity: 0;
  transition: 0.3s opacity;

  display: none; /* hide by default */
  z-index: 999999;
}
.custom-tooltip:hover:before {
  display: block;
  opacity: 1;
}
.custom-tooltip.custom-left:before {
  /* reset defaults */
  left: initial;
  margin: initial;

  /* set new values */
  right: 50%;
  top: 10%;
  margin-right: 15px;
}

.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
  display: block;
}

.custom-tooltip:hover:after {
  opacity: 1;
}
