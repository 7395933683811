/*
* Remix Icon v2.0.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2019-09-15
*/
@font-face {
  font-family: "remixicon";
  src: url('../fonts/remixicon.eot?t=1568552893872'); /* IE9*/
  src: url('../fonts/remixicon.eot?t=1568552893872#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("../fonts/remixicon.woff2?t=1568552893872") format("woff2"),
  url("../fonts/remixicon.woff?t=1568552893872") format("woff"),
  url('../fonts/remixicon.ttf?t=1568552893872') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../fonts/remixicon.svg?t=1568552893872#remixicon') format('svg'); /* iOS 4.1- */
  font-display: swap;
}

[class^="ri-"], [class*=" ri-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
      vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg { font-size: 1.3333em; line-height: 0.75em; vertical-align: -.0667em; }
.ri-xl { font-size: 1.5em; line-height: 0.6666em; vertical-align: -.075em; }
.ri-xxs { font-size: .5em; }
.ri-xs { font-size: .75em; }
.ri-sm { font-size: .875em }
.ri-1x { font-size: 1em; }
.ri-2x { font-size: 2em; }
.ri-3x { font-size: 3em; }
.ri-4x { font-size: 4em; }
.ri-5x { font-size: 5em; }
.ri-6x { font-size: 6em; }
.ri-7x { font-size: 7em; }
.ri-8x { font-size: 8em; }
.ri-9x { font-size: 9em; }
.ri-10x { font-size: 10em; }
.ri-fw { text-align: center; width: 1.25em; }

.ri-4k-fill:before { content: "\ea01"; }
.ri-4k-line:before { content: "\ea02"; }
.ri-account-box-fill:before { content: "\ea03"; }
.ri-account-box-line:before { content: "\ea04"; }
.ri-account-circle-fill:before { content: "\ea05"; }
.ri-account-circle-line:before { content: "\ea06"; }
.ri-account-pin-box-fill:before { content: "\ea07"; }
.ri-account-pin-box-line:before { content: "\ea08"; }
.ri-account-pin-circle-fill:before { content: "\ea09"; }
.ri-account-pin-circle-line:before { content: "\ea0a"; }
.ri-add-box-fill:before { content: "\ea0b"; }
.ri-add-box-line:before { content: "\ea0c"; }
.ri-add-circle-fill:before { content: "\ea0d"; }
.ri-add-circle-line:before { content: "\ea0e"; }
.ri-add-fill:before { content: "\ea0f"; }
.ri-add-line:before { content: "\ea10"; }
.ri-admin-fill:before { content: "\ea11"; }
.ri-admin-line:before { content: "\ea12"; }
.ri-airplay-fill:before { content: "\ea13"; }
.ri-airplay-line:before { content: "\ea14"; }
.ri-alarm-fill:before { content: "\ea15"; }
.ri-alarm-line:before { content: "\ea16"; }
.ri-alarm-warning-fill:before { content: "\ea17"; }
.ri-alarm-warning-line:before { content: "\ea18"; }
.ri-album-fill:before { content: "\ea19"; }
.ri-album-line:before { content: "\ea1a"; }
.ri-alert-fill:before { content: "\ea1b"; }
.ri-alert-line:before { content: "\ea1c"; }
.ri-align-bottom:before { content: "\ea1d"; }
.ri-align-center:before { content: "\ea1e"; }
.ri-align-justify:before { content: "\ea1f"; }
.ri-align-left:before { content: "\ea20"; }
.ri-align-right:before { content: "\ea21"; }
.ri-align-top:before { content: "\ea22"; }
.ri-align-vertically:before { content: "\ea23"; }
.ri-alipay-fill:before { content: "\ea24"; }
.ri-alipay-line:before { content: "\ea25"; }
.ri-amazon-fill:before { content: "\ea26"; }
.ri-amazon-line:before { content: "\ea27"; }
.ri-anchor-fill:before { content: "\ea28"; }
.ri-anchor-line:before { content: "\ea29"; }
.ri-android-fill:before { content: "\ea2a"; }
.ri-android-line:before { content: "\ea2b"; }
.ri-angularjs-fill:before { content: "\ea2c"; }
.ri-angularjs-line:before { content: "\ea2d"; }
.ri-anticlockwise-2-fill:before { content: "\ea2e"; }
.ri-anticlockwise-2-line:before { content: "\ea2f"; }
.ri-anticlockwise-fill:before { content: "\ea30"; }
.ri-anticlockwise-line:before { content: "\ea31"; }
.ri-apple-fill:before { content: "\ea32"; }
.ri-apple-line:before { content: "\ea33"; }
.ri-apps-2-fill:before { content: "\ea34"; }
.ri-apps-2-line:before { content: "\ea35"; }
.ri-apps-fill:before { content: "\ea36"; }
.ri-apps-line:before { content: "\ea37"; }
.ri-archive-drawer-fill:before { content: "\ea38"; }
.ri-archive-drawer-line:before { content: "\ea39"; }
.ri-archive-fill:before { content: "\ea3a"; }
.ri-archive-line:before { content: "\ea3b"; }
.ri-arrow-down-circle-fill:before { content: "\ea3c"; }
.ri-arrow-down-circle-line:before { content: "\ea3d"; }
.ri-arrow-down-fill:before { content: "\ea3e"; }
.ri-arrow-down-line:before { content: "\ea3f"; }
.ri-arrow-down-s-fill:before { content: "\ea40"; }
.ri-arrow-down-s-line:before { content: "\ea41"; }
.ri-arrow-drop-down-fill:before { content: "\ea42"; }
.ri-arrow-drop-down-line:before { content: "\ea43"; }
.ri-arrow-drop-left-fill:before { content: "\ea44"; }
.ri-arrow-drop-left-line:before { content: "\ea45"; }
.ri-arrow-drop-right-fill:before { content: "\ea46"; }
.ri-arrow-drop-right-line:before { content: "\ea47"; }
.ri-arrow-drop-up-fill:before { content: "\ea48"; }
.ri-arrow-drop-up-line:before { content: "\ea49"; }
.ri-arrow-go-back-fill:before { content: "\ea4a"; }
.ri-arrow-go-back-line:before { content: "\ea4b"; }
.ri-arrow-go-forward-fill:before { content: "\ea4c"; }
.ri-arrow-go-forward-line:before { content: "\ea4d"; }
.ri-arrow-left-circle-fill:before { content: "\ea4e"; }
.ri-arrow-left-circle-line:before { content: "\ea4f"; }
.ri-arrow-left-down-fill:before { content: "\ea50"; }
.ri-arrow-left-down-line:before { content: "\ea51"; }
.ri-arrow-left-fill:before { content: "\ea52"; }
.ri-arrow-left-line:before { content: "\ea53"; }
.ri-arrow-left-s-fill:before { content: "\ea54"; }
.ri-arrow-left-s-line:before { content: "\ea55"; }
.ri-arrow-left-up-fill:before { content: "\ea56"; }
.ri-arrow-left-up-line:before { content: "\ea57"; }
.ri-arrow-right-circle-fill:before { content: "\ea58"; }
.ri-arrow-right-circle-line:before { content: "\ea59"; }
.ri-arrow-right-down-fill:before { content: "\ea5a"; }
.ri-arrow-right-down-line:before { content: "\ea5b"; }
.ri-arrow-right-fill:before { content: "\ea5c"; }
.ri-arrow-right-line:before { content: "\ea5d"; }
.ri-arrow-right-s-fill:before { content: "\ea5e"; }
.ri-arrow-right-s-line:before { content: "\ea5f"; }
.ri-arrow-right-up-fill:before { content: "\ea60"; }
.ri-arrow-right-up-line:before { content: "\ea61"; }
.ri-arrow-up-circle-fill:before { content: "\ea62"; }
.ri-arrow-up-circle-line:before { content: "\ea63"; }
.ri-arrow-up-fill:before { content: "\ea64"; }
.ri-arrow-up-line:before { content: "\ea65"; }
.ri-arrow-up-s-fill:before { content: "\ea66"; }
.ri-arrow-up-s-line:before { content: "\ea67"; }
.ri-artboard-2-fill:before { content: "\ea68"; }
.ri-artboard-2-line:before { content: "\ea69"; }
.ri-artboard-fill:before { content: "\ea6a"; }
.ri-artboard-line:before { content: "\ea6b"; }
.ri-article-fill:before { content: "\ea6c"; }
.ri-article-line:before { content: "\ea6d"; }
.ri-at-fill:before { content: "\ea6e"; }
.ri-at-line:before { content: "\ea6f"; }
.ri-attachment-2:before { content: "\ea70"; }
.ri-attachment-fill:before { content: "\ea71"; }
.ri-attachment-line:before { content: "\ea72"; }
.ri-award-fill:before { content: "\ea73"; }
.ri-award-line:before { content: "\ea74"; }
.ri-baidu-fill:before { content: "\ea75"; }
.ri-baidu-line:before { content: "\ea76"; }
.ri-ball-pen-fill:before { content: "\ea77"; }
.ri-ball-pen-line:before { content: "\ea78"; }
.ri-bank-card-2-fill:before { content: "\ea79"; }
.ri-bank-card-2-line:before { content: "\ea7a"; }
.ri-bank-card-fill:before { content: "\ea7b"; }
.ri-bank-card-line:before { content: "\ea7c"; }
.ri-bank-fill:before { content: "\ea7d"; }
.ri-bank-line:before { content: "\ea7e"; }
.ri-bar-chart-2-fill:before { content: "\ea7f"; }
.ri-bar-chart-2-line:before { content: "\ea80"; }
.ri-bar-chart-box-fill:before { content: "\ea81"; }
.ri-bar-chart-box-line:before { content: "\ea82"; }
.ri-bar-chart-fill:before { content: "\ea83"; }
.ri-bar-chart-grouped-fill:before { content: "\ea84"; }
.ri-bar-chart-grouped-line:before { content: "\ea85"; }
.ri-bar-chart-horizontal-fill:before { content: "\ea86"; }
.ri-bar-chart-horizontal-line:before { content: "\ea87"; }
.ri-bar-chart-line:before { content: "\ea88"; }
.ri-barcode-box-fill:before { content: "\ea89"; }
.ri-barcode-box-line:before { content: "\ea8a"; }
.ri-barcode-fill:before { content: "\ea8b"; }
.ri-barcode-line:before { content: "\ea8c"; }
.ri-barricade-fill:before { content: "\ea8d"; }
.ri-barricade-line:before { content: "\ea8e"; }
.ri-base-station-fill:before { content: "\ea8f"; }
.ri-base-station-line:before { content: "\ea90"; }
.ri-battery-2-charge-fill:before { content: "\ea91"; }
.ri-battery-2-charge-line:before { content: "\ea92"; }
.ri-battery-2-fill:before { content: "\ea93"; }
.ri-battery-2-line:before { content: "\ea94"; }
.ri-battery-charge-fill:before { content: "\ea95"; }
.ri-battery-charge-line:before { content: "\ea96"; }
.ri-battery-fill:before { content: "\ea97"; }
.ri-battery-line:before { content: "\ea98"; }
.ri-battery-low-fill:before { content: "\ea99"; }
.ri-battery-low-line:before { content: "\ea9a"; }
.ri-behance-fill:before { content: "\ea9b"; }
.ri-behance-line:before { content: "\ea9c"; }
.ri-bike-fill:before { content: "\ea9d"; }
.ri-bike-line:before { content: "\ea9e"; }
.ri-bilibili-fill:before { content: "\ea9f"; }
.ri-bilibili-line:before { content: "\eaa0"; }
.ri-bill-fill:before { content: "\eaa1"; }
.ri-bill-line:before { content: "\eaa2"; }
.ri-bit-coin-fill:before { content: "\eaa3"; }
.ri-bit-coin-line:before { content: "\eaa4"; }
.ri-bluetooth-connect-fill:before { content: "\eaa5"; }
.ri-bluetooth-connect-line:before { content: "\eaa6"; }
.ri-bluetooth-fill:before { content: "\eaa7"; }
.ri-bluetooth-line:before { content: "\eaa8"; }
.ri-blur-off-fill:before { content: "\eaa9"; }
.ri-blur-off-line:before { content: "\eaaa"; }
.ri-bold:before { content: "\eaab"; }
.ri-book-2-fill:before { content: "\eaac"; }
.ri-book-2-line:before { content: "\eaad"; }
.ri-book-3-fill:before { content: "\eaae"; }
.ri-book-3-line:before { content: "\eaaf"; }
.ri-book-fill:before { content: "\eab0"; }
.ri-book-line:before { content: "\eab1"; }
.ri-book-mark-fill:before { content: "\eab2"; }
.ri-book-mark-line:before { content: "\eab3"; }
.ri-book-open-fill:before { content: "\eab4"; }
.ri-book-open-line:before { content: "\eab5"; }
.ri-book-read-fill:before { content: "\eab6"; }
.ri-book-read-line:before { content: "\eab7"; }
.ri-bookmark-2-fill:before { content: "\eab8"; }
.ri-bookmark-2-line:before { content: "\eab9"; }
.ri-bookmark-3-fill:before { content: "\eaba"; }
.ri-bookmark-3-line:before { content: "\eabb"; }
.ri-bookmark-fill:before { content: "\eabc"; }
.ri-bookmark-line:before { content: "\eabd"; }
.ri-braces-fill:before { content: "\eabe"; }
.ri-braces-line:before { content: "\eabf"; }
.ri-brackets-fill:before { content: "\eac0"; }
.ri-brackets-line:before { content: "\eac1"; }
.ri-briefcase-2-fill:before { content: "\eac2"; }
.ri-briefcase-2-line:before { content: "\eac3"; }
.ri-briefcase-3-fill:before { content: "\eac4"; }
.ri-briefcase-3-line:before { content: "\eac5"; }
.ri-briefcase-4-fill:before { content: "\eac6"; }
.ri-briefcase-4-line:before { content: "\eac7"; }
.ri-briefcase-5-fill:before { content: "\eac8"; }
.ri-briefcase-5-line:before { content: "\eac9"; }
.ri-briefcase-fill:before { content: "\eaca"; }
.ri-briefcase-line:before { content: "\eacb"; }
.ri-broadcast-fill:before { content: "\eacc"; }
.ri-broadcast-line:before { content: "\eacd"; }
.ri-brush-2-fill:before { content: "\eace"; }
.ri-brush-2-line:before { content: "\eacf"; }
.ri-brush-3-fill:before { content: "\ead0"; }
.ri-brush-3-line:before { content: "\ead1"; }
.ri-brush-4-fill:before { content: "\ead2"; }
.ri-brush-4-line:before { content: "\ead3"; }
.ri-brush-fill:before { content: "\ead4"; }
.ri-brush-line:before { content: "\ead5"; }
.ri-bug-2-fill:before { content: "\ead6"; }
.ri-bug-2-line:before { content: "\ead7"; }
.ri-bug-fill:before { content: "\ead8"; }
.ri-bug-line:before { content: "\ead9"; }
.ri-building-2-fill:before { content: "\eada"; }
.ri-building-2-line:before { content: "\eadb"; }
.ri-building-3-fill:before { content: "\eadc"; }
.ri-building-3-line:before { content: "\eadd"; }
.ri-building-4-fill:before { content: "\eade"; }
.ri-building-4-line:before { content: "\eadf"; }
.ri-building-fill:before { content: "\eae0"; }
.ri-building-line:before { content: "\eae1"; }
.ri-bus-2-fill:before { content: "\eae2"; }
.ri-bus-2-line:before { content: "\eae3"; }
.ri-bus-fill:before { content: "\eae4"; }
.ri-bus-line:before { content: "\eae5"; }
.ri-calculator-fill:before { content: "\eae6"; }
.ri-calculator-line:before { content: "\eae7"; }
.ri-calendar-2-fill:before { content: "\eae8"; }
.ri-calendar-2-line:before { content: "\eae9"; }
.ri-calendar-event-fill:before { content: "\eaea"; }
.ri-calendar-event-line:before { content: "\eaeb"; }
.ri-calendar-fill:before { content: "\eaec"; }
.ri-calendar-line:before { content: "\eaed"; }
.ri-calendar-todo-fill:before { content: "\eaee"; }
.ri-calendar-todo-line:before { content: "\eaef"; }
.ri-camera-2-fill:before { content: "\eaf0"; }
.ri-camera-2-line:before { content: "\eaf1"; }
.ri-camera-3-fill:before { content: "\eaf2"; }
.ri-camera-3-line:before { content: "\eaf3"; }
.ri-camera-fill:before { content: "\eaf4"; }
.ri-camera-lens-fill:before { content: "\eaf5"; }
.ri-camera-lens-line:before { content: "\eaf6"; }
.ri-camera-line:before { content: "\eaf7"; }
.ri-camera-off-fill:before { content: "\eaf8"; }
.ri-camera-off-line:before { content: "\eaf9"; }
.ri-camera-switch-fill:before { content: "\eafa"; }
.ri-camera-switch-line:before { content: "\eafb"; }
.ri-car-fill:before { content: "\eafc"; }
.ri-car-line:before { content: "\eafd"; }
.ri-car-washing-fill:before { content: "\eafe"; }
.ri-car-washing-line:before { content: "\eaff"; }
.ri-cast-fill:before { content: "\eb00"; }
.ri-cast-line:before { content: "\eb01"; }
.ri-cellphone-fill:before { content: "\eb02"; }
.ri-cellphone-line:before { content: "\eb03"; }
.ri-celsius-fill:before { content: "\eb04"; }
.ri-celsius-line:before { content: "\eb05"; }
.ri-charging-pile-2-fill:before { content: "\eb06"; }
.ri-charging-pile-2-line:before { content: "\eb07"; }
.ri-charging-pile-fill:before { content: "\eb08"; }
.ri-charging-pile-line:before { content: "\eb09"; }
.ri-chat-1-fill:before { content: "\eb0a"; }
.ri-chat-1-line:before { content: "\eb0b"; }
.ri-chat-2-fill:before { content: "\eb0c"; }
.ri-chat-2-line:before { content: "\eb0d"; }
.ri-chat-3-fill:before { content: "\eb0e"; }
.ri-chat-3-line:before { content: "\eb0f"; }
.ri-chat-4-fill:before { content: "\eb10"; }
.ri-chat-4-line:before { content: "\eb11"; }
.ri-chat-check-fill:before { content: "\eb12"; }
.ri-chat-check-line:before { content: "\eb13"; }
.ri-chat-delete-fill:before { content: "\eb14"; }
.ri-chat-delete-line:before { content: "\eb15"; }
.ri-chat-download-fill:before { content: "\eb16"; }
.ri-chat-download-line:before { content: "\eb17"; }
.ri-chat-forward-fill:before { content: "\eb18"; }
.ri-chat-forward-line:before { content: "\eb19"; }
.ri-chat-heart-fill:before { content: "\eb1a"; }
.ri-chat-heart-line:before { content: "\eb1b"; }
.ri-chat-new-fill:before { content: "\eb1c"; }
.ri-chat-new-line:before { content: "\eb1d"; }
.ri-chat-settings-fill:before { content: "\eb1e"; }
.ri-chat-settings-line:before { content: "\eb1f"; }
.ri-chat-smile-2-fill:before { content: "\eb20"; }
.ri-chat-smile-2-line:before { content: "\eb21"; }
.ri-chat-smile-3-fill:before { content: "\eb22"; }
.ri-chat-smile-3-line:before { content: "\eb23"; }
.ri-chat-smile-fill:before { content: "\eb24"; }
.ri-chat-smile-line:before { content: "\eb25"; }
.ri-chat-upload-fill:before { content: "\eb26"; }
.ri-chat-upload-line:before { content: "\eb27"; }
.ri-chat-voice-fill:before { content: "\eb28"; }
.ri-chat-voice-line:before { content: "\eb29"; }
.ri-check-double-fill:before { content: "\eb2a"; }
.ri-check-double-line:before { content: "\eb2b"; }
.ri-check-fill:before { content: "\eb2c"; }
.ri-check-line:before { content: "\eb2d"; }
.ri-checkbox-blank-circle-fill:before { content: "\eb2e"; }
.ri-checkbox-blank-circle-line:before { content: "\eb2f"; }
.ri-checkbox-blank-fill:before { content: "\eb30"; }
.ri-checkbox-blank-line:before { content: "\eb31"; }
.ri-checkbox-circle-fill:before { content: "\eb32"; }
.ri-checkbox-circle-line:before { content: "\eb33"; }
.ri-checkbox-fill:before { content: "\eb34"; }
.ri-checkbox-indeterminate-fill:before { content: "\eb35"; }
.ri-checkbox-indeterminate-line:before { content: "\eb36"; }
.ri-checkbox-line:before { content: "\eb37"; }
.ri-checkbox-multiple-blank-fill:before { content: "\eb38"; }
.ri-checkbox-multiple-blank-line:before { content: "\eb39"; }
.ri-checkbox-multiple-fill:before { content: "\eb3a"; }
.ri-checkbox-multiple-line:before { content: "\eb3b"; }
.ri-china-railway-fill:before { content: "\eb3c"; }
.ri-china-railway-line:before { content: "\eb3d"; }
.ri-chrome-fill:before { content: "\eb3e"; }
.ri-chrome-line:before { content: "\eb3f"; }
.ri-clapperboard-fill:before { content: "\eb40"; }
.ri-clapperboard-line:before { content: "\eb41"; }
.ri-clipboard-fill:before { content: "\eb42"; }
.ri-clipboard-line:before { content: "\eb43"; }
.ri-clockwise-2-fill:before { content: "\eb44"; }
.ri-clockwise-2-line:before { content: "\eb45"; }
.ri-clockwise-fill:before { content: "\eb46"; }
.ri-clockwise-line:before { content: "\eb47"; }
.ri-close-circle-fill:before { content: "\eb48"; }
.ri-close-circle-line:before { content: "\eb49"; }
.ri-close-fill:before { content: "\eb4a"; }
.ri-close-line:before { content: "\eb4b"; }
.ri-cloud-fill:before { content: "\eb4c"; }
.ri-cloud-line:before { content: "\eb4d"; }
.ri-cloud-windy-fill:before { content: "\eb4e"; }
.ri-cloud-windy-line:before { content: "\eb4f"; }
.ri-cloudy-2-fill:before { content: "\eb50"; }
.ri-cloudy-2-line:before { content: "\eb51"; }
.ri-cloudy-fill:before { content: "\eb52"; }
.ri-cloudy-line:before { content: "\eb53"; }
.ri-code-box-fill:before { content: "\eb54"; }
.ri-code-box-line:before { content: "\eb55"; }
.ri-code-fill:before { content: "\eb56"; }
.ri-code-line:before { content: "\eb57"; }
.ri-code-s-fill:before { content: "\eb58"; }
.ri-code-s-line:before { content: "\eb59"; }
.ri-code-s-slash-fill:before { content: "\eb5a"; }
.ri-code-s-slash-line:before { content: "\eb5b"; }
.ri-code-view:before { content: "\eb5c"; }
.ri-codepen-fill:before { content: "\eb5d"; }
.ri-codepen-line:before { content: "\eb5e"; }
.ri-command-fill:before { content: "\eb5f"; }
.ri-command-line:before { content: "\eb60"; }
.ri-community-fill:before { content: "\eb61"; }
.ri-community-line:before { content: "\eb62"; }
.ri-compass-2-fill:before { content: "\eb63"; }
.ri-compass-2-line:before { content: "\eb64"; }
.ri-compass-3-fill:before { content: "\eb65"; }
.ri-compass-3-line:before { content: "\eb66"; }
.ri-compass-4-fill:before { content: "\eb67"; }
.ri-compass-4-line:before { content: "\eb68"; }
.ri-compass-discover-fill:before { content: "\eb69"; }
.ri-compass-discover-line:before { content: "\eb6a"; }
.ri-compass-fill:before { content: "\eb6b"; }
.ri-compass-line:before { content: "\eb6c"; }
.ri-compasses-2-fill:before { content: "\eb6d"; }
.ri-compasses-2-line:before { content: "\eb6e"; }
.ri-compasses-fill:before { content: "\eb6f"; }
.ri-compasses-line:before { content: "\eb70"; }
.ri-computer-fill:before { content: "\eb71"; }
.ri-computer-line:before { content: "\eb72"; }
.ri-contacts-book-2-fill:before { content: "\eb73"; }
.ri-contacts-book-2-line:before { content: "\eb74"; }
.ri-contacts-book-fill:before { content: "\eb75"; }
.ri-contacts-book-line:before { content: "\eb76"; }
.ri-contacts-fill:before { content: "\eb77"; }
.ri-contacts-line:before { content: "\eb78"; }
.ri-contrast-2-fill:before { content: "\eb79"; }
.ri-contrast-2-line:before { content: "\eb7a"; }
.ri-contrast-drop-2-fill:before { content: "\eb7b"; }
.ri-contrast-drop-2-line:before { content: "\eb7c"; }
.ri-contrast-drop-fill:before { content: "\eb7d"; }
.ri-contrast-drop-line:before { content: "\eb7e"; }
.ri-contrast-fill:before { content: "\eb7f"; }
.ri-contrast-line:before { content: "\eb80"; }
.ri-copper-coin-fill:before { content: "\eb81"; }
.ri-copper-coin-line:before { content: "\eb82"; }
.ri-copper-diamond-fill:before { content: "\eb83"; }
.ri-copper-diamond-line:before { content: "\eb84"; }
.ri-copyright-fill:before { content: "\eb85"; }
.ri-copyright-line:before { content: "\eb86"; }
.ri-coreos-fill:before { content: "\eb87"; }
.ri-coreos-line:before { content: "\eb88"; }
.ri-coupon-2-fill:before { content: "\eb89"; }
.ri-coupon-2-line:before { content: "\eb8a"; }
.ri-coupon-3-fill:before { content: "\eb8b"; }
.ri-coupon-3-line:before { content: "\eb8c"; }
.ri-coupon-4-fill:before { content: "\eb8d"; }
.ri-coupon-4-line:before { content: "\eb8e"; }
.ri-coupon-5-fill:before { content: "\eb8f"; }
.ri-coupon-5-line:before { content: "\eb90"; }
.ri-coupon-fill:before { content: "\eb91"; }
.ri-coupon-line:before { content: "\eb92"; }
.ri-cpu-fill:before { content: "\eb93"; }
.ri-cpu-line:before { content: "\eb94"; }
.ri-crop-2-fill:before { content: "\eb95"; }
.ri-crop-2-line:before { content: "\eb96"; }
.ri-crop-fill:before { content: "\eb97"; }
.ri-crop-line:before { content: "\eb98"; }
.ri-css3-fill:before { content: "\eb99"; }
.ri-css3-line:before { content: "\eb9a"; }
.ri-cup-fill:before { content: "\eb9b"; }
.ri-cup-line:before { content: "\eb9c"; }
.ri-currency-fill:before { content: "\eb9d"; }
.ri-currency-line:before { content: "\eb9e"; }
.ri-cursor-fill:before { content: "\eb9f"; }
.ri-cursor-line:before { content: "\eba0"; }
.ri-customer-service-2-fill:before { content: "\eba1"; }
.ri-customer-service-2-line:before { content: "\eba2"; }
.ri-customer-service-fill:before { content: "\eba3"; }
.ri-customer-service-line:before { content: "\eba4"; }
.ri-dashboard-fill:before { content: "\eba5"; }
.ri-dashboard-line:before { content: "\eba6"; }
.ri-database-2-fill:before { content: "\eba7"; }
.ri-database-2-line:before { content: "\eba8"; }
.ri-database-fill:before { content: "\eba9"; }
.ri-database-line:before { content: "\ebaa"; }
.ri-delete-back-2-fill:before { content: "\ebab"; }
.ri-delete-back-2-line:before { content: "\ebac"; }
.ri-delete-back-fill:before { content: "\ebad"; }
.ri-delete-back-line:before { content: "\ebae"; }
.ri-delete-bin-2-fill:before { content: "\ebaf"; }
.ri-delete-bin-2-line:before { content: "\ebb0"; }
.ri-delete-bin-3-fill:before { content: "\ebb1"; }
.ri-delete-bin-3-line:before { content: "\ebb2"; }
.ri-delete-bin-4-fill:before { content: "\ebb3"; }
.ri-delete-bin-4-line:before { content: "\ebb4"; }
.ri-delete-bin-5-fill:before { content: "\ebb5"; }
.ri-delete-bin-5-line:before { content: "\ebb6"; }
.ri-delete-bin-6-fill:before { content: "\ebb7"; }
.ri-delete-bin-6-line:before { content: "\ebb8"; }
.ri-delete-bin-7-fill:before { content: "\ebb9"; }
.ri-delete-bin-7-line:before { content: "\ebba"; }
.ri-delete-bin-fill:before { content: "\ebbb"; }
.ri-delete-bin-line:before { content: "\ebbc"; }
.ri-device-fill:before { content: "\ebbd"; }
.ri-device-line:before { content: "\ebbe"; }
.ri-dingding-fill:before { content: "\ebbf"; }
.ri-dingding-line:before { content: "\ebc0"; }
.ri-direction-fill:before { content: "\ebc1"; }
.ri-direction-line:before { content: "\ebc2"; }
.ri-disc-fill:before { content: "\ebc3"; }
.ri-disc-line:before { content: "\ebc4"; }
.ri-discord-fill:before { content: "\ebc5"; }
.ri-discord-line:before { content: "\ebc6"; }
.ri-discuss-fill:before { content: "\ebc7"; }
.ri-discuss-line:before { content: "\ebc8"; }
.ri-divide-fill:before { content: "\ebc9"; }
.ri-divide-line:before { content: "\ebca"; }
.ri-door-lock-box-fill:before { content: "\ebcb"; }
.ri-door-lock-box-line:before { content: "\ebcc"; }
.ri-door-lock-fill:before { content: "\ebcd"; }
.ri-door-lock-line:before { content: "\ebce"; }
.ri-douban-fill:before { content: "\ebcf"; }
.ri-douban-line:before { content: "\ebd0"; }
.ri-double-quotes-l:before { content: "\ebd1"; }
.ri-double-quotes-r:before { content: "\ebd2"; }
.ri-download-2-fill:before { content: "\ebd3"; }
.ri-download-2-line:before { content: "\ebd4"; }
.ri-download-cloud-fill:before { content: "\ebd5"; }
.ri-download-cloud-line:before { content: "\ebd6"; }
.ri-download-fill:before { content: "\ebd7"; }
.ri-download-line:before { content: "\ebd8"; }
.ri-drag-move-2-fill:before { content: "\ebd9"; }
.ri-drag-move-2-line:before { content: "\ebda"; }
.ri-drag-move-fill:before { content: "\ebdb"; }
.ri-drag-move-line:before { content: "\ebdc"; }
.ri-dribbble-fill:before { content: "\ebdd"; }
.ri-dribbble-line:before { content: "\ebde"; }
.ri-drive-fill:before { content: "\ebdf"; }
.ri-drive-line:before { content: "\ebe0"; }
.ri-drizzle-fill:before { content: "\ebe1"; }
.ri-drizzle-line:before { content: "\ebe2"; }
.ri-drop-fill:before { content: "\ebe3"; }
.ri-drop-line:before { content: "\ebe4"; }
.ri-dropbox-fill:before { content: "\ebe5"; }
.ri-dropbox-line:before { content: "\ebe6"; }
.ri-dv-fill:before { content: "\ebe7"; }
.ri-dv-line:before { content: "\ebe8"; }
.ri-dvd-fill:before { content: "\ebe9"; }
.ri-dvd-line:before { content: "\ebea"; }
.ri-e-bike-2-fill:before { content: "\ebeb"; }
.ri-e-bike-2-line:before { content: "\ebec"; }
.ri-e-bike-fill:before { content: "\ebed"; }
.ri-e-bike-line:before { content: "\ebee"; }
.ri-earth-fill:before { content: "\ebef"; }
.ri-earth-line:before { content: "\ebf0"; }
.ri-edge-fill:before { content: "\ebf1"; }
.ri-edge-line:before { content: "\ebf2"; }
.ri-edit-2-fill:before { content: "\ebf3"; }
.ri-edit-2-line:before { content: "\ebf4"; }
.ri-edit-box-fill:before { content: "\ebf5"; }
.ri-edit-box-line:before { content: "\ebf6"; }
.ri-edit-circle-fill:before { content: "\ebf7"; }
.ri-edit-circle-line:before { content: "\ebf8"; }
.ri-edit-fill:before { content: "\ebf9"; }
.ri-edit-line:before { content: "\ebfa"; }
.ri-eject-fill:before { content: "\ebfb"; }
.ri-eject-line:before { content: "\ebfc"; }
.ri-emotion-2-fill:before { content: "\ebfd"; }
.ri-emotion-2-line:before { content: "\ebfe"; }
.ri-emotion-fill:before { content: "\ebff"; }
.ri-emotion-happy-fill:before { content: "\ec00"; }
.ri-emotion-happy-line:before { content: "\ec01"; }
.ri-emotion-line:before { content: "\ec02"; }
.ri-emotion-normal-fill:before { content: "\ec03"; }
.ri-emotion-normal-line:before { content: "\ec04"; }
.ri-emotion-unhappy-fill:before { content: "\ec05"; }
.ri-emotion-unhappy-line:before { content: "\ec06"; }
.ri-equalizer-fill:before { content: "\ec07"; }
.ri-equalizer-line:before { content: "\ec08"; }
.ri-eraser-fill:before { content: "\ec09"; }
.ri-eraser-line:before { content: "\ec0a"; }
.ri-error-warning-fill:before { content: "\ec0b"; }
.ri-error-warning-line:before { content: "\ec0c"; }
.ri-evernote-fill:before { content: "\ec0d"; }
.ri-evernote-line:before { content: "\ec0e"; }
.ri-exchange-box-fill:before { content: "\ec0f"; }
.ri-exchange-box-line:before { content: "\ec10"; }
.ri-exchange-cny-fill:before { content: "\ec11"; }
.ri-exchange-cny-line:before { content: "\ec12"; }
.ri-exchange-dollar-fill:before { content: "\ec13"; }
.ri-exchange-dollar-line:before { content: "\ec14"; }
.ri-exchange-fill:before { content: "\ec15"; }
.ri-exchange-funds-fill:before { content: "\ec16"; }
.ri-exchange-funds-line:before { content: "\ec17"; }
.ri-exchange-line:before { content: "\ec18"; }
.ri-eye-close-fill:before { content: "\ec19"; }
.ri-eye-close-line:before { content: "\ec1a"; }
.ri-eye-fill:before { content: "\ec1b"; }
.ri-eye-line:before { content: "\ec1c"; }
.ri-eye-off-fill:before { content: "\ec1d"; }
.ri-eye-off-line:before { content: "\ec1e"; }
.ri-facebook-box-fill:before { content: "\ec1f"; }
.ri-facebook-box-line:before { content: "\ec20"; }
.ri-facebook-circle-fill:before { content: "\ec21"; }
.ri-facebook-circle-line:before { content: "\ec22"; }
.ri-facebook-fill:before { content: "\ec23"; }
.ri-facebook-line:before { content: "\ec24"; }
.ri-fahrenheit-fill:before { content: "\ec25"; }
.ri-fahrenheit-line:before { content: "\ec26"; }
.ri-feedback-fill:before { content: "\ec27"; }
.ri-feedback-line:before { content: "\ec28"; }
.ri-file-2-fill:before { content: "\ec29"; }
.ri-file-2-line:before { content: "\ec2a"; }
.ri-file-3-fill:before { content: "\ec2b"; }
.ri-file-3-line:before { content: "\ec2c"; }
.ri-file-4-fill:before { content: "\ec2d"; }
.ri-file-4-line:before { content: "\ec2e"; }
.ri-file-add-fill:before { content: "\ec2f"; }
.ri-file-add-line:before { content: "\ec30"; }
.ri-file-chart-2-fill:before { content: "\ec31"; }
.ri-file-chart-2-line:before { content: "\ec32"; }
.ri-file-chart-fill:before { content: "\ec33"; }
.ri-file-chart-line:before { content: "\ec34"; }
.ri-file-code-fill:before { content: "\ec35"; }
.ri-file-code-line:before { content: "\ec36"; }
.ri-file-copy-2-fill:before { content: "\ec37"; }
.ri-file-copy-2-line:before { content: "\ec38"; }
.ri-file-copy-fill:before { content: "\ec39"; }
.ri-file-copy-line:before { content: "\ec3a"; }
.ri-file-damage-fill:before { content: "\ec3b"; }
.ri-file-damage-line:before { content: "\ec3c"; }
.ri-file-download-fill:before { content: "\ec3d"; }
.ri-file-download-line:before { content: "\ec3e"; }
.ri-file-edit-fill:before { content: "\ec3f"; }
.ri-file-edit-line:before { content: "\ec40"; }
.ri-file-excel-2-fill:before { content: "\ec41"; }
.ri-file-excel-2-line:before { content: "\ec42"; }
.ri-file-excel-fill:before { content: "\ec43"; }
.ri-file-excel-line:before { content: "\ec44"; }
.ri-file-fill:before { content: "\ec45"; }
.ri-file-forbid-fill:before { content: "\ec46"; }
.ri-file-forbid-line:before { content: "\ec47"; }
.ri-file-info-fill:before { content: "\ec48"; }
.ri-file-info-line:before { content: "\ec49"; }
.ri-file-line:before { content: "\ec4a"; }
.ri-file-list-2-fill:before { content: "\ec4b"; }
.ri-file-list-2-line:before { content: "\ec4c"; }
.ri-file-list-3-fill:before { content: "\ec4d"; }
.ri-file-list-3-line:before { content: "\ec4e"; }
.ri-file-list-fill:before { content: "\ec4f"; }
.ri-file-list-line:before { content: "\ec50"; }
.ri-file-lock-fill:before { content: "\ec51"; }
.ri-file-lock-line:before { content: "\ec52"; }
.ri-file-mark-fill:before { content: "\ec53"; }
.ri-file-mark-line:before { content: "\ec54"; }
.ri-file-music-fill:before { content: "\ec55"; }
.ri-file-music-line:before { content: "\ec56"; }
.ri-file-paper-fill:before { content: "\ec57"; }
.ri-file-paper-line:before { content: "\ec58"; }
.ri-file-pdf-fill:before { content: "\ec59"; }
.ri-file-pdf-line:before { content: "\ec5a"; }
.ri-file-ppt-2-fill:before { content: "\ec5b"; }
.ri-file-ppt-2-line:before { content: "\ec5c"; }
.ri-file-ppt-fill:before { content: "\ec5d"; }
.ri-file-ppt-line:before { content: "\ec5e"; }
.ri-file-reduce-fill:before { content: "\ec5f"; }
.ri-file-reduce-line:before { content: "\ec60"; }
.ri-file-search-fill:before { content: "\ec61"; }
.ri-file-search-line:before { content: "\ec62"; }
.ri-file-settings-fill:before { content: "\ec63"; }
.ri-file-settings-line:before { content: "\ec64"; }
.ri-file-shield-2-fill:before { content: "\ec65"; }
.ri-file-shield-2-line:before { content: "\ec66"; }
.ri-file-shield-fill:before { content: "\ec67"; }
.ri-file-shield-line:before { content: "\ec68"; }
.ri-file-shred-fill:before { content: "\ec69"; }
.ri-file-shred-line:before { content: "\ec6a"; }
.ri-file-text-fill:before { content: "\ec6b"; }
.ri-file-text-line:before { content: "\ec6c"; }
.ri-file-transfer-fill:before { content: "\ec6d"; }
.ri-file-transfer-line:before { content: "\ec6e"; }
.ri-file-unknow-fill:before { content: "\ec6f"; }
.ri-file-unknow-line:before { content: "\ec70"; }
.ri-file-upload-fill:before { content: "\ec71"; }
.ri-file-upload-line:before { content: "\ec72"; }
.ri-file-user-fill:before { content: "\ec73"; }
.ri-file-user-line:before { content: "\ec74"; }
.ri-file-warning-fill:before { content: "\ec75"; }
.ri-file-warning-line:before { content: "\ec76"; }
.ri-file-word-2-fill:before { content: "\ec77"; }
.ri-file-word-2-line:before { content: "\ec78"; }
.ri-file-word-fill:before { content: "\ec79"; }
.ri-file-word-line:before { content: "\ec7a"; }
.ri-file-zip-fill:before { content: "\ec7b"; }
.ri-file-zip-line:before { content: "\ec7c"; }
.ri-film-fill:before { content: "\ec7d"; }
.ri-film-line:before { content: "\ec7e"; }
.ri-filter-2-fill:before { content: "\ec7f"; }
.ri-filter-2-line:before { content: "\ec80"; }
.ri-filter-3-fill:before { content: "\ec81"; }
.ri-filter-3-line:before { content: "\ec82"; }
.ri-filter-fill:before { content: "\ec83"; }
.ri-filter-line:before { content: "\ec84"; }
.ri-find-replace-fill:before { content: "\ec85"; }
.ri-find-replace-line:before { content: "\ec86"; }
.ri-fire-fill:before { content: "\ec87"; }
.ri-fire-line:before { content: "\ec88"; }
.ri-firefox-fill:before { content: "\ec89"; }
.ri-firefox-line:before { content: "\ec8a"; }
.ri-flag-2-fill:before { content: "\ec8b"; }
.ri-flag-2-line:before { content: "\ec8c"; }
.ri-flag-fill:before { content: "\ec8d"; }
.ri-flag-line:before { content: "\ec8e"; }
.ri-flashlight-fill:before { content: "\ec8f"; }
.ri-flashlight-line:before { content: "\ec90"; }
.ri-flight-land-fill:before { content: "\ec91"; }
.ri-flight-land-line:before { content: "\ec92"; }
.ri-flight-takeoff-fill:before { content: "\ec93"; }
.ri-flight-takeoff-line:before { content: "\ec94"; }
.ri-focus-2-fill:before { content: "\ec95"; }
.ri-focus-2-line:before { content: "\ec96"; }
.ri-focus-fill:before { content: "\ec97"; }
.ri-focus-line:before { content: "\ec98"; }
.ri-foggy-fill:before { content: "\ec99"; }
.ri-foggy-line:before { content: "\ec9a"; }
.ri-folder-2-fill:before { content: "\ec9b"; }
.ri-folder-2-line:before { content: "\ec9c"; }
.ri-folder-3-fill:before { content: "\ec9d"; }
.ri-folder-3-line:before { content: "\ec9e"; }
.ri-folder-4-fill:before { content: "\ec9f"; }
.ri-folder-4-line:before { content: "\eca0"; }
.ri-folder-5-fill:before { content: "\eca1"; }
.ri-folder-5-line:before { content: "\eca2"; }
.ri-folder-add-fill:before { content: "\eca3"; }
.ri-folder-add-line:before { content: "\eca4"; }
.ri-folder-chart-2-fill:before { content: "\eca5"; }
.ri-folder-chart-2-line:before { content: "\eca6"; }
.ri-folder-chart-fill:before { content: "\eca7"; }
.ri-folder-chart-line:before { content: "\eca8"; }
.ri-folder-download-fill:before { content: "\eca9"; }
.ri-folder-download-line:before { content: "\ecaa"; }
.ri-folder-fill:before { content: "\ecab"; }
.ri-folder-forbid-fill:before { content: "\ecac"; }
.ri-folder-forbid-line:before { content: "\ecad"; }
.ri-folder-info-fill:before { content: "\ecae"; }
.ri-folder-info-line:before { content: "\ecaf"; }
.ri-folder-line:before { content: "\ecb0"; }
.ri-folder-lock-fill:before { content: "\ecb1"; }
.ri-folder-lock-line:before { content: "\ecb2"; }
.ri-folder-music-fill:before { content: "\ecb3"; }
.ri-folder-music-line:before { content: "\ecb4"; }
.ri-folder-received-fill:before { content: "\ecb5"; }
.ri-folder-received-line:before { content: "\ecb6"; }
.ri-folder-reduce-fill:before { content: "\ecb7"; }
.ri-folder-reduce-line:before { content: "\ecb8"; }
.ri-folder-settings-fill:before { content: "\ecb9"; }
.ri-folder-settings-line:before { content: "\ecba"; }
.ri-folder-shared-fill:before { content: "\ecbb"; }
.ri-folder-shared-line:before { content: "\ecbc"; }
.ri-folder-shield-2-fill:before { content: "\ecbd"; }
.ri-folder-shield-2-line:before { content: "\ecbe"; }
.ri-folder-shield-fill:before { content: "\ecbf"; }
.ri-folder-shield-line:before { content: "\ecc0"; }
.ri-folder-transfer-fill:before { content: "\ecc1"; }
.ri-folder-transfer-line:before { content: "\ecc2"; }
.ri-folder-unknow-fill:before { content: "\ecc3"; }
.ri-folder-unknow-line:before { content: "\ecc4"; }
.ri-folder-upload-fill:before { content: "\ecc5"; }
.ri-folder-upload-line:before { content: "\ecc6"; }
.ri-folder-user-fill:before { content: "\ecc7"; }
.ri-folder-user-line:before { content: "\ecc8"; }
.ri-folder-warning-fill:before { content: "\ecc9"; }
.ri-folder-warning-line:before { content: "\ecca"; }
.ri-folders-fill:before { content: "\eccb"; }
.ri-folders-line:before { content: "\eccc"; }
.ri-font-color:before { content: "\eccd"; }
.ri-font-size-2:before { content: "\ecce"; }
.ri-font-size:before { content: "\eccf"; }
.ri-footprint-fill:before { content: "\ecd0"; }
.ri-footprint-line:before { content: "\ecd1"; }
.ri-forbid-2-fill:before { content: "\ecd2"; }
.ri-forbid-2-line:before { content: "\ecd3"; }
.ri-forbid-fill:before { content: "\ecd4"; }
.ri-forbid-line:before { content: "\ecd5"; }
.ri-format-clear:before { content: "\ecd6"; }
.ri-fullscreen-exit-fill:before { content: "\ecd7"; }
.ri-fullscreen-exit-line:before { content: "\ecd8"; }
.ri-fullscreen-fill:before { content: "\ecd9"; }
.ri-fullscreen-line:before { content: "\ecda"; }
.ri-function-fill:before { content: "\ecdb"; }
.ri-function-line:before { content: "\ecdc"; }
.ri-functions:before { content: "\ecdd"; }
.ri-funds-box-fill:before { content: "\ecde"; }
.ri-funds-box-line:before { content: "\ecdf"; }
.ri-funds-fill:before { content: "\ece0"; }
.ri-funds-line:before { content: "\ece1"; }
.ri-gallery-fill:before { content: "\ece2"; }
.ri-gallery-line:before { content: "\ece3"; }
.ri-gas-station-fill:before { content: "\ece4"; }
.ri-gas-station-line:before { content: "\ece5"; }
.ri-genderless-fill:before { content: "\ece6"; }
.ri-genderless-line:before { content: "\ece7"; }
.ri-git-branch-fill:before { content: "\ece8"; }
.ri-git-branch-line:before { content: "\ece9"; }
.ri-git-commit-fill:before { content: "\ecea"; }
.ri-git-commit-line:before { content: "\eceb"; }
.ri-git-merge-fill:before { content: "\ecec"; }
.ri-git-merge-line:before { content: "\eced"; }
.ri-git-pull-request-fill:before { content: "\ecee"; }
.ri-git-pull-request-line:before { content: "\ecef"; }
.ri-git-repository-commits-fill:before { content: "\ecf0"; }
.ri-git-repository-commits-line:before { content: "\ecf1"; }
.ri-git-repository-fill:before { content: "\ecf2"; }
.ri-git-repository-line:before { content: "\ecf3"; }
.ri-git-repository-private-fill:before { content: "\ecf4"; }
.ri-git-repository-private-line:before { content: "\ecf5"; }
.ri-github-fill:before { content: "\ecf6"; }
.ri-github-line:before { content: "\ecf7"; }
.ri-gitlab-fill:before { content: "\ecf8"; }
.ri-gitlab-line:before { content: "\ecf9"; }
.ri-global-fill:before { content: "\ecfa"; }
.ri-global-line:before { content: "\ecfb"; }
.ri-globe-fill:before { content: "\ecfc"; }
.ri-globe-line:before { content: "\ecfd"; }
.ri-goblet-fill:before { content: "\ecfe"; }
.ri-goblet-line:before { content: "\ecff"; }
.ri-google-fill:before { content: "\ed00"; }
.ri-google-line:before { content: "\ed01"; }
.ri-government-fill:before { content: "\ed02"; }
.ri-government-line:before { content: "\ed03"; }
.ri-gps-fill:before { content: "\ed04"; }
.ri-gps-line:before { content: "\ed05"; }
.ri-gradienter-fill:before { content: "\ed06"; }
.ri-gradienter-line:before { content: "\ed07"; }
.ri-grid-fill:before { content: "\ed08"; }
.ri-grid-line:before { content: "\ed09"; }
.ri-group-2-fill:before { content: "\ed0a"; }
.ri-group-2-line:before { content: "\ed0b"; }
.ri-group-fill:before { content: "\ed0c"; }
.ri-group-line:before { content: "\ed0d"; }
.ri-guide-fill:before { content: "\ed0e"; }
.ri-guide-line:before { content: "\ed0f"; }
.ri-hail-fill:before { content: "\ed10"; }
.ri-hail-line:before { content: "\ed11"; }
.ri-hard-drive-2-fill:before { content: "\ed12"; }
.ri-hard-drive-2-line:before { content: "\ed13"; }
.ri-hard-drive-fill:before { content: "\ed14"; }
.ri-hard-drive-line:before { content: "\ed15"; }
.ri-hashtag:before { content: "\ed16"; }
.ri-haze-fill:before { content: "\ed17"; }
.ri-haze-line:before { content: "\ed18"; }
.ri-hd-fill:before { content: "\ed19"; }
.ri-hd-line:before { content: "\ed1a"; }
.ri-heading:before { content: "\ed1b"; }
.ri-headphone-fill:before { content: "\ed1c"; }
.ri-headphone-line:before { content: "\ed1d"; }
.ri-heart-2-fill:before { content: "\ed1e"; }
.ri-heart-2-line:before { content: "\ed1f"; }
.ri-heart-fill:before { content: "\ed20"; }
.ri-heart-line:before { content: "\ed21"; }
.ri-heavy-showers-fill:before { content: "\ed22"; }
.ri-heavy-showers-line:before { content: "\ed23"; }
.ri-home-2-fill:before { content: "\ed24"; }
.ri-home-2-line:before { content: "\ed25"; }
.ri-home-3-fill:before { content: "\ed26"; }
.ri-home-3-line:before { content: "\ed27"; }
.ri-home-4-fill:before { content: "\ed28"; }
.ri-home-4-line:before { content: "\ed29"; }
.ri-home-5-fill:before { content: "\ed2a"; }
.ri-home-5-line:before { content: "\ed2b"; }
.ri-home-6-fill:before { content: "\ed2c"; }
.ri-home-6-line:before { content: "\ed2d"; }
.ri-home-7-fill:before { content: "\ed2e"; }
.ri-home-7-line:before { content: "\ed2f"; }
.ri-home-8-fill:before { content: "\ed30"; }
.ri-home-8-line:before { content: "\ed31"; }
.ri-home-fill:before { content: "\ed32"; }
.ri-home-gear-fill:before { content: "\ed33"; }
.ri-home-gear-line:before { content: "\ed34"; }
.ri-home-heart-fill:before { content: "\ed35"; }
.ri-home-heart-line:before { content: "\ed36"; }
.ri-home-line:before { content: "\ed37"; }
.ri-home-smile-2-fill:before { content: "\ed38"; }
.ri-home-smile-2-line:before { content: "\ed39"; }
.ri-home-smile-fill:before { content: "\ed3a"; }
.ri-home-smile-line:before { content: "\ed3b"; }
.ri-home-wifi-fill:before { content: "\ed3c"; }
.ri-home-wifi-line:before { content: "\ed3d"; }
.ri-honour-fill:before { content: "\ed3e"; }
.ri-honour-line:before { content: "\ed3f"; }
.ri-hospital-fill:before { content: "\ed40"; }
.ri-hospital-line:before { content: "\ed41"; }
.ri-hotel-bed-fill:before { content: "\ed42"; }
.ri-hotel-bed-line:before { content: "\ed43"; }
.ri-hotel-fill:before { content: "\ed44"; }
.ri-hotel-line:before { content: "\ed45"; }
.ri-hq-fill:before { content: "\ed46"; }
.ri-hq-line:before { content: "\ed47"; }
.ri-html5-fill:before { content: "\ed48"; }
.ri-html5-line:before { content: "\ed49"; }
.ri-ie-fill:before { content: "\ed4a"; }
.ri-ie-line:before { content: "\ed4b"; }
.ri-image-2-fill:before { content: "\ed4c"; }
.ri-image-2-line:before { content: "\ed4d"; }
.ri-image-fill:before { content: "\ed4e"; }
.ri-image-line:before { content: "\ed4f"; }
.ri-inbox-archive-fill:before { content: "\ed50"; }
.ri-inbox-archive-line:before { content: "\ed51"; }
.ri-inbox-fill:before { content: "\ed52"; }
.ri-inbox-line:before { content: "\ed53"; }
.ri-increase-decrease-fill:before { content: "\ed54"; }
.ri-increase-decrease-line:before { content: "\ed55"; }
.ri-indent-decrease:before { content: "\ed56"; }
.ri-indent-increase:before { content: "\ed57"; }
.ri-indeterminate-circle-fill:before { content: "\ed58"; }
.ri-indeterminate-circle-line:before { content: "\ed59"; }
.ri-information-fill:before { content: "\ed5a"; }
.ri-information-line:before { content: "\ed5b"; }
.ri-input-method-fill:before { content: "\ed5c"; }
.ri-input-method-line:before { content: "\ed5d"; }
.ri-instagram-fill:before { content: "\ed5e"; }
.ri-instagram-line:before { content: "\ed5f"; }
.ri-invision-fill:before { content: "\ed60"; }
.ri-invision-line:before { content: "\ed61"; }
.ri-italic:before { content: "\ed62"; }
.ri-kakao-talk-fill:before { content: "\ed63"; }
.ri-kakao-talk-line:before { content: "\ed64"; }
.ri-key-2-fill:before { content: "\ed65"; }
.ri-key-2-line:before { content: "\ed66"; }
.ri-key-fill:before { content: "\ed67"; }
.ri-key-line:before { content: "\ed68"; }
.ri-keyboard-box-fill:before { content: "\ed69"; }
.ri-keyboard-box-line:before { content: "\ed6a"; }
.ri-keyboard-fill:before { content: "\ed6b"; }
.ri-keyboard-line:before { content: "\ed6c"; }
.ri-keynote-fill:before { content: "\ed6d"; }
.ri-keynote-line:before { content: "\ed6e"; }
.ri-landscape-fill:before { content: "\ed6f"; }
.ri-landscape-line:before { content: "\ed70"; }
.ri-layout-column-fill:before { content: "\ed71"; }
.ri-layout-column-line:before { content: "\ed72"; }
.ri-layout-fill:before { content: "\ed73"; }
.ri-layout-line:before { content: "\ed74"; }
.ri-layout-row-fill:before { content: "\ed75"; }
.ri-layout-row-line:before { content: "\ed76"; }
.ri-lightbulb-fill:before { content: "\ed77"; }
.ri-lightbulb-flash-fill:before { content: "\ed78"; }
.ri-lightbulb-flash-line:before { content: "\ed79"; }
.ri-lightbulb-line:before { content: "\ed7a"; }
.ri-line-fill:before { content: "\ed7b"; }
.ri-line-height:before { content: "\ed7c"; }
.ri-line-line:before { content: "\ed7d"; }
.ri-link-m:before { content: "\ed7e"; }
.ri-link-unlink-m:before { content: "\ed7f"; }
.ri-link-unlink:before { content: "\ed80"; }
.ri-link:before { content: "\ed81"; }
.ri-linkedin-box-fill:before { content: "\ed82"; }
.ri-linkedin-box-line:before { content: "\ed83"; }
.ri-linkedin-fill:before { content: "\ed84"; }
.ri-linkedin-line:before { content: "\ed85"; }
.ri-links-fill:before { content: "\ed86"; }
.ri-links-line:before { content: "\ed87"; }
.ri-list-check-2:before { content: "\ed88"; }
.ri-list-check:before { content: "\ed89"; }
.ri-list-ordered:before { content: "\ed8a"; }
.ri-list-settings-fill:before { content: "\ed8b"; }
.ri-list-settings-line:before { content: "\ed8c"; }
.ri-list-unordered:before { content: "\ed8d"; }
.ri-loader-2-fill:before { content: "\ed8e"; }
.ri-loader-2-line:before { content: "\ed8f"; }
.ri-loader-3-fill:before { content: "\ed90"; }
.ri-loader-3-line:before { content: "\ed91"; }
.ri-loader-4-fill:before { content: "\ed92"; }
.ri-loader-4-line:before { content: "\ed93"; }
.ri-loader-5-fill:before { content: "\ed94"; }
.ri-loader-5-line:before { content: "\ed95"; }
.ri-loader-fill:before { content: "\ed96"; }
.ri-loader-line:before { content: "\ed97"; }
.ri-lock-2-fill:before { content: "\ed98"; }
.ri-lock-2-line:before { content: "\ed99"; }
.ri-lock-fill:before { content: "\ed9a"; }
.ri-lock-line:before { content: "\ed9b"; }
.ri-lock-password-fill:before { content: "\ed9c"; }
.ri-lock-password-line:before { content: "\ed9d"; }
.ri-lock-unlock-fill:before { content: "\ed9e"; }
.ri-lock-unlock-line:before { content: "\ed9f"; }
.ri-login-box-fill:before { content: "\eda0"; }
.ri-login-box-line:before { content: "\eda1"; }
.ri-login-circle-fill:before { content: "\eda2"; }
.ri-login-circle-line:before { content: "\eda3"; }
.ri-logout-box-fill:before { content: "\eda4"; }
.ri-logout-box-line:before { content: "\eda5"; }
.ri-logout-circle-fill:before { content: "\eda6"; }
.ri-logout-circle-line:before { content: "\eda7"; }
.ri-mac-fill:before { content: "\eda8"; }
.ri-mac-line:before { content: "\eda9"; }
.ri-macbook-fill:before { content: "\edaa"; }
.ri-macbook-line:before { content: "\edab"; }
.ri-magic-fill:before { content: "\edac"; }
.ri-magic-line:before { content: "\edad"; }
.ri-mail-add-fill:before { content: "\edae"; }
.ri-mail-add-line:before { content: "\edaf"; }
.ri-mail-check-fill:before { content: "\edb0"; }
.ri-mail-check-line:before { content: "\edb1"; }
.ri-mail-close-fill:before { content: "\edb2"; }
.ri-mail-close-line:before { content: "\edb3"; }
.ri-mail-download-fill:before { content: "\edb4"; }
.ri-mail-download-line:before { content: "\edb5"; }
.ri-mail-fill:before { content: "\edb6"; }
.ri-mail-forbid-fill:before { content: "\edb7"; }
.ri-mail-forbid-line:before { content: "\edb8"; }
.ri-mail-line:before { content: "\edb9"; }
.ri-mail-lock-fill:before { content: "\edba"; }
.ri-mail-lock-line:before { content: "\edbb"; }
.ri-mail-open-fill:before { content: "\edbc"; }
.ri-mail-open-line:before { content: "\edbd"; }
.ri-mail-send-fill:before { content: "\edbe"; }
.ri-mail-send-line:before { content: "\edbf"; }
.ri-mail-settings-fill:before { content: "\edc0"; }
.ri-mail-settings-line:before { content: "\edc1"; }
.ri-mail-star-fill:before { content: "\edc2"; }
.ri-mail-star-line:before { content: "\edc3"; }
.ri-mail-unread-fill:before { content: "\edc4"; }
.ri-mail-unread-line:before { content: "\edc5"; }
.ri-map-2-fill:before { content: "\edc6"; }
.ri-map-2-line:before { content: "\edc7"; }
.ri-map-fill:before { content: "\edc8"; }
.ri-map-line:before { content: "\edc9"; }
.ri-map-pin-2-fill:before { content: "\edca"; }
.ri-map-pin-2-line:before { content: "\edcb"; }
.ri-map-pin-3-fill:before { content: "\edcc"; }
.ri-map-pin-3-line:before { content: "\edcd"; }
.ri-map-pin-4-fill:before { content: "\edce"; }
.ri-map-pin-4-line:before { content: "\edcf"; }
.ri-map-pin-5-fill:before { content: "\edd0"; }
.ri-map-pin-5-line:before { content: "\edd1"; }
.ri-map-pin-add-fill:before { content: "\edd2"; }
.ri-map-pin-add-line:before { content: "\edd3"; }
.ri-map-pin-fill:before { content: "\edd4"; }
.ri-map-pin-line:before { content: "\edd5"; }
.ri-map-pin-range-fill:before { content: "\edd6"; }
.ri-map-pin-range-line:before { content: "\edd7"; }
.ri-map-pin-time-fill:before { content: "\edd8"; }
.ri-map-pin-time-line:before { content: "\edd9"; }
.ri-map-pin-user-fill:before { content: "\edda"; }
.ri-map-pin-user-line:before { content: "\eddb"; }
.ri-mark-pen-fill:before { content: "\eddc"; }
.ri-mark-pen-line:before { content: "\eddd"; }
.ri-markdown-fill:before { content: "\edde"; }
.ri-markdown-line:before { content: "\eddf"; }
.ri-markup-fill:before { content: "\ede0"; }
.ri-markup-line:before { content: "\ede1"; }
.ri-mastercard-fill:before { content: "\ede2"; }
.ri-mastercard-line:before { content: "\ede3"; }
.ri-mastodon-fill:before { content: "\ede4"; }
.ri-mastodon-line:before { content: "\ede5"; }
.ri-medium-fill:before { content: "\ede6"; }
.ri-medium-line:before { content: "\ede7"; }
.ri-men-fill:before { content: "\ede8"; }
.ri-men-line:before { content: "\ede9"; }
.ri-menu-2-fill:before { content: "\edea"; }
.ri-menu-2-line:before { content: "\edeb"; }
.ri-menu-3-fill:before { content: "\edec"; }
.ri-menu-3-line:before { content: "\eded"; }
.ri-menu-fill:before { content: "\edee"; }
.ri-menu-line:before { content: "\edef"; }
.ri-message-2-fill:before { content: "\edf0"; }
.ri-message-2-line:before { content: "\edf1"; }
.ri-message-3-fill:before { content: "\edf2"; }
.ri-message-3-line:before { content: "\edf3"; }
.ri-message-fill:before { content: "\edf4"; }
.ri-message-line:before { content: "\edf5"; }
.ri-messenger-fill:before { content: "\edf6"; }
.ri-messenger-line:before { content: "\edf7"; }
.ri-mic-2-fill:before { content: "\edf8"; }
.ri-mic-2-line:before { content: "\edf9"; }
.ri-mic-fill:before { content: "\edfa"; }
.ri-mic-line:before { content: "\edfb"; }
.ri-mic-off-fill:before { content: "\edfc"; }
.ri-mic-off-line:before { content: "\edfd"; }
.ri-mini-program-fill:before { content: "\edfe"; }
.ri-mini-program-line:before { content: "\edff"; }
.ri-mist-fill:before { content: "\ee00"; }
.ri-mist-line:before { content: "\ee01"; }
.ri-money-cny-box-fill:before { content: "\ee02"; }
.ri-money-cny-box-line:before { content: "\ee03"; }
.ri-money-cny-circle-fill:before { content: "\ee04"; }
.ri-money-cny-circle-line:before { content: "\ee05"; }
.ri-money-dollar-box-fill:before { content: "\ee06"; }
.ri-money-dollar-box-line:before { content: "\ee07"; }
.ri-money-dollar-circle-fill:before { content: "\ee08"; }
.ri-money-dollar-circle-line:before { content: "\ee09"; }
.ri-money-euro-box-fill:before { content: "\ee0a"; }
.ri-money-euro-box-line:before { content: "\ee0b"; }
.ri-money-euro-circle-fill:before { content: "\ee0c"; }
.ri-money-euro-circle-line:before { content: "\ee0d"; }
.ri-money-pound-box-fill:before { content: "\ee0e"; }
.ri-money-pound-box-line:before { content: "\ee0f"; }
.ri-money-pound-circle-fill:before { content: "\ee10"; }
.ri-money-pound-circle-line:before { content: "\ee11"; }
.ri-moon-clear-fill:before { content: "\ee12"; }
.ri-moon-clear-line:before { content: "\ee13"; }
.ri-moon-cloudy-fill:before { content: "\ee14"; }
.ri-moon-cloudy-line:before { content: "\ee15"; }
.ri-moon-fill:before { content: "\ee16"; }
.ri-moon-foggy-fill:before { content: "\ee17"; }
.ri-moon-foggy-line:before { content: "\ee18"; }
.ri-moon-line:before { content: "\ee19"; }
.ri-more-2-fill:before { content: "\ee1a"; }
.ri-more-2-line:before { content: "\ee1b"; }
.ri-more-fill:before { content: "\ee1c"; }
.ri-more-line:before { content: "\ee1d"; }
.ri-motorbike-fill:before { content: "\ee1e"; }
.ri-motorbike-line:before { content: "\ee1f"; }
.ri-mouse-fill:before { content: "\ee20"; }
.ri-mouse-line:before { content: "\ee21"; }
.ri-movie-2-fill:before { content: "\ee22"; }
.ri-movie-2-line:before { content: "\ee23"; }
.ri-movie-fill:before { content: "\ee24"; }
.ri-movie-line:before { content: "\ee25"; }
.ri-music-2-fill:before { content: "\ee26"; }
.ri-music-2-line:before { content: "\ee27"; }
.ri-music-fill:before { content: "\ee28"; }
.ri-music-line:before { content: "\ee29"; }
.ri-mv-fill:before { content: "\ee2a"; }
.ri-mv-line:before { content: "\ee2b"; }
.ri-navigation-fill:before { content: "\ee2c"; }
.ri-navigation-line:before { content: "\ee2d"; }
.ri-netease-cloud-music-fill:before { content: "\ee2e"; }
.ri-netease-cloud-music-line:before { content: "\ee2f"; }
.ri-netflix-fill:before { content: "\ee30"; }
.ri-netflix-line:before { content: "\ee31"; }
.ri-newspaper-fill:before { content: "\ee32"; }
.ri-newspaper-line:before { content: "\ee33"; }
.ri-notification-2-fill:before { content: "\ee34"; }
.ri-notification-2-line:before { content: "\ee35"; }
.ri-notification-3-fill:before { content: "\ee36"; }
.ri-notification-3-line:before { content: "\ee37"; }
.ri-notification-4-fill:before { content: "\ee38"; }
.ri-notification-4-line:before { content: "\ee39"; }
.ri-notification-badge-fill:before { content: "\ee3a"; }
.ri-notification-badge-line:before { content: "\ee3b"; }
.ri-notification-fill:before { content: "\ee3c"; }
.ri-notification-line:before { content: "\ee3d"; }
.ri-notification-off-fill:before { content: "\ee3e"; }
.ri-notification-off-line:before { content: "\ee3f"; }
.ri-numbers-fill:before { content: "\ee40"; }
.ri-numbers-line:before { content: "\ee41"; }
.ri-oil-fill:before { content: "\ee42"; }
.ri-oil-line:before { content: "\ee43"; }
.ri-omega:before { content: "\ee44"; }
.ri-open-arm-fill:before { content: "\ee45"; }
.ri-open-arm-line:before { content: "\ee46"; }
.ri-opera-fill:before { content: "\ee47"; }
.ri-opera-line:before { content: "\ee48"; }
.ri-order-play-fill:before { content: "\ee49"; }
.ri-order-play-line:before { content: "\ee4a"; }
.ri-outlet-2-fill:before { content: "\ee4b"; }
.ri-outlet-2-line:before { content: "\ee4c"; }
.ri-outlet-fill:before { content: "\ee4d"; }
.ri-outlet-line:before { content: "\ee4e"; }
.ri-page-separator:before { content: "\ee4f"; }
.ri-pages-fill:before { content: "\ee50"; }
.ri-pages-line:before { content: "\ee51"; }
.ri-paint-brush-fill:before { content: "\ee52"; }
.ri-paint-brush-line:before { content: "\ee53"; }
.ri-paint-fill:before { content: "\ee54"; }
.ri-paint-line:before { content: "\ee55"; }
.ri-palette-fill:before { content: "\ee56"; }
.ri-palette-line:before { content: "\ee57"; }
.ri-pantone-fill:before { content: "\ee58"; }
.ri-pantone-line:before { content: "\ee59"; }
.ri-paragraph:before { content: "\ee5a"; }
.ri-parent-fill:before { content: "\ee5b"; }
.ri-parent-line:before { content: "\ee5c"; }
.ri-parentheses-fill:before { content: "\ee5d"; }
.ri-parentheses-line:before { content: "\ee5e"; }
.ri-parking-box-fill:before { content: "\ee5f"; }
.ri-parking-box-line:before { content: "\ee60"; }
.ri-parking-fill:before { content: "\ee61"; }
.ri-parking-line:before { content: "\ee62"; }
.ri-patreon-fill:before { content: "\ee63"; }
.ri-patreon-line:before { content: "\ee64"; }
.ri-pause-circle-fill:before { content: "\ee65"; }
.ri-pause-circle-line:before { content: "\ee66"; }
.ri-pause-fill:before { content: "\ee67"; }
.ri-pause-line:before { content: "\ee68"; }
.ri-pause-mini-fill:before { content: "\ee69"; }
.ri-pause-mini-line:before { content: "\ee6a"; }
.ri-paypal-fill:before { content: "\ee6b"; }
.ri-paypal-line:before { content: "\ee6c"; }
.ri-pen-nib-fill:before { content: "\ee6d"; }
.ri-pen-nib-line:before { content: "\ee6e"; }
.ri-pencil-fill:before { content: "\ee6f"; }
.ri-pencil-line:before { content: "\ee70"; }
.ri-pencil-ruler-2-fill:before { content: "\ee71"; }
.ri-pencil-ruler-2-line:before { content: "\ee72"; }
.ri-pencil-ruler-fill:before { content: "\ee73"; }
.ri-pencil-ruler-line:before { content: "\ee74"; }
.ri-percent-fill:before { content: "\ee75"; }
.ri-percent-line:before { content: "\ee76"; }
.ri-phone-camera-fill:before { content: "\ee77"; }
.ri-phone-camera-line:before { content: "\ee78"; }
.ri-phone-fill:before { content: "\ee79"; }
.ri-phone-line:before { content: "\ee7a"; }
.ri-pie-chart-2-fill:before { content: "\ee7b"; }
.ri-pie-chart-2-line:before { content: "\ee7c"; }
.ri-pie-chart-box-fill:before { content: "\ee7d"; }
.ri-pie-chart-box-line:before { content: "\ee7e"; }
.ri-pie-chart-fill:before { content: "\ee7f"; }
.ri-pie-chart-line:before { content: "\ee80"; }
.ri-pin-distance-fill:before { content: "\ee81"; }
.ri-pin-distance-line:before { content: "\ee82"; }
.ri-pinterest-fill:before { content: "\ee83"; }
.ri-pinterest-line:before { content: "\ee84"; }
.ri-plane-fill:before { content: "\ee85"; }
.ri-plane-line:before { content: "\ee86"; }
.ri-play-circle-fill:before { content: "\ee87"; }
.ri-play-circle-line:before { content: "\ee88"; }
.ri-play-fill:before { content: "\ee89"; }
.ri-play-line:before { content: "\ee8a"; }
.ri-play-list-add-fill:before { content: "\ee8b"; }
.ri-play-list-add-line:before { content: "\ee8c"; }
.ri-play-list-fill:before { content: "\ee8d"; }
.ri-play-list-line:before { content: "\ee8e"; }
.ri-play-mini-fill:before { content: "\ee8f"; }
.ri-play-mini-line:before { content: "\ee90"; }
.ri-playstation-fill:before { content: "\ee91"; }
.ri-playstation-line:before { content: "\ee92"; }
.ri-plug-2-fill:before { content: "\ee93"; }
.ri-plug-2-line:before { content: "\ee94"; }
.ri-plug-fill:before { content: "\ee95"; }
.ri-plug-line:before { content: "\ee96"; }
.ri-polaroid-2-fill:before { content: "\ee97"; }
.ri-polaroid-2-line:before { content: "\ee98"; }
.ri-polaroid-fill:before { content: "\ee99"; }
.ri-polaroid-line:before { content: "\ee9a"; }
.ri-police-car-fill:before { content: "\ee9b"; }
.ri-police-car-line:before { content: "\ee9c"; }
.ri-price-tag-2-fill:before { content: "\ee9d"; }
.ri-price-tag-2-line:before { content: "\ee9e"; }
.ri-price-tag-3-fill:before { content: "\ee9f"; }
.ri-price-tag-3-line:before { content: "\eea0"; }
.ri-price-tag-fill:before { content: "\eea1"; }
.ri-price-tag-line:before { content: "\eea2"; }
.ri-printer-fill:before { content: "\eea3"; }
.ri-printer-line:before { content: "\eea4"; }
.ri-product-hunt-fill:before { content: "\eea5"; }
.ri-product-hunt-line:before { content: "\eea6"; }
.ri-profile-fill:before { content: "\eea7"; }
.ri-profile-line:before { content: "\eea8"; }
.ri-projector-2-fill:before { content: "\eea9"; }
.ri-projector-2-line:before { content: "\eeaa"; }
.ri-projector-fill:before { content: "\eeab"; }
.ri-projector-line:before { content: "\eeac"; }
.ri-qq-fill:before { content: "\eead"; }
.ri-qq-line:before { content: "\eeae"; }
.ri-qr-code-fill:before { content: "\eeaf"; }
.ri-qr-code-line:before { content: "\eeb0"; }
.ri-qr-scan-2-fill:before { content: "\eeb1"; }
.ri-qr-scan-2-line:before { content: "\eeb2"; }
.ri-qr-scan-fill:before { content: "\eeb3"; }
.ri-qr-scan-line:before { content: "\eeb4"; }
.ri-question-answer-fill:before { content: "\eeb5"; }
.ri-question-answer-line:before { content: "\eeb6"; }
.ri-question-fill:before { content: "\eeb7"; }
.ri-question-line:before { content: "\eeb8"; }
.ri-questionnaire-fill:before { content: "\eeb9"; }
.ri-questionnaire-line:before { content: "\eeba"; }
.ri-quill-pen-fill:before { content: "\eebb"; }
.ri-quill-pen-line:before { content: "\eebc"; }
.ri-radar-fill:before { content: "\eebd"; }
.ri-radar-line:before { content: "\eebe"; }
.ri-radio-2-fill:before { content: "\eebf"; }
.ri-radio-2-line:before { content: "\eec0"; }
.ri-radio-button-fill:before { content: "\eec1"; }
.ri-radio-button-line:before { content: "\eec2"; }
.ri-radio-fill:before { content: "\eec3"; }
.ri-radio-line:before { content: "\eec4"; }
.ri-rainy-fill:before { content: "\eec5"; }
.ri-rainy-line:before { content: "\eec6"; }
.ri-reactjs-fill:before { content: "\eec7"; }
.ri-reactjs-line:before { content: "\eec8"; }
.ri-record-circle-fill:before { content: "\eec9"; }
.ri-record-circle-line:before { content: "\eeca"; }
.ri-record-mail-fill:before { content: "\eecb"; }
.ri-record-mail-line:before { content: "\eecc"; }
.ri-red-packet-fill:before { content: "\eecd"; }
.ri-red-packet-line:before { content: "\eece"; }
.ri-reddit-fill:before { content: "\eecf"; }
.ri-reddit-line:before { content: "\eed0"; }
.ri-refresh-fill:before { content: "\eed1"; }
.ri-refresh-line:before { content: "\eed2"; }
.ri-refund-fill:before { content: "\eed3"; }
.ri-refund-line:before { content: "\eed4"; }
.ri-remixicon-fill:before { content: "\eed5"; }
.ri-remixicon-line:before { content: "\eed6"; }
.ri-repeat-2-fill:before { content: "\eed7"; }
.ri-repeat-2-line:before { content: "\eed8"; }
.ri-repeat-fill:before { content: "\eed9"; }
.ri-repeat-line:before { content: "\eeda"; }
.ri-repeat-one-fill:before { content: "\eedb"; }
.ri-repeat-one-line:before { content: "\eedc"; }
.ri-reply-fill:before { content: "\eedd"; }
.ri-reply-line:before { content: "\eede"; }
.ri-reserved-fill:before { content: "\eedf"; }
.ri-reserved-line:before { content: "\eee0"; }
.ri-restart-fill:before { content: "\eee1"; }
.ri-restart-line:before { content: "\eee2"; }
.ri-restaurant-2-fill:before { content: "\eee3"; }
.ri-restaurant-2-line:before { content: "\eee4"; }
.ri-restaurant-fill:before { content: "\eee5"; }
.ri-restaurant-line:before { content: "\eee6"; }
.ri-rewind-fill:before { content: "\eee7"; }
.ri-rewind-line:before { content: "\eee8"; }
.ri-rewind-mini-fill:before { content: "\eee9"; }
.ri-rewind-mini-line:before { content: "\eeea"; }
.ri-rhythm-fill:before { content: "\eeeb"; }
.ri-rhythm-line:before { content: "\eeec"; }
.ri-riding-fill:before { content: "\eeed"; }
.ri-riding-line:before { content: "\eeee"; }
.ri-road-map-fill:before { content: "\eeef"; }
.ri-road-map-line:before { content: "\eef0"; }
.ri-roadster-fill:before { content: "\eef1"; }
.ri-roadster-line:before { content: "\eef2"; }
.ri-robot-fill:before { content: "\eef3"; }
.ri-robot-line:before { content: "\eef4"; }
.ri-rocket-2-fill:before { content: "\eef5"; }
.ri-rocket-2-line:before { content: "\eef6"; }
.ri-rocket-fill:before { content: "\eef7"; }
.ri-rocket-line:before { content: "\eef8"; }
.ri-route-fill:before { content: "\eef9"; }
.ri-route-line:before { content: "\eefa"; }
.ri-router-fill:before { content: "\eefb"; }
.ri-router-line:before { content: "\eefc"; }
.ri-rss-fill:before { content: "\eefd"; }
.ri-rss-line:before { content: "\eefe"; }
.ri-ruler-2-fill:before { content: "\eeff"; }
.ri-ruler-2-line:before { content: "\ef00"; }
.ri-ruler-fill:before { content: "\ef01"; }
.ri-ruler-line:before { content: "\ef02"; }
.ri-run-fill:before { content: "\ef03"; }
.ri-run-line:before { content: "\ef04"; }
.ri-safari-fill:before { content: "\ef05"; }
.ri-safari-line:before { content: "\ef06"; }
.ri-safe-2-fill:before { content: "\ef07"; }
.ri-safe-2-line:before { content: "\ef08"; }
.ri-safe-fill:before { content: "\ef09"; }
.ri-safe-line:before { content: "\ef0a"; }
.ri-sailboat-fill:before { content: "\ef0b"; }
.ri-sailboat-line:before { content: "\ef0c"; }
.ri-save-2-fill:before { content: "\ef0d"; }
.ri-save-2-line:before { content: "\ef0e"; }
.ri-save-3-fill:before { content: "\ef0f"; }
.ri-save-3-line:before { content: "\ef10"; }
.ri-save-fill:before { content: "\ef11"; }
.ri-save-line:before { content: "\ef12"; }
.ri-scan-2-fill:before { content: "\ef13"; }
.ri-scan-2-line:before { content: "\ef14"; }
.ri-scan-fill:before { content: "\ef15"; }
.ri-scan-line:before { content: "\ef16"; }
.ri-scissors-2-fill:before { content: "\ef17"; }
.ri-scissors-2-line:before { content: "\ef18"; }
.ri-scissors-cut-fill:before { content: "\ef19"; }
.ri-scissors-cut-line:before { content: "\ef1a"; }
.ri-scissors-fill:before { content: "\ef1b"; }
.ri-scissors-line:before { content: "\ef1c"; }
.ri-screenshot-2-fill:before { content: "\ef1d"; }
.ri-screenshot-2-line:before { content: "\ef1e"; }
.ri-screenshot-fill:before { content: "\ef1f"; }
.ri-screenshot-line:before { content: "\ef20"; }
.ri-sd-card-fill:before { content: "\ef21"; }
.ri-sd-card-line:before { content: "\ef22"; }
.ri-sd-card-mini-fill:before { content: "\ef23"; }
.ri-sd-card-mini-line:before { content: "\ef24"; }
.ri-search-2-fill:before { content: "\ef25"; }
.ri-search-2-line:before { content: "\ef26"; }
.ri-search-eye-fill:before { content: "\ef27"; }
.ri-search-eye-line:before { content: "\ef28"; }
.ri-search-fill:before { content: "\ef29"; }
.ri-search-line:before { content: "\ef2a"; }
.ri-send-plane-2-fill:before { content: "\ef2b"; }
.ri-send-plane-2-line:before { content: "\ef2c"; }
.ri-send-plane-fill:before { content: "\ef2d"; }
.ri-send-plane-line:before { content: "\ef2e"; }
.ri-sensor-fill:before { content: "\ef2f"; }
.ri-sensor-line:before { content: "\ef30"; }
.ri-separator:before { content: "\ef31"; }
.ri-server-fill:before { content: "\ef32"; }
.ri-server-line:before { content: "\ef33"; }
.ri-settings-2-fill:before { content: "\ef34"; }
.ri-settings-2-line:before { content: "\ef35"; }
.ri-settings-3-fill:before { content: "\ef36"; }
.ri-settings-3-line:before { content: "\ef37"; }
.ri-settings-4-fill:before { content: "\ef38"; }
.ri-settings-4-line:before { content: "\ef39"; }
.ri-settings-5-fill:before { content: "\ef3a"; }
.ri-settings-5-line:before { content: "\ef3b"; }
.ri-settings-6-fill:before { content: "\ef3c"; }
.ri-settings-6-line:before { content: "\ef3d"; }
.ri-settings-fill:before { content: "\ef3e"; }
.ri-settings-line:before { content: "\ef3f"; }
.ri-shape-2-fill:before { content: "\ef40"; }
.ri-shape-2-line:before { content: "\ef41"; }
.ri-shape-fill:before { content: "\ef42"; }
.ri-shape-line:before { content: "\ef43"; }
.ri-share-box-fill:before { content: "\ef44"; }
.ri-share-box-line:before { content: "\ef45"; }
.ri-share-circle-fill:before { content: "\ef46"; }
.ri-share-circle-line:before { content: "\ef47"; }
.ri-share-fill:before { content: "\ef48"; }
.ri-share-forward-2-fill:before { content: "\ef49"; }
.ri-share-forward-2-line:before { content: "\ef4a"; }
.ri-share-forward-box-fill:before { content: "\ef4b"; }
.ri-share-forward-box-line:before { content: "\ef4c"; }
.ri-share-forward-fill:before { content: "\ef4d"; }
.ri-share-forward-line:before { content: "\ef4e"; }
.ri-share-line:before { content: "\ef4f"; }
.ri-shield-cross-fill:before { content: "\ef50"; }
.ri-shield-cross-line:before { content: "\ef51"; }
.ri-shield-fill:before { content: "\ef52"; }
.ri-shield-flash-fill:before { content: "\ef53"; }
.ri-shield-flash-line:before { content: "\ef54"; }
.ri-shield-line:before { content: "\ef55"; }
.ri-shield-star-fill:before { content: "\ef56"; }
.ri-shield-star-line:before { content: "\ef57"; }
.ri-shield-user-fill:before { content: "\ef58"; }
.ri-shield-user-line:before { content: "\ef59"; }
.ri-ship-2-fill:before { content: "\ef5a"; }
.ri-ship-2-line:before { content: "\ef5b"; }
.ri-ship-fill:before { content: "\ef5c"; }
.ri-ship-line:before { content: "\ef5d"; }
.ri-shirt-fill:before { content: "\ef5e"; }
.ri-shirt-line:before { content: "\ef5f"; }
.ri-shopping-bag-2-fill:before { content: "\ef60"; }
.ri-shopping-bag-2-line:before { content: "\ef61"; }
.ri-shopping-bag-3-fill:before { content: "\ef62"; }
.ri-shopping-bag-3-line:before { content: "\ef63"; }
.ri-shopping-bag-fill:before { content: "\ef64"; }
.ri-shopping-bag-line:before { content: "\ef65"; }
.ri-shopping-cart-2-fill:before { content: "\ef66"; }
.ri-shopping-cart-2-line:before { content: "\ef67"; }
.ri-shopping-cart-fill:before { content: "\ef68"; }
.ri-shopping-cart-line:before { content: "\ef69"; }
.ri-showers-fill:before { content: "\ef6a"; }
.ri-showers-line:before { content: "\ef6b"; }
.ri-shuffle-fill:before { content: "\ef6c"; }
.ri-shuffle-line:before { content: "\ef6d"; }
.ri-shut-down-fill:before { content: "\ef6e"; }
.ri-shut-down-line:before { content: "\ef6f"; }
.ri-side-bar-fill:before { content: "\ef70"; }
.ri-side-bar-line:before { content: "\ef71"; }
.ri-signal-tower-fill:before { content: "\ef72"; }
.ri-signal-tower-line:before { content: "\ef73"; }
.ri-sim-card-2-fill:before { content: "\ef74"; }
.ri-sim-card-2-line:before { content: "\ef75"; }
.ri-sim-card-fill:before { content: "\ef76"; }
.ri-sim-card-line:before { content: "\ef77"; }
.ri-single-quotes-l:before { content: "\ef78"; }
.ri-single-quotes-r:before { content: "\ef79"; }
.ri-sip-fill:before { content: "\ef7a"; }
.ri-sip-line:before { content: "\ef7b"; }
.ri-skip-back-fill:before { content: "\ef7c"; }
.ri-skip-back-line:before { content: "\ef7d"; }
.ri-skip-back-mini-fill:before { content: "\ef7e"; }
.ri-skip-back-mini-line:before { content: "\ef7f"; }
.ri-skip-forward-fill:before { content: "\ef80"; }
.ri-skip-forward-line:before { content: "\ef81"; }
.ri-skip-forward-mini-fill:before { content: "\ef82"; }
.ri-skip-forward-mini-line:before { content: "\ef83"; }
.ri-skull-fill:before { content: "\ef84"; }
.ri-skull-line:before { content: "\ef85"; }
.ri-skype-fill:before { content: "\ef86"; }
.ri-skype-line:before { content: "\ef87"; }
.ri-slack-fill:before { content: "\ef88"; }
.ri-slack-line:before { content: "\ef89"; }
.ri-slice-fill:before { content: "\ef8a"; }
.ri-slice-line:before { content: "\ef8b"; }
.ri-slideshow-2-fill:before { content: "\ef8c"; }
.ri-slideshow-2-line:before { content: "\ef8d"; }
.ri-slideshow-3-fill:before { content: "\ef8e"; }
.ri-slideshow-3-line:before { content: "\ef8f"; }
.ri-slideshow-4-fill:before { content: "\ef90"; }
.ri-slideshow-4-line:before { content: "\ef91"; }
.ri-slideshow-fill:before { content: "\ef92"; }
.ri-slideshow-line:before { content: "\ef93"; }
.ri-smartphone-fill:before { content: "\ef94"; }
.ri-smartphone-line:before { content: "\ef95"; }
.ri-snapchat-fill:before { content: "\ef96"; }
.ri-snapchat-line:before { content: "\ef97"; }
.ri-snowy-fill:before { content: "\ef98"; }
.ri-snowy-line:before { content: "\ef99"; }
.ri-sound-module-fill:before { content: "\ef9a"; }
.ri-sound-module-line:before { content: "\ef9b"; }
.ri-space-ship-fill:before { content: "\ef9c"; }
.ri-space-ship-line:before { content: "\ef9d"; }
.ri-space:before { content: "\ef9e"; }
.ri-spam-2-fill:before { content: "\ef9f"; }
.ri-spam-2-line:before { content: "\efa0"; }
.ri-spam-3-fill:before { content: "\efa1"; }
.ri-spam-3-line:before { content: "\efa2"; }
.ri-spam-fill:before { content: "\efa3"; }
.ri-spam-line:before { content: "\efa4"; }
.ri-speaker-2-fill:before { content: "\efa5"; }
.ri-speaker-2-line:before { content: "\efa6"; }
.ri-speaker-3-fill:before { content: "\efa7"; }
.ri-speaker-3-line:before { content: "\efa8"; }
.ri-speaker-fill:before { content: "\efa9"; }
.ri-speaker-line:before { content: "\efaa"; }
.ri-speed-fill:before { content: "\efab"; }
.ri-speed-line:before { content: "\efac"; }
.ri-speed-mini-fill:before { content: "\efad"; }
.ri-speed-mini-line:before { content: "\efae"; }
.ri-spotify-fill:before { content: "\efaf"; }
.ri-spotify-line:before { content: "\efb0"; }
.ri-stack-fill:before { content: "\efb1"; }
.ri-stack-line:before { content: "\efb2"; }
.ri-stack-overflow-fill:before { content: "\efb3"; }
.ri-stack-overflow-line:before { content: "\efb4"; }
.ri-star-fill:before { content: "\efb5"; }
.ri-star-half-fill:before { content: "\efb6"; }
.ri-star-half-line:before { content: "\efb7"; }
.ri-star-half-s-fill:before { content: "\efb8"; }
.ri-star-half-s-line:before { content: "\efb9"; }
.ri-star-line:before { content: "\efba"; }
.ri-star-s-fill:before { content: "\efbb"; }
.ri-star-s-line:before { content: "\efbc"; }
.ri-stock-fill:before { content: "\efbd"; }
.ri-stock-line:before { content: "\efbe"; }
.ri-stop-circle-fill:before { content: "\efbf"; }
.ri-stop-circle-line:before { content: "\efc0"; }
.ri-stop-fill:before { content: "\efc1"; }
.ri-stop-line:before { content: "\efc2"; }
.ri-stop-mini-fill:before { content: "\efc3"; }
.ri-stop-mini-line:before { content: "\efc4"; }
.ri-store-2-fill:before { content: "\efc5"; }
.ri-store-2-line:before { content: "\efc6"; }
.ri-store-3-fill:before { content: "\efc7"; }
.ri-store-3-line:before { content: "\efc8"; }
.ri-store-fill:before { content: "\efc9"; }
.ri-store-line:before { content: "\efca"; }
.ri-strikethrough-2:before { content: "\efcb"; }
.ri-strikethrough:before { content: "\efcc"; }
.ri-subscript-2:before { content: "\efcd"; }
.ri-subscript:before { content: "\efce"; }
.ri-subtract-fill:before { content: "\efcf"; }
.ri-subtract-line:before { content: "\efd0"; }
.ri-subway-fill:before { content: "\efd1"; }
.ri-subway-line:before { content: "\efd2"; }
.ri-sun-cloudy-fill:before { content: "\efd3"; }
.ri-sun-cloudy-line:before { content: "\efd4"; }
.ri-sun-fill:before { content: "\efd5"; }
.ri-sun-foggy-fill:before { content: "\efd6"; }
.ri-sun-foggy-line:before { content: "\efd7"; }
.ri-sun-line:before { content: "\efd8"; }
.ri-superscript-2:before { content: "\efd9"; }
.ri-superscript:before { content: "\efda"; }
.ri-surround-sound-fill:before { content: "\efdb"; }
.ri-surround-sound-line:before { content: "\efdc"; }
.ri-swap-box-fill:before { content: "\efdd"; }
.ri-swap-box-line:before { content: "\efde"; }
.ri-swap-fill:before { content: "\efdf"; }
.ri-swap-line:before { content: "\efe0"; }
.ri-switch-fill:before { content: "\efe1"; }
.ri-switch-line:before { content: "\efe2"; }
.ri-t-box-fill:before { content: "\efe3"; }
.ri-t-box-line:before { content: "\efe4"; }
.ri-t-shirt-fill:before { content: "\efe5"; }
.ri-t-shirt-line:before { content: "\efe6"; }
.ri-table-2:before { content: "\efe7"; }
.ri-table-fill:before { content: "\efe8"; }
.ri-table-line:before { content: "\efe9"; }
.ri-tablet-fill:before { content: "\efea"; }
.ri-tablet-line:before { content: "\efeb"; }
.ri-taobao-fill:before { content: "\efec"; }
.ri-taobao-line:before { content: "\efed"; }
.ri-tape-fill:before { content: "\efee"; }
.ri-tape-line:before { content: "\efef"; }
.ri-task-fill:before { content: "\eff0"; }
.ri-task-line:before { content: "\eff1"; }
.ri-taxi-fill:before { content: "\eff2"; }
.ri-taxi-line:before { content: "\eff3"; }
.ri-telegram-fill:before { content: "\eff4"; }
.ri-telegram-line:before { content: "\eff5"; }
.ri-temp-cold-fill:before { content: "\eff6"; }
.ri-temp-cold-line:before { content: "\eff7"; }
.ri-temp-hot-fill:before { content: "\eff8"; }
.ri-temp-hot-line:before { content: "\eff9"; }
.ri-terminal-box-fill:before { content: "\effa"; }
.ri-terminal-box-line:before { content: "\effb"; }
.ri-terminal-fill:before { content: "\effc"; }
.ri-terminal-line:before { content: "\effd"; }
.ri-terminal-window-fill:before { content: "\effe"; }
.ri-terminal-window-line:before { content: "\efff"; }
.ri-text-direction-l:before { content: "\f000"; }
.ri-text-direction-r:before { content: "\f001"; }
.ri-text-spacing:before { content: "\f002"; }
.ri-text-wrap:before { content: "\f003"; }
.ri-text:before { content: "\f004"; }
.ri-thumb-down-fill:before { content: "\f005"; }
.ri-thumb-down-line:before { content: "\f006"; }
.ri-thumb-up-fill:before { content: "\f007"; }
.ri-thumb-up-line:before { content: "\f008"; }
.ri-thunderstorms-fill:before { content: "\f009"; }
.ri-thunderstorms-line:before { content: "\f00a"; }
.ri-time-fill:before { content: "\f00b"; }
.ri-time-line:before { content: "\f00c"; }
.ri-timer-2-fill:before { content: "\f00d"; }
.ri-timer-2-line:before { content: "\f00e"; }
.ri-timer-fill:before { content: "\f00f"; }
.ri-timer-flash-fill:before { content: "\f010"; }
.ri-timer-flash-line:before { content: "\f011"; }
.ri-timer-line:before { content: "\f012"; }
.ri-todo-fill:before { content: "\f013"; }
.ri-todo-line:before { content: "\f014"; }
.ri-toggle-fill:before { content: "\f015"; }
.ri-toggle-line:before { content: "\f016"; }
.ri-tornado-fill:before { content: "\f017"; }
.ri-tornado-line:before { content: "\f018"; }
.ri-traffic-light-fill:before { content: "\f019"; }
.ri-traffic-light-line:before { content: "\f01a"; }
.ri-train-fill:before { content: "\f01b"; }
.ri-train-line:before { content: "\f01c"; }
.ri-travesti-fill:before { content: "\f01d"; }
.ri-travesti-line:before { content: "\f01e"; }
.ri-treasure-map-fill:before { content: "\f01f"; }
.ri-treasure-map-line:before { content: "\f020"; }
.ri-trello-fill:before { content: "\f021"; }
.ri-trello-line:before { content: "\f022"; }
.ri-trophy-fill:before { content: "\f023"; }
.ri-trophy-line:before { content: "\f024"; }
.ri-truck-fill:before { content: "\f025"; }
.ri-truck-line:before { content: "\f026"; }
.ri-tumblr-fill:before { content: "\f027"; }
.ri-tumblr-line:before { content: "\f028"; }
.ri-tv-2-fill:before { content: "\f029"; }
.ri-tv-2-line:before { content: "\f02a"; }
.ri-tv-fill:before { content: "\f02b"; }
.ri-tv-line:before { content: "\f02c"; }
.ri-twitch-fill:before { content: "\f02d"; }
.ri-twitch-line:before { content: "\f02e"; }
.ri-twitter-fill:before { content: "\f02f"; }
.ri-twitter-line:before { content: "\f030"; }
.ri-u-disk-fill:before { content: "\f031"; }
.ri-u-disk-line:before { content: "\f032"; }
.ri-ubuntu-fill:before { content: "\f033"; }
.ri-ubuntu-line:before { content: "\f034"; }
.ri-umbrella-fill:before { content: "\f035"; }
.ri-umbrella-line:before { content: "\f036"; }
.ri-underline:before { content: "\f037"; }
.ri-upload-2-fill:before { content: "\f038"; }
.ri-upload-2-line:before { content: "\f039"; }
.ri-upload-cloud-fill:before { content: "\f03a"; }
.ri-upload-cloud-line:before { content: "\f03b"; }
.ri-upload-fill:before { content: "\f03c"; }
.ri-upload-line:before { content: "\f03d"; }
.ri-user-2-fill:before { content: "\f03e"; }
.ri-user-2-line:before { content: "\f03f"; }
.ri-user-3-fill:before { content: "\f040"; }
.ri-user-3-line:before { content: "\f041"; }
.ri-user-4-fill:before { content: "\f042"; }
.ri-user-4-line:before { content: "\f043"; }
.ri-user-5-fill:before { content: "\f044"; }
.ri-user-5-line:before { content: "\f045"; }
.ri-user-add-fill:before { content: "\f046"; }
.ri-user-add-line:before { content: "\f047"; }
.ri-user-fill:before { content: "\f048"; }
.ri-user-follow-fill:before { content: "\f049"; }
.ri-user-follow-line:before { content: "\f04a"; }
.ri-user-line:before { content: "\f04b"; }
.ri-user-location-fill:before { content: "\f04c"; }
.ri-user-location-line:before { content: "\f04d"; }
.ri-user-received-2-fill:before { content: "\f04e"; }
.ri-user-received-2-line:before { content: "\f04f"; }
.ri-user-received-fill:before { content: "\f050"; }
.ri-user-received-line:before { content: "\f051"; }
.ri-user-search-fill:before { content: "\f052"; }
.ri-user-search-line:before { content: "\f053"; }
.ri-user-settings-fill:before { content: "\f054"; }
.ri-user-settings-line:before { content: "\f055"; }
.ri-user-shared-2-fill:before { content: "\f056"; }
.ri-user-shared-2-line:before { content: "\f057"; }
.ri-user-shared-fill:before { content: "\f058"; }
.ri-user-shared-line:before { content: "\f059"; }
.ri-user-smile-fill:before { content: "\f05a"; }
.ri-user-smile-line:before { content: "\f05b"; }
.ri-user-star-fill:before { content: "\f05c"; }
.ri-user-star-line:before { content: "\f05d"; }
.ri-user-unfollow-fill:before { content: "\f05e"; }
.ri-user-unfollow-line:before { content: "\f05f"; }
.ri-user-voice-fill:before { content: "\f060"; }
.ri-user-voice-line:before { content: "\f061"; }
.ri-video-chat-fill:before { content: "\f062"; }
.ri-video-chat-line:before { content: "\f063"; }
.ri-video-fill:before { content: "\f064"; }
.ri-video-line:before { content: "\f065"; }
.ri-vidicon-2-fill:before { content: "\f066"; }
.ri-vidicon-2-line:before { content: "\f067"; }
.ri-vidicon-fill:before { content: "\f068"; }
.ri-vidicon-line:before { content: "\f069"; }
.ri-vip-crown-2-fill:before { content: "\f06a"; }
.ri-vip-crown-2-line:before { content: "\f06b"; }
.ri-vip-crown-fill:before { content: "\f06c"; }
.ri-vip-crown-line:before { content: "\f06d"; }
.ri-vip-diamond-fill:before { content: "\f06e"; }
.ri-vip-diamond-line:before { content: "\f06f"; }
.ri-vip-fill:before { content: "\f070"; }
.ri-vip-line:before { content: "\f071"; }
.ri-visa-fill:before { content: "\f072"; }
.ri-visa-line:before { content: "\f073"; }
.ri-voiceprint-fill:before { content: "\f074"; }
.ri-voiceprint-line:before { content: "\f075"; }
.ri-volume-down-fill:before { content: "\f076"; }
.ri-volume-down-line:before { content: "\f077"; }
.ri-volume-mute-fill:before { content: "\f078"; }
.ri-volume-mute-line:before { content: "\f079"; }
.ri-volume-up-fill:before { content: "\f07a"; }
.ri-volume-up-line:before { content: "\f07b"; }
.ri-vuejs-fill:before { content: "\f07c"; }
.ri-vuejs-line:before { content: "\f07d"; }
.ri-walk-fill:before { content: "\f07e"; }
.ri-walk-line:before { content: "\f07f"; }
.ri-wallet-2-fill:before { content: "\f080"; }
.ri-wallet-2-line:before { content: "\f081"; }
.ri-wallet-3-fill:before { content: "\f082"; }
.ri-wallet-3-line:before { content: "\f083"; }
.ri-wallet-fill:before { content: "\f084"; }
.ri-wallet-line:before { content: "\f085"; }
.ri-water-flash-fill:before { content: "\f086"; }
.ri-water-flash-line:before { content: "\f087"; }
.ri-webcam-fill:before { content: "\f088"; }
.ri-webcam-line:before { content: "\f089"; }
.ri-wechat-2-fill:before { content: "\f08a"; }
.ri-wechat-2-line:before { content: "\f08b"; }
.ri-wechat-fill:before { content: "\f08c"; }
.ri-wechat-line:before { content: "\f08d"; }
.ri-wechat-pay-fill:before { content: "\f08e"; }
.ri-wechat-pay-line:before { content: "\f08f"; }
.ri-weibo-fill:before { content: "\f090"; }
.ri-weibo-line:before { content: "\f091"; }
.ri-whatsapp-fill:before { content: "\f092"; }
.ri-whatsapp-line:before { content: "\f093"; }
.ri-wifi-fill:before { content: "\f094"; }
.ri-wifi-line:before { content: "\f095"; }
.ri-window-2-fill:before { content: "\f096"; }
.ri-window-2-line:before { content: "\f097"; }
.ri-window-fill:before { content: "\f098"; }
.ri-window-line:before { content: "\f099"; }
.ri-windows-fill:before { content: "\f09a"; }
.ri-windows-line:before { content: "\f09b"; }
.ri-windy-fill:before { content: "\f09c"; }
.ri-windy-line:before { content: "\f09d"; }
.ri-women-fill:before { content: "\f09e"; }
.ri-women-line:before { content: "\f09f"; }
.ri-xbox-fill:before { content: "\f0a0"; }
.ri-xbox-line:before { content: "\f0a1"; }
.ri-xing-fill:before { content: "\f0a2"; }
.ri-xing-line:before { content: "\f0a3"; }
.ri-youtube-fill:before { content: "\f0a4"; }
.ri-youtube-line:before { content: "\f0a5"; }
.ri-zcool-fill:before { content: "\f0a6"; }
.ri-zcool-line:before { content: "\f0a7"; }
.ri-zhihu-fill:before { content: "\f0a8"; }
.ri-zhihu-line:before { content: "\f0a9"; }
.ri-zoom-in-fill:before { content: "\f0aa"; }
.ri-zoom-in-line:before { content: "\f0ab"; }
.ri-zoom-out-fill:before { content: "\f0ac"; }
.ri-zoom-out-line:before { content: "\f0ad"; }

