.donate-container .donate-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.donate-container span {
  font-size: 1.2rem;
}

.donate-container .donate-input[type="number"]::-webkit-inner-spin-button,
.donate-container .donate-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.donate-container .donate-input[type="number"] {
  -moz-appearance: textfield;
}

.donate-container .donate-box h5 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.donate-container .donate-box .donate-input-box {
  position: relative;
}

.donate-container .donate-box .donate-input-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.donate-container .donate-box .donate-input-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 0;
  font-size: 1.2rem !important;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
  font-weight: bold;
}

.donate-container .donate-box .donate-input-box input:focus ~ label,
.donate-container .donate-box .donate-input-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #039cf4;
  font-size: 12px;
}

.donate-container .donate-box form .submit-donate-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #039cf4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.donate-container .donate-box .submit-donate-button:hover {
  background: #039cf4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 4px #039cf4, 0 0 8px #039cf4, 0 0 12px #039cf4,
    0 0 16px #039cf4;
}
.donate-input-box.error input {
  border-bottom: 1px solid red !important;
}
.donate-container .donate-box .submit-donate-button span {
  position: absolute;
  display: block;
}

.donate-container .donate-box .submit-donate-button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #039cf4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.donate-container .donate-box .submit-donate-button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #039cf4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.donate-container .donate-box .submit-donate-button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #039cf4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.donate-container .donate-box .submit-donate-button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #039cf4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@media (max-width: 600px) {
  .donate-container .donate-box .donate-input-box label {
    font-size: 1rem !important;
  }
  .donate-container span {
    font-size: 1.2rem;
    padding: 6px;
  }
  .donate-container .donate-box h5 {
    font-size: 1.2rem;
  }
  .donate-container .donate-box form .submit-donate-button {
    font-size: 12px;
    letter-spacing: 2px;
  }
  .donate-container .donate-box {
    width: 400px;
  }
}
@media (max-width: 400px) {
  .donate-container .donate-box {
    width: 350px;
  }
  .button-donate-container {
    display: flex;
    justify-content: center;
  }
}
